import { Divider, Spin } from "antd";
import React, { useState } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import AddressAutocomplete from "../Common/AddressAutocomplete";
import * as com from "../Common";
import { Result } from "antd";
import { CommaizedInput } from "../NumericalInput";

/**
 * VOE Eligibility component
 *
 * It needs the following information to determine eligibility:
 * 1. Property address (just for address validation)
 * 2. Number of units
 * 3. Loan purpose
 * 4. Occupancy
 * 5. FICO score
 * 6. Income
 * 7. Property value
 * 8. Down payment
 * 9. First time home buyer
 *
 * Source eligibility:
 * - First-time homebuyer restrictions: primary residence only, max DTI 45%.
 * - Primary & second homes:
 * - Purchase/R&T: 1 unit max LTV/CLTV 75%; 2-4 units max LTV/CLTV 65%; min FICO 700.
 * - Cash-out: 1 unit max LTV/CLTV 65%; 2-4 units max LTV/CLTV 55%; min FICO 720.
 * - Investment properties:
 * - Purchase/R&T: 1 unit max LTV/CLTV 70%; 2-4 units max LTV/CLTV 60%; min FICO 720.
 * - Cash-out: max LTV 75%.
 *
 * @returns
 */
const EligibilityVOE = () => {
  // Address related fields and calculation
  const [propertyAddress, setPropertyAddress] = useState("");
  const [displayedAddress, setDisplayedAddress] = useState("");
  const [propertyState, setPropertyState] = useState("");
  const [propertyCounty, setPropertyCounty] = useState("");
  const [propertyCity, setPropertyCity] = useState("");
  const [propertyZip, setPropertyZip] = useState("");

  const onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    setPropertyCity(city);
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );

    let zip = com.getSubaddress(address, "postal_code", "long_name");
    setPropertyZip(zip);

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    setPropertyCounty(county);
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
    );
    setPropertyState(longstate);

    let short = com.getSubaddress(address, "route", "long_name");
    setPropertyAddress(num + " " + short);
    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip;
    setDisplayedAddress(stradd);
  };

  // Property related fields and calculation
  const [units, setUnits] = useState("1");
  const [purpose, setPurpose] = useState("");
  const [occupancy, setOccupancy] = useState("");

  // Loan related fields and calculation
  const [propertyValue, setPropertyValue] = useState("");
  const [fico, setFico] = useState<number>(780);
  const [downPayment, setDownPayment] = useState("");
  const [cashOutAmount, setCashOutAmount] = useState("");
  const [firstTimeHomeBuyer, setFirstTimeHomeBuyer] = useState(false);

  const displayedLoanAmount = () => {
    if (propertyValue === "" || downPayment === "") return "";
    let dp = com.safeParseInt(downPayment);
    let pp = com.safeParseInt(propertyValue);

    let loanAmount = purpose === "purchase" ? pp - dp : dp;
    if (purpose === "cashOutRefinance")
      loanAmount += com.safeParseInt(cashOutAmount);

    return (
      <Row className="blueTips bold mb-2">
        <Col>
          <div>Your loan amount is ${com.commaize(loanAmount)}</div>
        </Col>
      </Row>
    );
  };

  // Submit and check eligibility
  const [loading, setLoading] = useState(false);
  const [qualified, setQualified] = useState<boolean | null>(null);
  const [unqualifiedReason, setUnqualifiedReason] = useState("");
  const [qualifiedTitle, setQualifiedTitle] = useState("");

  // Submit and check eligibility
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // Set loading to true
    setLoading(true);
    // set all other states to defualt
    setQualified(null);
    setUnqualifiedReason("");

    e.preventDefault();
    const dp = com.safeParseInt(downPayment);
    const pp = com.safeParseInt(propertyValue);
    const co = com.safeParseInt(cashOutAmount);
    const ltv = purpose === "purchase" ? (pp - dp) / pp : (co + dp) / pp;
    if (firstTimeHomeBuyer) {
      if (occupancy !== "primary") {
        setUnqualifiedReason(
          "First-time homebuyer restrictions: primary residence only"
        );
        setLoading(false);
        setQualified(false);
        return;
      }
      setQualifiedTitle("You're qualified for the program (max DTI 45%).");
      setLoading(false);
      setQualified(true);
      return;
    } else {
      if (occupancy === "primary" || occupancy === "secondHome") {
        // Branch 1: primary or second home, purpose is purchase or rate/term refinance
        if (purpose === "purchase" || purpose === "rateTermRefinance") {
          // Check unit constraints
          switch (units) {
            case "1":
              if (ltv > 0.75) {
                setUnqualifiedReason("LTV/CLTV cannot exceed 75%");
                setQualified(false);
                setLoading(false);
                return;
              }
              break;
            case "2":
            case "3":
            case "4":
              if (ltv > 0.65) {
                setUnqualifiedReason("LTV/CLTV cannot exceed 65%");
                setQualified(false);
                setLoading(false);
                return;
              }
              break;
          }
          // Check FICO
          if (com.safeParseInt(fico) < 700) {
            setUnqualifiedReason("FICO score cannot be less than 700");
            setQualified(false);
            setLoading(false);
            return;
          }
          // If both checks pass, set qualified to true
          setQualified(true);
        }
        // Branch 2: primary or second home, purpose is cash out refinance
        else {
          // Check unit constraints
          switch (units) {
            case "1":
              if (ltv > 0.65) {
                setUnqualifiedReason("LTV/CLTV cannot exceed 65%");
                setQualified(false);
                setLoading(false);
                return;
              }
              break;
            case "2":
            case "3":
            case "4":
              if (ltv > 0.55) {
                setUnqualifiedReason("LTV/CLTV cannot exceed 55%");
                setQualified(false);
                setLoading(false);
                return;
              }
              break;
          }
          // Check FICO
          if (com.safeParseInt(fico) < 720) {
            setUnqualifiedReason("FICO score cannot be less than 720");
            setQualified(false);
            setLoading(false);
            return;
          }
          // If both checks pass, set qualified to true
          setQualified(true);
        }
      } else {
        // Branch 3: investment property, purpose is purchase or rate/term refinance
        if (purpose === "purchase" || purpose === "rateTermRefinance") {
          // Check unit constraints
          switch (units) {
            case "1":
              if (ltv > 0.7) {
                setUnqualifiedReason("LTV/CLTV cannot exceed 70%");
                setQualified(false);
                setLoading(false);
                return;
              }
              break;
            case "2":
            case "3":
            case "4":
              if (ltv > 0.6) {
                setUnqualifiedReason("LTV/CLTV cannot exceed 60%");
                setQualified(false);
                setLoading(false);
                return;
              }
              break;
          }
          // Check FICO
          if (com.safeParseInt(fico) < 720) {
            setUnqualifiedReason("FICO score cannot be less than 720");
            setQualified(false);
            setLoading(false);
            return;
          }
          // If both checks pass, set qualified to true
          setQualified(true);
        } else {
          // Branch 4: investment property, purpose is cash out refinance
          // Check unit constraints
          if (ltv > 0.75) {
            setUnqualifiedReason("LTV/CLTV cannot exceed 75%");
            setQualified(false);
            setLoading(false);
            return;
          }
          // Only need to check LTV, set qualified to true
          setQualified(true);
        }
      }
    }
    setQualifiedTitle("You're qualified for the program!");
    setLoading(false);
  };

  return (
    <div className="qualifierFormWrap">
      <Form onSubmit={handleSubmit} className="qualifierForm">
        <Row>
          <Col md={12} xs={12}>
            <AddressAutocomplete
              id={"VOE_Eligibility_Address"}
              label="Property address"
              required={true}
              name="addessline"
              defaultValue={displayedAddress}
              onChange={(e) => setDisplayedAddress(e.target.value)}
              pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
              placeholder="Please type your home address and select from the dropdown addresses"
              goodfeedback="Looks good!"
              badfeedback="Please provide your street address."
              onCompletion={(e) => onStreetAddressCompletion(e)}
              size="sm"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <Form.Group controlId="purpose" className="">
              <Form.Label className="text-left">Number of units</Form.Label>
              <Form.Control
                required
                name="units"
                as="select"
                size="sm"
                defaultValue={units}
                onChange={(e) => setUnits(e.target.value)}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6} xs={12}>
            <Form.Group>
              <Form.Label>FICO (optional)</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                value={fico}
                defaultValue={fico}
                onChange={(e) => setFico(com.safeParseInt(e.target.value))}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} xs={12}>
            <Form.Group controlId="purpose" className="">
              <Form.Label className="text-left">Loan purpose</Form.Label>
              <Form.Control
                required
                name="purpose"
                as="select"
                size="sm"
                defaultValue={purpose}
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
              >
                <option value="">...</option>
                <option value="purchase">Purchase</option>
                <option value="rateTermRefinance">Rate/Term refinance</option>
                <option value="cashOutRefinance">Cash out refinance</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6} xs={12}>
            <Form.Group controlId="purpose" className="">
              <Form.Label className="text-left">Occupancy</Form.Label>
              <Form.Control
                required
                name="occupancy"
                as="select"
                size="sm"
                defaultValue={occupancy}
                value={occupancy}
                onChange={(e) => setOccupancy(e.target.value)}
              >
                <option value="">...</option>
                <option value="primary">Primary</option>
                <option value="secondHome">Second home</option>
                <option value="investment">Investment(rental)</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
            <Form.Group>
              <Form.Label>Property price</Form.Label>
              <CommaizedInput
                value={propertyValue}
                onChange={(e) => setPropertyValue(e.target.value)}
                withDollarSign
                required
              />
            </Form.Group>
          </Col>
          <Col md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
            <Form.Group>
              <Form.Label>
                {purpose === "purchase" ? "Down payment" : "Current balance"}
              </Form.Label>
              <CommaizedInput
                className="gmccCalculatorInput"
                value={downPayment}
                onChange={(e) => setDownPayment(e.target.value)}
                withDollarSign
                required
              />
            </Form.Group>
          </Col>
          {purpose === "cashOutRefinance" && (
            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Cash out amount</Form.Label>
                <CommaizedInput
                  className="gmccCalculatorInput"
                  value={cashOutAmount}
                  onChange={(e) => setCashOutAmount(e.target.value)}
                  withDollarSign
                  required
                />
              </Form.Group>
            </Col>
          )}
        </Row>

        <Row>
          <Col md={6} xs={12}>
            <Form.Group>
              <Form.Label className="text-left">
                First time home buyer
              </Form.Label>
              <Form.Control
                required
                name="firstTimeHomeBuyer"
                as="select"
                size="sm"
                defaultValue={firstTimeHomeBuyer ? "true" : "false"}
                value={firstTimeHomeBuyer ? "true" : "false"}
                onChange={(e) =>
                  setFirstTimeHomeBuyer(e.target.value === "true")
                }
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        {displayedLoanAmount()}

        <Row className="ml-2 mt-2">
          <Col className="text-center">
            <Button type="submit" variant="zeitro-primary">
              {loading ? (
                <div className="gmccSpin">
                  <Spin />
                </div>
              ) : (
                "Check my eligibility"
              )}
            </Button>
          </Col>
        </Row>
      </Form>

      {!loading && qualified === true && (
        <Result status="success" title={qualifiedTitle} subTitle={""} />
      )}
      {!loading && qualified === false && (
        <Result
          status="warning"
          title="You're not qualified for the program."
          subTitle={<div>{unqualifiedReason}</div>}
        />
      )}
      <Divider />
      <Row className="qualifierDisclaimerWrap">
        <div className="disclaimerContainer">
          <div className="title-6 mb-2">Disclaimer:</div>
          <div className="title-6">
            All loans are subject to credit approval. Interest rates are subject
            to change daily and without notice. Current interest rates shown our
            indicative of market conditions and individual qualifications and
            will vary upon your lock-in period, loan type, credit score, purpose
            and loan to value.
          </div>
        </div>
      </Row>
    </div>
  );
};

export default EligibilityVOE;
