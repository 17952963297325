import React from "react";

const EligibilityDSCRNoRatio = () => {
  return (
    <div style={{ height: "50vh" }}>
      <div
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "left",
          padding: "0 0 1em 1em",
        }}
      >
        Eligibility
      </div>
      <ul>
        <li>
          CPA P&L with minimum 3 months bank statements and business expense
          statement.
        </li>
        <li>Requires 50% net margin for DSCR calculation.</li>
      </ul>
    </div>
  );
};

export default EligibilityDSCRNoRatio;
