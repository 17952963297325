import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Input, Space, Select, Checkbox, DatePicker, Skeleton } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import dayjs from 'dayjs'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import * as com from '../Common.js'
import { connect } from 'react-redux'
import * as act from '../Store/actions'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Autocomplete from '../Autocomplete'
import AddressAutocomplete from '../Common/AddressAutocomplete'
import { tooltip } from '../Common/Tooltip'
import { FutureExpenses } from '../State'
import { titles } from '../occupations.js'
import CheckGroup from '../Common/components/CheckGroup'
import { Tips } from '../Common/Tooltip'
import { isEmpty, isFinite, toNumber } from 'lodash'
import eventBus from '../Bus'
// import Military from './Military'
import '../BorrowerApplication.css'
import { DollarInput } from '../Common/components/DollarInput'

class MoneyInput extends React.PureComponent {
    render() {
        const {
            value,
            period = 'yearly',
            onChange,
            onPeriodChange,
            placeholder = 'Enter amount',
            required = false,
            inputRef
        } = this.props

        return (
            <div style={{ flex: '1 1 0' }}>
                <div className="money-input-container" style={{
                    display: 'flex',
                    border: '1px solid #d9d9d9',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    height: '44px',
                }}>
                    {/* Dollar Sign and Input Field Group */}
                    <div style={{ display: 'flex', flex: '2', background: '#fff' }}>
                        <span style={{ padding: '0 12px', display: 'flex', alignItems: 'center', color: '#666' }}>$</span>
                        <Form.Control
                            style={{ border: 'none', borderRadius: '0', boxShadow: 'none', padding: '0 8px' }}
                            isValid={false}
                            ref={inputRef}
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                            required={required}
                            type="number"
                            min="0"
                            step="0.01"
                            inputMode="decimal"
                        />
                    </div>

                    {/* Divider */}
                    <div style={{ width: '1px', background: '#d9d9d9' }} />

                    {/* Period Select */}
                    <Select
                        style={{
                            width: '120px',
                            background: '#fff',
                        }}
                        className="period-select"
                        value={period}
                        onChange={onPeriodChange}
                        bordered={false}
                    >
                        <Select.Option value="yearly">Yearly</Select.Option>
                        <Select.Option value="monthly">Monthly</Select.Option>
                    </Select>
                </div>
            </div>
        )
    }
}

export function calculateEmploymentIncome(app, who) {
    // Get employment info for this person
    const employment = app[who].occupation

    // Helper function to calculate monthly income based on payment type
    const calculateMonthlyIncome = (amount, period, hoursPerWeek = null) => {
        const value = parseFloat(amount)
        if (isNaN(value)) return 0

        switch (period) {
            case 'monthly':
                return value
            case 'yearly':
                return value / 12
            case 'hourly':
                if (hoursPerWeek === null) return 0
                const hours = parseFloat(hoursPerWeek)
                return isNaN(hours) ? 0 : (value * hours * 52) / 12
            default:
                return 0
        }
    }

    // Helper to calculate income from employment object
    const calculateEmploymentIncome = (emp) => {
        if (emp.selfemployed) {
            return calculateMonthlyIncome(emp.incomeorloss, emp.incomeorlossperiod)
        }

        // Sum up all income types for regular employment
        const incomeTypes = ['base', 'commissions', 'overtime', 'bonuses', 'military', 'other']
        return incomeTypes.reduce((total, type) => {
            const period = emp.income[type + 'monthly']
            const amount = emp.income[type]
            const hours = type === 'base' ? emp.income[type + 'hoursperweek'] : null
            return total + calculateMonthlyIncome(amount, period, hours)
        }, 0)
    }

    let totalIncome = 0

    // Only calculate if person is employed
    if (employment.hasoccupation === 'employed') {
        // Add primary employment income
        totalIncome += calculateEmploymentIncome(employment)

        // Add other current employment income
        if (app[who].otheroccupations) {
            totalIncome += app[who].otheroccupations.reduce((sum, otherEmp) => {
                return sum + calculateEmploymentIncome(otherEmp)
            }, 0)
        }
    }

    return totalIncome
}

export function calculateDividendIncome(app, who) {
    let income = 0
    if (app[who].income.hasdividends) {
        let dividends = parseFloat(app[who].income.dividends)
        if (!isNaN(dividends)) {
            switch (app[who].income.dividendsmonthly) {
                case 'monthly': {
                    income += dividends
                    break
                }
                case 'yearly': {
                    income += dividends / 12
                    break
                }
                default:
                    break
            }
        }
    }

    return income
}

export function calculateOtherIncome(app, who) {
    let income = 0
    if (app[who].income.otherincome !== null && app[who].income.otherincome.length > 0) {
        app[who].income.otherincome.forEach((inc) => {
            const v = parseFloat(inc.amount)
            if (isNaN(v)) return
            switch (inc.period) {
                case 'monthly': {
                    income += v
                    break
                }
                case 'yearly': {
                    income += v / 12
                    break
                }
                default:
                    break
            }
        })
    }

    return income
}

export function calculateIncomeWho(app, who) {
    let income = calculateEmploymentIncome(app, who)
    income += calculateDividendIncome(app, who)
    income += calculateOtherIncome(app, who)

    return income
}

const mapStateToPropsDetails = (state) => {
    return {
        application: state.application,
    }
}

const mapDispatchToPropsDetails = (dispatch) => ({
    changeNonQMInfo: (event, who, verb) => {
        dispatch(act.ChangeNonQMInfo(event, who, verb))
    },
    updateProduct: (v, verb) => {
        dispatch(act.UpdateProduct(v, verb))
    },
    setBorrowerHasAdditionalIncome: (value, who) => {
        dispatch(act.SetBorrowerHasAdditionalIncome(value, who))
    },
    updateIncomeMonthly: (event, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(event.target.value, who, verb))
    },
    addBorrowerAdditionalIncome: (t, who) => {
        dispatch(act.AddBorrowerAdditionalIncome(t, who))
    },
    removeBorrowerAdditionalIncome: (t, who) => {
        dispatch(act.RemoveBorrowerAdditionalIncome(t, who))
    },
    clearBorrowerAdditionalIncome: (who) => {
        dispatch(act.ClearBorrowerAdditionalIncome(who))
    },
    updateBorrowerAdditionalIncome: (event, who, verb, n) => {
        dispatch(
            act.UpdateBorrowerAdditionalIncome(event.target.value, who, verb, n)
        )
    },
    changeIncomeCheck: (checked, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(checked, who, verb))
    },
    changeCurrentOccupationCheck: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(!event.target.checked, who, verb))
    },
    changeCurrentOccupation: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event, who, verb))
    },
    changeOccupationInfo: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.value, who, verb))
    },
    changePhoneInfo: (phone, who, verb) => {
        dispatch(act.ChangeOccupationInfo(phone, who, verb))
    },
    changeOccupationCheck: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.checked, who, verb))
    },
    addAdditionaOccupation: (event, who) => {
        dispatch(act.AddAdditionaOccupation(event, who))
    },
    removeAdditionalOccupation: (event, who, i) => {
        dispatch(act.RemoveAdditionalOccupation(event.target.value, who, i))
    },
    clearAdditionalOccupations: (event, who) => {
        dispatch(act.ClearAdditionalOccupations(event, who))
    },
    changePastOccupationInfo: (event, who, verb, index) => {
        dispatch(
            act.ChangePastOccupationInfo(event.target.value, who, verb, index)
        )
    },
    changePastOccupationDate: (date, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(date, who, verb, index))
    },
    changePastOccupationPhone: (phone, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(phone, who, verb, index))
    },
    changePastOccupationCheck: (event, who, verb, index) => {
        dispatch(
            act.ChangePastOccupationInfo(event.target.checked, who, verb, index)
        )
    },
    addBorrowerOtherOccupation: (who) => {
        dispatch(act.AddBorrowerOtherOccupation(who))
    },
    removeBorrowerOtherOccupation: (who, index) => {
        dispatch(act.RemoveBorrowerOtherOccupation(who, index))
    },
    clearBorrowerOtherOccupations: (who) => {
        dispatch(act.ClearBorrowerOtherOccupations(who))
    },
    changeBorrowerOtherOccupationValue: (t, i, who, verb) => {
        dispatch(act.ChangeBorrowerOtherOccupationValue(t, i, who, verb))
    },
    changeBorrowerOtherOccupationIncome: (t, i, who, verb) => {
        dispatch(act.ChangeBorrowerOtherOccupationIncome(t, i, who, verb))
    },
    changeMainProperty: (t, verb) => {
        dispatch(act.ChangeMainProperty(t, verb))
    },
    updateIncomeAtttribute: (t, who, verb) => {
        dispatch(act.UpdateIncomeAtttribute(t, who, verb))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event, who, verb))
    },
    changeDeclaration: (event, who, verb) => {
        dispatch(act.ChangeDeclaration(event, who, verb));
    },
})

const getEmploymentType = (app, who) => {
    const e = app[who].occupation.hasoccupation
    const s = app[who].occupation.selfemployed

    if (e === 'employed' && s) {
        return 'selfemployed'
    } else if (e === 'employed' && !s) {
        return 'employed'
    }

    return e
}

export default class Income extends Component {
    constructor(props) {
        super(props)

        this.form = React.createRef()

        const { who } = this.props
        const { firstname, lastname } = this.props.application[who]
        this.state = {
            isNonQMCustomer: null,
            validated: false,
            showSelf: false,
            isbasevalid: false,
            iscomissionvalid: false,
            isovertimevalid: false,
            isbonusesvalid: false,
            ismilitaryvalid: false,
            isothervalid: false,
            isdividendsvalid: false,
            employmentType: '',
            tabAvtive: 0,
            isotherJob: false,
            showEmploymentModal: false,
            showPreviousEmploymentModal: false,
            who: com.borrower,
            tabs: [
                {
                    tab: who === com.borrower ? 'Primary' : 'Co-borrower',
                    value: `${firstname} ${lastname}`,
                },
            ],
            isEdit: false,
            hasborrowerTab: true,
            editIndex: -1,
            selfemployed: false,
            employername: '',
            positiontitle: '',
            phone: '',
            yearsatlineofwork: '',
            monthsatlineofwork: '',
            from: '',
            employeraddress: '',
            income: {
                basemonthly: '',
                basehoursperweek: '',
                base: '',
                overtimemonthly: '',
                overtime: 0,
                bonusesmonthly: '',
                bonuses: 0,
                commissionsmonthly: '',
                commissions: 0,
                militarymonthly: '',
                military: 0,
                othermonthly: '',
                other: 0,
            },
            previousoccupation: {
                employername: '',
                from: '',
                to: '',
                employeraddress: '',
                positiontitle: '',
                income: '',
                incomeperiod: '',
                selfemployed: false,
                employedbyagent: false,
            },
            employedbyagent: false,
            percentownership: '',
            incomeorlossperiod: '',
            incomeorloss: '',
            selfincomedoc: '',
            employed2years: 'false',
            showNoticeModal: false,
        }

        this.refsfrom1 = React.createRef()
        this.refsto1 = React.createRef()
        this.refsfrom = []
        this.refsto = []

        const { previousoccupations, otheroccupations } = this.props.application[who]
        if (previousoccupations !== null) {
            for (let i = 0; i < previousoccupations.length; i++) {
                this.refsfrom.push(React.createRef())
                this.refsto.push(React.createRef())
            }
        }

        this.crefsfrom = []
        if (otheroccupations !== null) {
            for (let i = 0; i < otheroccupations.length; i++) {
                this.crefsfrom.push(React.createRef())
            }
        }

        this.isValidated = this.isValidated.bind(this)
    }

    getIsNonQMCustomer = () => {
        const token = sessionStorage.getItem('ZeitroA')
        fetch('/borrower/getaccountconfig', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
            },
        }).then((response) => {
            if (response.status !== 200) {
                this.setState({ isNonQMCustomer: false })
                return
            }
            response.json().then((data) => {
                this.setState({ isNonQMCustomer: data.isNonQMCustomer })
            })
        })
    }

    componentDidMount() {
        this.getIsNonQMCustomer()

        let prevOccupations = this.props.application[this.props.who].previousoccupations
        if (prevOccupations !== null && prevOccupations.length > 0) {
            this.setState({ employed2years: 'true' })
        }

        this.setState({
            employmentType: getEmploymentType(
                this.props.application,
                this.props.who
            ),
        })

        const { firstname, lastname } = this.props.application.coborrower
        if (this.personalCoborrower()) {
            this.setState((prevState) => ({
                tabs: [
                    ...prevState.tabs,
                    {
                        tab: 'Co-borrower',
                        value: `${firstname} ${lastname}`,
                    },
                ],
            }))
        }

        this.loadExpenses()
        this.completion()

        const { who } = this.state
        const { income, otheroccupations, otherincome } = this.props.application[who]
        if (income.hasdividends === null) {
            this.props.updateIncomeAtttribute(false, who, 'hasdividends')
        }
        if (otheroccupations === null) {
            this.props.clearBorrowerOtherOccupations(who)
        }
        if (otherincome === null) {
            this.props.clearBorrowerAdditionalIncome(who)
        }
    }

    componentDidUpdate() {
        this.completion()
    }

    personalCoborrower = () => {
        const { hascoborrower, married } = this.props.application
        return (
            hascoborrower === 'withcoborrower' ||
            (hascoborrower === 'multiple' && married)
        )
    }

    completion = () => {
        const form = this.props.self.current
        const formElements = form?.elements
        if (formElements && formElements.length !== 0) {
            let totalCount = 5
            let fillNum = 5
            if (isEmpty(this.state.employmentType)) {
                fillNum = 4
            }
            for (let i = 0; i < formElements.length; i++) {
                const element = formElements[i]
                if (!['button', 'fieldset', 'submit'].includes(element.type)) {
                    totalCount++
                    if (!isEmpty(element.value)) {
                        fillNum++
                    }
                }
            }
            const progressValue = Math.round((fillNum / totalCount) * 100)
            sessionStorage.setItem('progressValue', progressValue)
        }
    }

    loadExpenses = () => {
        const token = sessionStorage.getItem('ZeitroA')

        fetch('/data/getexpences', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                Cache: 'no-cache',
                'Content-Type': 'application/json',
                'X-Borrower': this.props.borrowerid,
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.warn('Bad response, should not happen')
                    return
                }
                response.json().then((fees) => {
                    const ex = new FutureExpenses()
                    ex.otherfinancing = fees.otherfinancing
                    ex.homeownersinsurance = fees.hazardinsurance
                    ex.realestatetaxes = fees.realestatetaxes
                    ex.hoa = fees.dues
                    ex.floodinsurance = fees.other
                    this.props.changeMainProperty(ex, 'expenses')
                })
            })
            .catch((err) => {
                this.setState({ loading: false })
                alert('Network error')
            })
    }

    componentWillUnmount() {}

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }

        return true
    }

    onBaseAmountValid = (val) => {
        this.setState({ isbasevalid: val })
    }
    onComissionsAmountValid = (val) => {
        this.setState({ iscomissionvalid: val })
    }
    onOvertimeAmountValid = (val) => {
        this.setState({ isovertimevalid: val })
    }
    onBonusesAmountValid = (val) => {
        this.setState({ isbonusesvalid: val })
    }
    onMilitaryAmountValid = (val) => {
        this.setState({ ismilitaryvalid: val })
    }
    onOtherAmountValid = (val) => {
        this.setState({ isothervalid: val })
    }
    onDividendsValid = (val) => {
        this.setState({ isdividendsvalid: val })
    }

    canShowDividends = () => {
        const { who } = this.props
        const { hasoccupation } = this.props.application[who].occupation

        if (hasoccupation === null)
            return false

        if (hasoccupation !== 'employed')
            return true

        const { isbasevalid, iscomissionvalid, isbonusesvalid, isovertimevalid } = this.state
        const { hasbonuses } = this.props.application[who].income
        if (isbasevalid && hasbonuses === false)
            return true

        if (isbasevalid && hasbonuses === true && iscomissionvalid && isovertimevalid && isbonusesvalid)
            return true

        return false
    }

    PreviousEmploymentForm = () => {
        const achangePastOccupationDate = (event, who, verb) => {
            com.touch()

            this.setState({
                previousoccupation: {
                    ...this.state.previousoccupation,
                    [verb]: dayjs(dayjs(event).format('MM/DD/YYYY')),
                },
            })
        }

        const changePastOccupationCheck = (verb) => {
            return (event) => {
                com.touch()

                const { employmentType } = this.state
                if (
                    event.target.checked &&
                    verb === 'selfemployed' &&
                    employmentType !== 'unemployed' &&
                    employmentType !== 'retired'
                ) {
                    this.setState({ showSelf: true, currentJob: false })
                }

                this.setState({
                    previousoccupation: {
                        ...this.state.previousoccupation,
                        [verb]: event.target.checked,
                    },
                })
            }
        }

        const { who } = this.props
        const { previousoccupation, employmentType } = this.state

        return (
            <div className="m-0 mb-3">
                <Row>
                    <Col className="px-0">
                        <Form.Row>
                            <Col>
                                <Form.Group className="text-left">
                                    <Form.Label>
                                        *Are you the business owner or self-employed?
                                    </Form.Label>
                                    <div>
                                        <CheckGroup
                                            data={[
                                                { value: 'yes', label: 'Yes' },
                                                { value: 'no', label: 'No' },
                                            ]}
                                            onChangeSelect={(e) => {
                                                com.touch()

                                                if (e === 'yes' && employmentType !== 'unemployed' && employmentType !== 'retired') {
                                                    this.setState({ showSelf: true, currentJob: false })
                                                }

                                                this.setState({
                                                    previousoccupation: {
                                                        ...previousoccupation,
                                                        selfemployed: e === 'yes',
                                                    },
                                                })
                                            }}
                                            value={previousoccupation.selfemployed ? 'yes' : 'no'}
                                            type="radio"
                                            direction="horizontal"
                                            required={true}
                                            style={{ width: '50%' }}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row className="mt-4">
                            <Col>
                                <Form.Group controlId="Employer1" className="text-left text-nowrap">
                                    <Form.Label className="text-left text-nowrap">
                                        Employer
                                    </Form.Label>
                                    <Form.Control
                                        isValid={false}
                                        autocomplete="off"
                                        required
                                        name="employer"
                                        type="text"
                                        defaultValue={ previousoccupation.employername }
                                        onChange={(e) => {
                                            this.setState({
                                                previousoccupation: {
                                                    ...previousoccupation,
                                                    employername: e.target.value,
                                                },
                                            })
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Provide employer.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Autocomplete
                                    required
                                    id="PrevTitle"
                                    suggestions={titles}
                                    name="position"
                                    type="text"
                                    label="Position/title"
                                    valid=""
                                    invalid="Please enter position"
                                    value={previousoccupation.positiontitle}
                                    onChange={(e) => {
                                        this.setState({
                                            previousoccupation: {
                                                ...previousoccupation,
                                                positiontitle: e.target.value,
                                            },
                                        })
                                    }}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className="mt-4">
                            <Col>
                                <AddressAutocomplete
                                    id="EmployerAddr1"
                                    label="Employment address"
                                    name="employeraddr"
                                    defaultValue={previousoccupation.employeraddress}
                                    onChange={(e) => {
                                        this.setState({
                                            previousoccupation: {
                                                ...previousoccupation,
                                                employeraddress: e.target.value,
                                            },
                                        })
                                    }}
                                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                    placeholder="Enter employer address"
                                    goodfeedback=""
                                    badfeedback="Please provide address."
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className="mt-4">
                            <Col xs="auto">
                                <Form.Group controlId="from" className="text-left">
                                    <Form.Label className="text-left text-nowrap">
                                        Start date
                                    </Form.Label>
                                    <div>
                                        <DatePicker
                                            name="dateofstart"
                                            style={{ width: '100%' }}
                                            required
                                            size="sm"
                                            placeholder="MM/DD/YYYY"
                                            format="MM/DD/YYYY"
                                            value={previousoccupation.from}
                                            onChange={(e) =>
                                                achangePastOccupationDate(
                                                    e,
                                                    who,
                                                    'from'
                                                )
                                            }
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group controlId="to" className="text-left">
                                    <Form.Label className="text-left text-nowrap">
                                        End date
                                    </Form.Label>
                                    <DatePicker
                                        name="dateofend"
                                        style={{ width: '100%' }}
                                        disabledDate={this.disabledDate}
                                        required
                                        size="sm"
                                        placeholder="MM/DD/YYYY"
                                        format="MM/DD/YYYY"
                                        value={previousoccupation.to}
                                        onChange={(e) =>
                                            achangePastOccupationDate(
                                                e,
                                                who,
                                                'to'
                                            )
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Total income
                                    </Form.Label>
                                    <MoneyInput
                                        value={previousoccupation.income}
                                        period={previousoccupation.incomeperiod || 'yearly'}
                                        onChange={(e) => {
                                            const inputValue = e.target.value
                                            if (inputValue.length > 0) {
                                                this.setState({
                                                    previousoccupation: {
                                                        ...previousoccupation,
                                                        income: inputValue,
                                                    },
                                                })
                                            } else {
                                                this.setState({
                                                    previousoccupation: {
                                                        ...previousoccupation,
                                                        income: 0,
                                                    },
                                                })
                                            }
                                        }}
                                        onPeriodChange={(period) => {
                                            this.setState({
                                                previousoccupation: {
                                                    ...previousoccupation,
                                                    incomeperiod: period,
                                                }
                                            })
                                        }}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs="auto" className="text-left nowrap d-block mt-4">
                                <Form.Group controlId="pastemployedbyagent" className="text-left d-block text-nowrap">
                                    <Form.Check
                                        type="checkbox"
                                        inline
                                        className="text-wrap"
                                        checked={previousoccupation.employedbyagent}
                                        key={Math.random()}
                                        onChange={changePastOccupationCheck('employedbyagent')}
                                        label="Check if employed by a family member, property seller, real estate agent, or other party to the transaction"
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Col>
                </Row>
            </div>
        )
    }

    EmploymentForm = () => {
        const ondocs = (e) => {
            this.setState({ selfincomedoc: e.target.value })
        }

        let key = null

        const {
            income,
            employername,
            positiontitle,
            phone,
            from,
            employeraddress,
            selfemployed,
            percentownership,
            incomeorlossperiod,
            incomeorloss,
            selfincomedoc,
            employedbyagent,
        } = this.state

        const IncomeType = (commissions) => {
            return (
                <Col md={6} xs={12} className="d-block mt-4 p-0">
                    <div className="px-0" style={{ width: '100%' }}>
                        <Form.Group
                            controlId={'amount' + commissions}
                            className="text-left"
                            style={{ alignItems: 'baseline', width: '100%', justifyContent: 'space-between' }}
                        >
                            <Form.Label className="text-left text-nowrap employment-label">
                                {commissions === 'base' ? '*' : ''}
                                {com.capitalize(commissions)}
                            </Form.Label>
                            <div style={{ width: '100%' }}>
                                <Space.Compact style={{ width: '96%' }}>
                                    <DollarInput
                                        value={income[commissions]}
                                        onChange={(e) => {
                                            this.setState({
                                                income: {
                                                    ...income,
                                                    [commissions]: e,
                                                },
                                            })
                                        }}
                                    />
                                    <Select
                                        style={{ height: 44 }}
                                        defaultValue="monthly"
                                        value={income[commissions + 'monthly']}
                                        onChange={(e) => {
                                            this.setState({
                                                income: {
                                                    ...income,
                                                    [commissions + 'monthly']: e,
                                                },
                                            })
                                        }}
                                    >
                                        <Select.Option value="monthly">Monthly</Select.Option>
                                        <Select.Option value="yearly">Yearly</Select.Option>
                                    </Select>
                                </Space.Compact>
                            </div>
                        </Form.Group>
                    </div>
                    <div>
                        {income[commissions + 'monthly'] === 'hourly' && (
                            <Form.Group controlId="basehoursperweek" className="text-left mt-4" style={{ width: '96%' }}>
                                <Form.Label className="text-left text-nowrap">
                                    Hours Per Week:
                                </Form.Label>

                                <Form.Control
                                    isValid={false}
                                    size="md"
                                    required
                                    min={1}
                                    max={80}
                                    step={1}
                                    defaultValue={income[commissions + 'hoursperweek']}
                                    onChange={(e) => {
                                        this.setState({
                                            income: {
                                                ...income,
                                                [commissions + 'hoursperweek']: e.target.value,
                                            },
                                        })
                                    }}
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please provide overtime income.
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </div>
                </Col>
            )
        }

        return (
            <div>
                <Form.Row>
                    <Col>
                        <Row>
                            <Col className="ml-0 pl-0" md={6} xs={12}>
                                <Form.Group
                                    controlId="CEmployer1"
                                    className="text-left input-groups"
                                >
                                    <Form.Label className="text-left text-nowrap employment-label">
                                        *Employer
                                    </Form.Label>
                                    <Form.Control
                                        isValid={false}
                                        size="md"
                                        required
                                        name="cemployer"
                                        placeholder="Enter your employer"
                                        type="text"
                                        defaultValue={employername}
                                        onChange={(e) => {
                                            this.setState({
                                                employername: e.target.value,
                                            })
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Provide employer.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col
                                className="ml-0 pl-0 employment-autocomplete"
                                md={6}
                                xs={12}
                            >
                                <Autocomplete
                                    required
                                    id="cOtherTitle"
                                    suggestions={titles}
                                    name="position"
                                    type="text"
                                    label="*Position/Title"
                                    valid=""
                                    invalid="Please enter position"
                                    value={positiontitle}
                                    onChange={(e) => {
                                        this.setState({
                                            positiontitle: e.target.value,
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Row>
                            <Col className="ml-0 pl-0 mt-4" md={6} xs={12}>
                                <Form.Group className="text-left">
                                    <Form.Label className="text-left text-nowrap">*Phone number
                                        <Tips
                                            content={
                                                <div>
                                                    Specify the HR/Personnel or hiring manager phone number to verify employment.
                                                </div>
                                            }
                                        />
                                    </Form.Label>
                                    <Form.Control
                                        size="sm"
                                        isValid={false}
                                        required
                                        type="text"
                                        placeholder="(###)-###-####"
                                        pattern="^\([1-9][0-9]{2,2}\)-[0-9]{3,3}-[0-9]{4,4}$"
                                        value={phone}
                                        onChange={(e) => {
                                            const phone = com.fixPhoneInput(e.target.value)
                                            this.setState({ phone })
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Row>
                            <Col className="ml-0 pl-0 mt-4" md={12} xs={12}>
                                <AddressAutocomplete
                                    id="EmployerAddr"
                                    label="Employment address"
                                    name="employeraddr"
                                    defaultValue={employeraddress}
                                    onChange={(e) => {
                                        this.setState({
                                            employeraddress: e.target.value,
                                        })
                                    }}
                                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                    placeholder="Enter employer address"
                                    goodfeedback=""
                                    badfeedback="Please provide address."
                                />
                            </Col>
                        </Row>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Row>
                            <Col className="ml-0 pl-0 mt-4" md={6} xs={12}>
                                <Form.Group controlId="from" className="text-left">
                                    <Form.Label className="text-left text-nowrap">
                                        *Start date
                                    </Form.Label>
                                    <div>
                                        <DatePicker
                                            name="dateofstart"
                                            style={{ width: '100%' }}
                                            required
                                            size="sm"
                                            placeholder="MM/DD/YYYY"
                                            format="MM/DD/YYYY"
                                            value={from ? dayjs(from) : null}
                                            onChange={(e) => {
                                                this.setState({
                                                    from: dayjs(
                                                        dayjs(e).format(
                                                            'MM/DD/YYYY'
                                                        )
                                                    ),
                                                })
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Form.Row>
                {selfemployed === false && (
                    <div>
                        <Row>
                            <Col className="p-0">
                                <Row>
                                    {IncomeType('base')}
                                    {IncomeType('commissions')}
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className=" p-0">
                                <Row>
                                    {IncomeType('overtime')}
                                    {IncomeType('bonuses')}
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className=" p-0">
                                <Row>
                                    {IncomeType('military')}
                                    {IncomeType('other')}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )}
                {selfemployed === true && (
                    <>
                        <Form.Row className="mb-4">
                            <Row>
                                <Col className="ml-0 pl-0 mt-4" md={4} xs={12}>
                                    <Form.Group
                                        controlId="share"
                                        className="text-left"
                                    >
                                        <Form.Label className="text-left text-nowrap">
                                            Ownership share
                                        </Form.Label>
                                        <div>
                                            <Input
                                                suffix="%"
                                                onChange={(e) => {
                                                    if (e.target.value > 100) {
                                                        this.setState({
                                                            percentownership: 100,
                                                        })
                                                    } else {
                                                        this.setState({
                                                            percentownership:
                                                                e.target.value.replaceAll(
                                                                    '.',
                                                                    ''
                                                                ),
                                                        })
                                                    }
                                                }}
                                                pattern="^[0-9.]+$"
                                                value={percentownership}
                                            />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide phone number or N/A.1
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col className="ml-0 pl-0 mt-4" md={4} xs={12}>
                                    <Form.Group
                                        controlId="firsttime"
                                        className="text-left"
                                    >
                                        <Form.Label className="text-left text-nowrap">
                                            Income or Loss
                                        </Form.Label>
                                        <div>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                    height: 44,
                                                }}
                                                value={incomeorlossperiod}
                                                onChange={(e) => {
                                                    this.setState({
                                                        incomeorlossperiod: e,
                                                    })
                                                }}
                                            >
                                                <Select.Option value="monthly">
                                                    Monthly
                                                </Select.Option>
                                                <Select.Option value="yearly">
                                                    Yearly
                                                </Select.Option>
                                            </Select>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col className="ml-0 pl-0 mt-4" md={4} xs={12}>
                                    <Form.Group
                                        controlId="dividend"
                                        className="text-left"
                                    >
                                        <Form.Label className="text-left text-nowrap">
                                            Amount
                                        </Form.Label>
                                        <div className="InputWithSignWrap">
                                            <div className="InputWithSign-prefix">
                                                $
                                            </div>
                                            <Input
                                                className="InputWithSign-input"
                                                value={incomeorloss}
                                                onChange={(e) => {
                                                    this.setState({
                                                        incomeorloss:
                                                            e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide amount.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form.Row>
                        {!isNaN(parseInt(percentownership)) &&
                        parseInt(percentownership) >= 25 ? (
                            <div>
                                <Form.Row className="p-0 pt-3">
                                    <Col className="ml-0 mt-2 w-100 align-middle  text-wrap sectionquestion">
                                        How do you file your taxes?
                                    </Col>
                                </Form.Row>
                                <Form.Row className="mt-2 ">
                                    <Col className="mr-2" xs="auto">
                                        <input
                                            type="radio"
                                            required
                                            checked={
                                                selfincomedoc === 'schedulec'
                                            }
                                            onChange={ondocs}
                                            value="schedulec"
                                            name={'taxes' + key}
                                            id={'schedulec' + key}
                                        />
                                        <label
                                            id="scheduleclabel"
                                            className="zeitro-radio divlink"
                                            htmlFor={'schedulec' + key}
                                        >
                                            Schedule C
                                        </label>
                                    </Col>
                                    <Col className="mr-2" xs="auto">
                                        <input
                                            type="radio"
                                            required
                                            checked={
                                                selfincomedoc === 'schedulef'
                                            }
                                            onChange={ondocs}
                                            value="schedulef"
                                            name={'taxes' + key}
                                            id={'schedulef' + key}
                                        />
                                        <label
                                            id="scheduleflabel"
                                            className="zeitro-radio divlink"
                                            htmlFor={'schedulef' + key}
                                        >
                                            Schedule F
                                        </label>
                                    </Col>
                                    <Col className="mr-2" xs="auto">
                                        <input
                                            type="radio"
                                            required
                                            checked={selfincomedoc === 'f1065'}
                                            onChange={ondocs}
                                            value="f1065"
                                            name={'taxes' + key}
                                            id={'f1065' + key}
                                        />
                                        <label
                                            id="f1065label"
                                            className="zeitro-radio divlink"
                                            htmlFor={'f1065' + key}
                                        >
                                            1065 Partnership Return
                                        </label>
                                    </Col>
                                    <Col className="mr-2" xs="auto">
                                        <input
                                            type="radio"
                                            required
                                            checked={selfincomedoc === 'f1120S'}
                                            onChange={ondocs}
                                            value="f1120S"
                                            name={'taxes' + key}
                                            id={'f1120S' + key}
                                        />
                                        <label
                                            id="f1120Slabel"
                                            className="zeitro-radio divlink"
                                            htmlFor={'f1120S' + key}
                                        >
                                            1120S for S Corp
                                        </label>
                                    </Col>
                                    <Col className="mr-2" xs="auto">
                                        <input
                                            type="radio"
                                            required
                                            checked={selfincomedoc === 'f1120'}
                                            onChange={ondocs}
                                            value="f1120"
                                            name={'taxes' + key}
                                            id={'f1120' + key}
                                        />
                                        <label
                                            id="f1120label"
                                            className="zeitro-radio divlink"
                                            htmlFor={'f1120' + key}
                                        >
                                            1120 for C Corp
                                        </label>
                                    </Col>
                                    <Col className=""></Col>
                                </Form.Row>
                            </div>
                        ) : (
                            <div>
                                <Form.Row>
                                    <Col className="mt-2 w-100 align-middle  text-wrap sectionquestion">
                                        How do you document your income?
                                    </Col>
                                </Form.Row>
                                <Form.Row className="mt-2 ml-0 ">
                                    <Col className="mr-2 p-0" xs="auto">
                                        <input
                                            type="radio"
                                            required
                                            checked={
                                                selfincomedoc === 'w2paystub'
                                            }
                                            onChange={ondocs}
                                            value="w2paystub"
                                            name={'taxes' + key}
                                            id={'w2paystub' + key}
                                        />
                                        <label
                                            id="w2paystublabel"
                                            className="zeitro-radio divlink"
                                            htmlFor={'w2paystub' + key}
                                        >
                                            W2/Paystubs
                                        </label>
                                    </Col>
                                    <Col className="mr-2" xs="auto">
                                        <input
                                            type="radio"
                                            required
                                            checked={
                                                selfincomedoc === 'schedulek1'
                                            }
                                            onChange={ondocs}
                                            value="schedulek1"
                                            name={'taxes' + key}
                                            id={'schedulek1' + key}
                                        />
                                        <label
                                            id="schedulek1label"
                                            className="zeitro-radio divlink"
                                            htmlFor={'schedulek1' + key}
                                        >
                                            Schedule K1
                                        </label>
                                    </Col>
                                    <Col className=""></Col>
                                </Form.Row>
                            </div>
                        )}
                    </>
                )}
                <Form.Row>
                    <Col className=" px-1 mt-4">
                        <Form.Group
                            controlId="employedbyagent"
                            className="text-left"
                        >
                            <Checkbox
                                onChange={(e) => {
                                    this.setState({
                                        employedbyagent: e.target.checked,
                                    })
                                }}
                                checked={employedbyagent}
                            >
                                Check if employed by a family member, property
                                seller, real estate agent, or other party to the
                                transaction
                            </Checkbox>
                        </Form.Group>
                    </Col>
                </Form.Row>
            </div>
        )
    }

    canShowOtherIncome = () => {
        if (!this.canShowDividends()) return false

        const { who } = this.props
        const { hasdividends } = this.props.application[who].income

        if (hasdividends === null) return false
        if (hasdividends === false) return true
        if (hasdividends && this.state.isdividendsvalid) return true

        return false
    }

    enoughCurrentJob = () => {
        const { who } = this.props
        const { yearsatlineofwork } = this.props.application[who].occupation

        if (yearsatlineofwork.length === 0) return false

        return parseInt(yearsatlineofwork) >= 2
    }

    processOtherIncome = (val, index) => {
        if (null === val) return ''

        const { description, monthlyamount, key } = val
        return this.extraIncome(index, description, monthlyamount, key)
    }

    hideOccupation = () => {
        this.props.updateBorrowerHasOccupation({ target: { checked: true } })
    }
    showOccupation = () => {
        this.props.updateBorrowerHasOccupation({ target: { checked: true } })
    }

    addOtherSources = (e) => {
        com.touch()

        const { who } = this.props
        if (e.target.value !== 'true') {
            this.props.clearBorrowerAdditionalIncome(who)
        } else {
            this.props.addBorrowerAdditionalIncome(0, who)
        }
    }

    addMoreSources = () => {
        com.touch()
        this.props.addBorrowerAdditionalIncome(0, this.props.who)
    }

    addOtherSources = (e) => {
        com.touch()

        const { who } = this.props
        if (e !== 'true') {
            this.props.clearBorrowerAdditionalIncome(who)
        } else {
            this.props.addBorrowerAdditionalIncome(0, who)
        }
    }

    fieldOccupation = () => {
        const {employername, from, to, positiontitle, income} = this.props.application.coborrower.previousoccupations[0]
        return employername === '' || from === '' || to === '' || positiontitle === '' || income === ''
    }

    onFinish = () => {
        const { who } = this.props
        const { completedsteps } = this.props.application[who] || {}

        if (!completedsteps?.employment) {
            this.props.changeBorrowerInfo(
                { ...completedsteps, employment: true },
                who,
                'completedsteps'
            )
        }
    }

    handleSubmit = (e) => {
        const { who } = this.props
        const { mortgageapplied } = this.props.application.product
        const { nonqm, occupation, previousoccupations } = this.props.application[who]

        if (mortgageapplied === 'nonqm') {
            if (!nonqm.nonqmincomeamount) {
                alert('Please provide your non-QM income amount.')
                return false
            }
        } else {
            if (occupation.hasoccupation === null) {
                alert('Please select your occupation.')
                return false
            }

            const { employmentType, employed2years } = this.state
            if (employmentType === null || employmentType === '' || employed2years === '') {
                return false
            }

            if (employmentType === 'unemployed' && employed2years === 'false' && isEmpty(previousoccupations)) {
                this.setState({ showNoticeModal: true })
                return false
            }

            if (employmentType === 'employed' && occupation.from && isEmpty(previousoccupations)) {
                const monthsEmployed = dayjs().diff(dayjs(occupation.from), 'month')
                // If the most recent employment is less than 2 years, show an notice modal
                if (monthsEmployed < 24) {
                    this.setState({ showNoticeModal: true })
                    return false
                }
            }
        }

        return true
    }

    handlePrevious = () => {
        return true
    }

    changeTab = (tab) => {
        this.setState(
            { tabAvtive: tab, who: tab === 0 ? com.borrower : com.coborrower },
            () => {
                this.setState({
                    employmentType: getEmploymentType(
                        this.props.application,
                        this.props.who
                    ),
                })
                this.completion()
                eventBus.emit('changeProgressValue')
            }
        )
    }

    editEmployment = (index) => {
        const isotherJob = index !== 0
        const { who } = this.props
        const { otheroccupations, occupation } = this.props.application[who]
        const employment = isotherJob ? otheroccupations[index - 1] : occupation

        const {
            selfemployed,
            employername,
            positiontitle,
            phone,
            yearsatlineofwork,
            monthsatlineofwork,
            from,
            employeraddress,
            income: {
                basemonthly,
                basehoursperweek,
                base,
                overtimemonthly,
                overtime,
                bonusesmonthly,
                bonuses,
                commissionsmonthly,
                commissions,
                militarymonthly,
                military,
                othermonthly,
                other,
            },
            employedbyagent,
            percentownership,
            incomeorlossperiod,
            incomeorloss,
            selfincomedoc,
        } = employment

        this.setState({
            editIndex: index,
            validated: false,
            isEdit: true,
            isotherJob,
            showEmploymentModal: true,
            selfemployed,
            employername,
            positiontitle,
            phone,
            yearsatlineofwork,
            monthsatlineofwork,
            from,
            employeraddress,
            income: {
                basemonthly,
                basehoursperweek,
                base,
                overtimemonthly,
                overtime: overtime === '' ? 0 : overtime,
                bonusesmonthly,
                bonuses: bonuses === '' ? 0 : bonuses,
                commissionsmonthly,
                commissions: commissions === '' ? 0 : commissions,
                militarymonthly,
                military: military === '' ? 0 : military,
                othermonthly,
                other: other === '' ? 0 : other,
            },
            employedbyagent,
            percentownership,
            incomeorlossperiod,
            incomeorloss,
            selfincomedoc,
        })
    }

    extraIncome = (index, desc, amount, key) => {
        index = parseInt(index)

        const removePreviousOccupation = (event) => {
            this.props.removeBorrowerAdditionalIncome(index, this.props.who)
        }

        const aupdateBorrowerAdditionalIncome = (who, verb) => {
            return (event) => {
                com.touch()

                this.props.updateBorrowerAdditionalIncome(
                    { target: { value: event } },
                    who,
                    verb,
                    index
                )
            }
        }

        const updateBorrowerAdditionalIncome = (who, verb) => {
            return (event) => {
                com.touch()

                this.props.updateBorrowerAdditionalIncome(
                    event,
                    who,
                    verb,
                    index
                )
            }
        }

        const onSelectAdditionalIncome = (e) => {
            com.touch()

            const { who } = this.props

            this.props.updateBorrowerAdditionalIncome(
                { target: { value: e } },
                who,
                'incometype',
                index
            )

            let description = ''
            com.othersouresofincome.forEach((x) => {
                if (x[0] === e) {
                    description = x[1]
                }
            })

            if (e === 'Other') description = ''

            this.props.updateBorrowerAdditionalIncome(
                { target: { value: description } },
                who,
                'description',
                index
            )
        }

        const updateMonthly = (who, verb) => {
            return (event) => {
                com.touch()
                return this.props.updateBorrowerAdditionalIncome(
                    event,
                    who,
                    verb,
                    index
                )
            }
        }

        const showDescription = () => {
            const { who } = this.props
            const { incometype } = this.props.application[who].income.otherincome[index]

            let description = ''
            com.othersouresofincome.forEach((x) => {
                if (x[0] === incometype) {
                    description = x[2]
                }
            })

            if (incometype === 'Other' || description === '') return 'Description:'

            return tooltip('Description:', description, 'auto', '', '')
        }

        const { who } = this.props

        const {
            period,
            incometype,
            description,
            amount: incomeAmount,
            otherincome,
        } = this.props.application[who].income.otherincome[index]

        return (
            <div className="p-0 mx-0 my-2" key={key}>
                <Row className="mx-0 pt-1 mb-2">
                    <Col>
                        <Form.Row>
                            <Col className="" xs="auto">
                                <Form.Group controlId="firsttime" className="text-left">
                                    <Form.Label>Period:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        style={{ width: '120px' }}
                                        type="text"
                                        size="md"
                                        defaultValue={period}
                                        onChange={updateMonthly(who, 'period')}
                                    >
                                        <Select.Option value="monthly">Monthly</Select.Option>
                                        <Select.Option value="yearly">Yearly</Select.Option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" className="">
                                <Form.Group controlId={'description' + index} className="text-left">
                                    <Form.Label className="text-left text-nowrap">
                                        {showDescription()}
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            isValid={false}
                                            size="md"
                                            readOnly={incometype !== 'Other'}
                                            required
                                            pattern="^.+$"
                                            name={'description' + index}
                                            type="text"
                                            value={description}
                                            onChange={updateBorrowerAdditionalIncome(who, 'description')}
                                        />
                                        <DropdownButton
                                            as={InputGroup.Append}
                                            variant="dropdown"
                                            className="incdropdown"
                                            title=""
                                            id="dropdown-income"
                                            size="md"
                                            onSelect={onSelectAdditionalIncome}
                                        >
                                            {' '}
                                            {com.othersouresofincome.map(
                                                (st) => (
                                                    <Dropdown.Item key={st[0]} eventKey={st[0]} href="#">
                                                        {st[1]}
                                                    </Dropdown.Item>
                                                )
                                            )}
                                        </DropdownButton>
                                        <Form.Control.Feedback type="invalid">
                                            Please describe the source of
                                            income.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" style={{ width: '10.5em' }}>
                                <Form.Group controlId="overtime" className="text-left">
                                    <Form.Label className="text-left text-nowrap">
                                        Amount
                                    </Form.Label>
                                    <div>
                                        <DollarInput
                                            size="large"
                                            onChange={aupdateBorrowerAdditionalIncome(who, 'amount')}
                                            value={incomeAmount}
                                        />
                                    </div>

                                    <Form.Control.Feedback type="invalid">
                                        Please provide income.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top px-1">
                        <i
                            hidden={index !== otherincome.length - 1}
                            className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink"
                            onClick={this.addMoreSources}
                        />
                        <i
                            hidden={index === otherincome.length - 1}
                            className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"
                        >
                            {' '}
                        </i>
                        <i
                            className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink"
                            onClick={removePreviousOccupation}
                        ></i>
                    </Col>
                </Row>
            </div>
        )
    }

    render = () => {
        const { who } = this.props
        const { employername, positiontitle, phone, from, employeraddress, income } = this.props.application[who].occupation
        const fieldOccupation = employername === '' || positiontitle === '' || phone === '' || from === '' || employeraddress === '' || income.base === ''

        const changeEmploymentTypes = (e, who) => {
            this.setState({ employmentType: e })
            com.touch()

            switch (e) {
                case 'employed':
                    this.props.changeCurrentOccupation(e, who, 'hasoccupation')
                    this.props.changeCurrentOccupationCheck(
                        { target: { checked: true } },
                        who,
                        'selfemployed'
                    )
                    setTimeout(() => {
                        if (fieldOccupation) {
                            this.editEmployment(0)
                        }
                    }, 200)
                    break
                case 'selfemployed':
                    this.props.changeCurrentOccupation(
                        'employed',
                        who,
                        'hasoccupation'
                    )
                    this.props.changeCurrentOccupationCheck(
                        { target: { checked: false } },
                        who,
                        'selfemployed'
                    )
                    this.setState({ showSelf: true })
                    break
                case 'unemployed':
                case 'retired':
                    this.props.changeCurrentOccupationCheck(
                        { target: { checked: true } },
                        who,
                        'selfemployed'
                    )
                    this.props.changeCurrentOccupation(e, who, 'hasoccupation')
                    break
                default:
                    break
            }
        }

        const getPreviousOccupation = (who) => {
            const previousOccupations = this.props.application[who]?.previousoccupations
            if (!previousOccupations || !previousOccupations[0]) {
                return {
                    employername: '',
                    from: '',
                    to: '',
                    positiontitle: '',
                    income: '',
                    incomeperiod: ''
                }
            }
            return previousOccupations[0]
        }

        const changePreviousJob = (e) => {
            this.setState({ employed2years: e })
            if (e === 'true') {
                addAdditionaOccupation(e, who)
                const { employername, from, to, positiontitle, income, incomeperiod } = getPreviousOccupation(who)
                setTimeout(() => {
                    const fieldOccupation =
                        employername === '' ||
                        from === '' ||
                        to === '' ||
                        positiontitle === '' ||
                        income === '' ||
                        incomeperiod === ''

                    if (fieldOccupation) {
                        editPreviousEmployment(0)
                    }
                }, 200)
            } else {
                clearAdditionalOccupations(e, who)
            }
        }

        const addAdditionaOccupation = (event, who) => {
            com.touch()
            const reffrom = React.createRef()
            const refto = React.createRef()
            this.refsfrom.push(reffrom)
            this.refsto.push(refto)
            return this.props.addAdditionaOccupation(event, who)
        }

        const clearAdditionalOccupations = (event, who) => {
            com.touch()
            return this.props.clearAdditionalOccupations(event, who)
        }

        const formatAmount = (value) => {
            return value ? Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, }) : '--'
        }

        const formatTaxes = (value) => {
            const obj = {
                schedulec: 'Schedule C',
                schedulef: 'Schedule F',
                f1065: '1065 Partnership Return',
                f1120S: '1120S for S Corp',
                f1120: '1120 for C Corp',
                w2paystub: 'W2/Paystubs',
                schedulek1: 'Schedule K1',
            }

            return obj[value]
        }

        const getEmploymentList = () => {
            const { otheroccupations, occupation } = this.props.application[this.props.who]
            const otherOccupations = otheroccupations && otheroccupations.length > 0 ? otheroccupations : []
            const occupations = [
                ...[occupation],
                ...otherOccupations,
            ]

            return occupations.map((obj) => [
                {
                    label: 'Employer',
                    value: obj.employername ? obj.employername : '--',
                    show: true,
                },
                {
                    label: 'Position/Title',
                    value: obj.positiontitle ? obj.positiontitle : '--',
                    show: true,
                },
                {
                    label: 'Date',
                    value: obj.from ? `${obj.from} - Present` : '--',
                    span: 12,
                    show: true,
                },
                {
                    label: 'Base',
                    value: formatAmount(obj.income.base),
                    show: !obj.selfemployed,
                    monthly: obj.income.basemonthly,
                },
                {
                    label: 'Commission',
                    value: obj.income.commissions ? formatAmount(obj.income.commissions) : '--',
                    show: !obj.selfemployed,
                    monthly: obj.income.commissionsmonthly,
                },
                {
                    label: 'Overtime',
                    value: obj.income.overtime ? formatAmount(obj.income.overtime) : '--',
                    show: !obj.selfemployed,
                    monthly: obj.income.overtimemonthly,
                },
                {
                    label: 'Bonus',
                    value: obj.income.bonuses ? formatAmount(obj.income.bonuses) : '--',
                    show: !obj.selfemployed,
                    monthly: obj.income.bonusesmonthly,
                },
                {
                    label: 'Military',
                    value: obj.income.military ? formatAmount(obj.income.military) : '--',
                    show: !obj.selfemployed,
                    monthly: obj.income.militarymonthly,
                },
                {
                    label: 'Other',
                    value: obj.income.other ? formatAmount(obj.income.other) : '--',
                    show: !obj.selfemployed,
                    monthly: obj.income.othermonthly,
                },
                {
                    label: 'Ownership share',
                    value:
                        obj.percentownership !== ''
                            ? obj.percentownership + '%'
                            : '--',
                    show: obj.selfemployed,
                },
                {
                    label: 'Income or Loss',
                    value: obj.incomeorloss
                        ? `${formatAmount(obj.incomeorloss)}(${obj.incomeorlossperiod})`
                        : '--',
                    show: obj.selfemployed,
                },
                {
                    label: parseInt(obj.percentownership) >= 25 ? 'Taxes' : 'Document income',
                    value: obj.selfincomedoc ? formatTaxes(obj.selfincomedoc) : '--',
                    show: obj.selfemployed,
                },
            ])
        }

        const editEmployment = (index) => {
            const isotherJob = index !== 0

            const { otheroccupations, occupation } = this.props.application[this.props.who]

            let employment = ''
            if (isotherJob) {
                employment = otheroccupations[index - 1]
            } else {
                employment = occupation
            }

            const {
                selfemployed,
                employername,
                positiontitle,
                phone,
                yearsatlineofwork,
                monthsatlineofwork,
                from,
                employeraddress,
                income: {
                    basemonthly,
                    basehoursperweek,
                    base,
                    overtimemonthly,
                    overtime,
                    bonusesmonthly,
                    bonuses,
                    commissionsmonthly,
                    commissions,
                    militarymonthly,
                    military,
                    othermonthly,
                    other,
                },
                employedbyagent,
                percentownership,
                incomeorlossperiod,
                incomeorloss,
                selfincomedoc,
            } = employment

            this.setState({
                employedbyagent,
                editIndex: index,
                validated: false,
                isEdit: true,
                isotherJob,
                showEmploymentModal: true,
                selfemployed,
                employername,
                positiontitle,
                phone,
                yearsatlineofwork,
                monthsatlineofwork,
                from,
                employeraddress,
                income: {
                    basemonthly,
                    basehoursperweek,
                    base,
                    overtimemonthly,
                    overtime: overtime === '' ? 0 : overtime,
                    bonusesmonthly,
                    bonuses: bonuses === '' ? 0 : bonuses,
                    commissionsmonthly,
                    commissions: commissions === '' ? 0 : commissions,
                    militarymonthly,
                    military: military === '' ? 0 : military,
                    othermonthly,
                    other: other === '' ? 0 : other,
                },

                percentownership,
                incomeorlossperiod,
                incomeorloss,
                selfincomedoc,
            })
        }

        const deleteEmployment = (index) => {
            return this.props.removeBorrowerOtherOccupation(who, index - 1)
        }

        const addCurrentOccupation = () => {
            const reffrom = React.createRef()
            this.crefsfrom.push(reffrom)
            return this.props.addBorrowerOtherOccupation(who)
        }

        const addEmployment = () => {
            this.setState({
                editIndex: -1,
                validated: false,
                isEdit: false,
                isotherJob: true,
                showEmploymentModal: true,
                selfemployed: false,
                employername: '',
                positiontitle: '',
                phone: '',
                yearsatlineofwork: '',
                monthsatlineofwork: '',
                from: '',
                employeraddress: '',
                income: {
                    basemonthly: '',
                    basehoursperweek: '',
                    base: '',
                    overtimemonthly: '',
                    overtime: 0,
                    bonusesmonthly: '',
                    bonuses: 0,
                    commissionsmonthly: '',
                    commissions: 0,
                    militarymonthly: '',
                    military: 0,
                    othermonthly: '',
                    other: 0,
                },
                employedbyagent: false,
                percentownership: '',
                incomeorlossperiod: '',
                incomeorloss: '',
                selfincomedoc: '',
            })
        }

        const previousEmployed = () => {
            const { previousoccupations } = this.props.application[this.props.who]

            return previousoccupations.map((obj) => [
                {
                    label: 'Employer',
                    value: obj.employername ? obj.employername : '--',
                },
                {
                    label: 'Position/Title',
                    value: obj.positiontitle ? obj.positiontitle : '--',
                },
                {
                    label: 'Address',
                    value: obj.employeraddress ? obj.employeraddress : '--',
                    span: 12,
                },
                {
                    label: 'Dates',
                    value: `${obj.from} - ${obj.to}`,
                },
                {
                    label: 'Total income',
                    value: `${formatAmount(obj.income)}/${obj.incomeperiod ? obj.incomeperiod : 'yearly'}`,
                },
            ])
        }

        const addPreviousEmployment = () => {
            this.setState({
                isEdit: false,
                validated: false,
                showPreviousEmploymentModal: true,
                previousoccupation: {
                    employername: '',
                    from: '',
                    to: '',
                    employeraddress: '',
                    positiontitle: '',
                    income: '',
                    incomeperiod: '',
                    selfemployed: false,
                    employedbyagent: false,
                },
            })
        }

        const deletePreviousEmployment = (event, index) => {
            this.props.removeAdditionalOccupation(event, who, index)
        }

        const editPreviousEmployment = (index) => {
            const { who } = this.props
            const {
                employername,
                from,
                to,
                employeraddress,
                positiontitle,
                income,
                incomeperiod,
                selfemployed,
                employedbyagent,
            } = this.props.application[who].previousoccupations[index]

            this.setState({
                isEdit: true,
                validated: false,
                editIndex: index,
                showPreviousEmploymentModal: true,
                previousoccupation: {
                    employername,
                    from: from === '' ? null : dayjs(from),
                    to: to === '' ? null : dayjs(to),
                    employeraddress,
                    positiontitle,
                    income,
                    incomeperiod,
                    selfemployed,
                    employedbyagent,
                },
            })
        }

        const employmentModal = () => {
            const creditCheckContinue = () => {
                const {
                    selfemployed,
                    employername,
                    positiontitle,
                    phone,
                    yearsatlineofwork,
                    monthsatlineofwork,
                    from,
                    employeraddress,
                    percentownership,
                    selfincomedoc,
                    income,
                    incomeorlossperiod,
                    incomeorloss,
                } = this.state

                const formatDateForRedux = (date) => {
                    if (!date) return ''
                    if (dayjs.isDayjs(date)) return date.format('MM/DD/YYYY')
                    return date
                }

                const employmentData = {
                    selfemployed,
                    employername,
                    positiontitle,
                    phone,
                    yearsatlineofwork,
                    monthsatlineofwork,
                    percentownership,
                    from: formatDateForRedux(from),
                    employeraddress,
                    selfincomedoc,
                    incomeorlossperiod,
                    incomeorloss,
                }

                const incomeData = {
                    basemonthly: income.basemonthly,
                    basehoursperweek: income.basehoursperweek,
                    base: income.base,
                    overtimemonthly: income.overtimemonthly,
                    overtime: income.overtime,
                    bonusesmonthly: income.bonusesmonthly,
                    bonuses: income.bonuses,
                    commissionsmonthly: income.commissionsmonthly,
                    commissions: income.commissions,
                    militarymonthly: income.militarymonthly,
                    military: income.military,
                    othermonthly: income.othermonthly,
                    other: income.other,
                }

                const { who } = this.props
                const { otheroccupations } = this.props.application[who]

                let index = 0
                if (this.state.isEdit) {
                    if (this.state.editIndex !== 0) {
                        index = this.state.editIndex - 1
                    }
                } else {
                    addCurrentOccupation()
                    index = otheroccupations ? otheroccupations.length : 0
                }

                if (this.state.editIndex !== 0) {
                    Object.entries(employmentData).forEach(([key, value]) => {
                        this.props.changeBorrowerOtherOccupationValue(
                            value,
                            index,
                            who,
                            key
                        )
                    })
                    Object.entries(incomeData).forEach(([key, value]) => {
                        this.props.changeBorrowerOtherOccupationIncome(
                            value,
                            index,
                            who,
                            key
                        )
                    })
                    this.props.changeBorrowerOtherOccupationValue(
                        this.state.employedbyagent,
                        index,
                        who,
                        'employedbyagent'
                    )
                } else {
                    Object.entries(employmentData).forEach(([key, value]) => {
                        this.props.changeOccupationInfo(
                            { target: { value } },
                            who,
                            key
                        )
                    })
                    Object.entries(incomeData).forEach(([key, value]) => {
                        this.props.updateIncomeMonthly(
                            { target: { value } },
                            who,
                            key
                        )
                    })
                    this.props.changeOccupationCheck(
                        { target: { checked: this.state.employedbyagent } },
                        who,
                        'employedbyagent'
                    )
                }
                this.setState({ showEmploymentModal: false })
            }

            const submitEmployment = (e) => {
                e.preventDefault()
                e.stopPropagation()
                if (this.form.current.reportValidity() === false) {
                    this.setState({ validated: true })
                    return false
                }
                creditCheckContinue()
            }

            const closeModal = () => {
                this.setState({ showEmploymentModal: false })

                const { who } = this.props
                const { employername, positiontitle, phone, from } = this.props.application[who].occupation
                const fieldOccupation = employername === '' || positiontitle === '' || phone === '' || from === ''
                if (this.state.editIndex === 0 && fieldOccupation) {
                    changeEmploymentTypes('unemployed', who)
                }
            }

            const { selfemployed } = this.state

            return (
                <Modal
                    className="employment-modal"
                    size="lg"
                    show={this.state.showEmploymentModal}
                    onHide={closeModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add employment</Modal.Title>
                    </Modal.Header>
                    <Form ref={this.form} noValidate onSubmit={submitEmployment} validated={this.state.validated}>
                        <Modal.Body style={{ padding: '0 40px 14px', maxHeight: '80vh', overflow: 'auto' }}>
                            <div style={{ fontSize: '14px', lineHeight: '20px', color: '#6E6E70', margin: '14px 0 20px 0' }}>
                                * Indicates required
                            </div>
                            <Row className="mb-4">
                                <Col className="px-0" md={6} xs={12}>
                                    <Form.Group className="inputSection">
                                        <Form.Label>
                                            *Are you the business owner or self-employed?
                                        </Form.Label>
                                        <CheckGroup
                                            data={[
                                                { value: 'yes', label: 'Yes' },
                                                { value: 'no', label: 'No' },
                                            ]}
                                            onChangeSelect={(e) => {
                                                com.touch()
                                                // changeEmploymentTypes(e === 'yes' ? 'selfemployed' : 'employed', who)
                                                this.setState({
                                                    selfemployed: e === 'yes'
                                                })
                                                if (e === 'no') {
                                                    this.setState({ showSelf: false })
                                                }
                                            }}
                                            value={selfemployed ? 'yes' : 'no'}
                                            type="radio"
                                            direction="horizontal"
                                            required={true}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {this.EmploymentForm()}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="zeitro-primary" className="income-save-btn">
                                Save
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )
        }

        const PreviousEmploymentModal = (event) => {
            const { who } = this.props

            const saveContinue = () => {
                let index = 0
                if (this.state.isEdit) {
                    index = this.state.editIndex
                } else {
                    this.props.addAdditionaOccupation(0, who)
                    const { previousoccupations } = this.props.application[who]
                    index = previousoccupations.length
                }

                const {
                    employername,
                    employeraddress,
                    positiontitle,
                    income,
                    incomeperiod,
                    from,
                    to,
                    selfemployed,
                    employedbyagent,
                } = this.state.previousoccupation

                const employmentData = {
                    employername,
                    employeraddress,
                    positiontitle,
                    income: isFinite(toNumber(income)) ? Math.round(toNumber(income)) : income, // DollarSReader only accepts integers
                    incomeperiod,
                }

                Object.entries(employmentData).forEach(([key, value]) => {
                    this.props.changePastOccupationInfo(
                        { target: { value } },
                        who,
                        key,
                        index
                    )
                })

                this.props.changePastOccupationDate(
                    dayjs(from).format('MM/DD/YYYY'),
                    who,
                    'from',
                    index
                )
                this.props.changePastOccupationDate(
                    dayjs(to).format('MM/DD/YYYY'),
                    who,
                    'to',
                    index
                )
                this.props.changePastOccupationCheck(
                    { target: { checked: selfemployed } },
                    who,
                    'selfemployed',
                    index
                )
                this.props.changePastOccupationCheck(
                    { target: { checked: employedbyagent } },
                    who,
                    'employedbyagent',
                    index
                )
                this.setState({ showPreviousEmploymentModal: false })
            }

            const submitEmployment = (e) => {
                e.preventDefault()
                e.stopPropagation()
                if (this.form.current.reportValidity() === false) {
                    this.setState({ validated: true })
                    return false
                }
                saveContinue()
            }

            const closeModal = () => {
                this.setState({ showPreviousEmploymentModal: false })

                const { who } = this.props
                const { employername, from, to, positiontitle, income } = this.props.application[who].previousoccupations[0]
                const fieldOccupation = employername === '' || from === '' || to === '' || positiontitle === '' || income === ''
                if (this.state.editIndex === 0 && fieldOccupation) {
                    clearAdditionalOccupations('false', who)
                }
            }

            return (
                <Modal
                    className="employment-modal"
                    size="lg"
                    show={this.state.showPreviousEmploymentModal}
                    onHide={closeModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add previous employment</Modal.Title>
                    </Modal.Header>
                    <Form ref={this.form} noValidate onSubmit={submitEmployment} validated={this.state.validated}>
                        <Modal.Body style={{ padding: '0 40px 14px', maxHeight: '80vh', overflow: 'auto' }}>
                            <div style={{ fontSize: '14px', lineHeight: '20px', color: '#6E6E70', margin: '14px 0 16px 0' }}>
                                * Indicates required
                            </div>
                            {this.PreviousEmploymentForm()}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="zeitro-primary" className="income-save-btn">
                                Save
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )
        }

        const displayConventional = () => {
            const { mortgageapplied } = this.props.application.product
            if (mortgageapplied === 'nonqm') {
                return ''
            }

            const { who } = this.props
            const { occupation, previousoccupations } = this.props.application[who]

            const getEmploymentType = () => {
                const { employmentType } = this.state
                if (employmentType === 'employed' || employmentType === 'selfemployed') {
                    return 'employed'
                }
                if (employmentType === 'unemployed' || employmentType === 'retired') {
                    return 'unemployed'
                }
            }

            const onContactLoanOfficer = () => {
                this.setState({ showNoticeModal: false })
                if (this.props.toggleLoanOfficerPanel) {
                    this.props.toggleLoanOfficerPanel(true)
                }
            }

            const onAddFormerEmployment = () => {
                this.setState({ showNoticeModal: false })
                this.setState({ employed2years: 'true' })
                addAdditionaOccupation('true', who)
                const { employername, from, to, positiontitle, income, incomeperiod } = getPreviousOccupation(who)
                setTimeout(() => {
                    const fieldOccupation =
                        employername === '' ||
                        from === '' ||
                        to === '' ||
                        positiontitle === '' ||
                        income === '' ||
                        incomeperiod === ''

                    if (fieldOccupation) {
                        editPreviousEmployment(0)
                    }
                }, 200)
            }

            const { showNoticeModal, employed2years } = this.state

            return (
                <>
                    {showNoticeModal && (
                        <div className="notice-card">
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '13px' }}>
                            <div
                                style={{
                                    width: '18px',
                                    height: '18px',
                                    borderRadius: '50%',
                                    backgroundColor: '#FFFFFF',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <InfoCircleFilled style={{ color: '#13358D', fontSize: '18px' }} />
                            </div>
                            <span style={{ fontSize: '14px', fontWeight: '600', color: '#13358D', lineHeight: '16.94px' }}>
                                Notice
                            </span>
                        </div>
                        <div
                            style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '18px',
                                color: '#333333',
                                marginBottom: '13px',
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                                whiteSpace: 'normal',
                            }}
                        >
                            To complete your loan application, we strongly encourage you to provide at least 2 years of employment history, as it is typically required for processing. However, if you're unable to provide this information at the moment, you may skip it for now and still proceed with your application.
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', alignItems: 'center' }}>
                            <Button variant="link" className="notice-link-btn" onClick={onContactLoanOfficer}>
                                Contact loan officer
                            </Button>
                                <Button
                                    variant="light"
                                    className="notice-skip-btn"
                                    onClick={() => this.setState({ showNoticeModal: false })}
                                >
                                Skip
                            </Button>
                            <Button variant="primary" className="notice-primary-btn" onClick={onAddFormerEmployment}>
                                Add former employment
                            </Button>
                        </div>
                        </div>
                    )}
                    <Row>
                        <Col className="mt-4 px-0" md={6} xs={12}>
                            <Form.Group className="inputSection">
                                <Form.Label>
                                    What is your current employment status?
                                </Form.Label>
                                <CheckGroup
                                    data={[
                                        {
                                            value: 'employed',
                                            label: 'Employed',
                                        },
                                        {
                                            value: 'unemployed',
                                            label: 'Unemployed/Retired',
                                        },
                                    ]}
                                    onChangeSelect={(e) =>
                                        changeEmploymentTypes(e, who)
                                    }
                                    value={getEmploymentType()}
                                    type="radio"
                                    direction="horizontal"
                                    required={true}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {occupation.hasoccupation === 'employed' && getEmploymentList().length > 0 && (
                        <Row>
                            <Col className='mt-4 px-0' md={12} xs={12}>
                                <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 8, color: '#62707C' }}>Employments</div>
                                {getEmploymentList().map((employment, index) => (
                                    <div className="employment-list mt-2" key={index}>
                                        <div className="employment-item">
                                            <div className="employment-top">
                                                <div className="employment-top-l">
                                                    <div className="employment-icon">
                                                        <img src="/images/employmentIcon.png" alt='employment' />
                                                    </div>
                                                    <span className="employment-name">
                                                        Employment {index + 1}
                                                    </span>
                                                </div>
                                                <div className="employment-top-r">
                                                    {index === 0 && (
                                                        <div className="primary-employment-tag">
                                                            <img src="/images/checked-blue.svg" alt="primary" />
                                                            <span>Primary employment</span>
                                                        </div>
                                                    )}
                                                    <div className="employment-edit" onClick={() => editEmployment(index)}>
                                                        <img src="/images/employmentEdit.svg" alt='edit' />
                                                    </div>
                                                    {index !== 0 && (
                                                        <div className="employment-delete" onClick={() => deleteEmployment(index)}>
                                                            <img src="/images/employmentDelete.svg" alt='delete' />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <Row className="employment-form mb-2">
                                                {employment.map(
                                                    (form, formIndex) =>
                                                        form.show && (
                                                            <Col
                                                                className="employment-form-item"
                                                                md={form.span ? form.span : 6}
                                                                key={formIndex}
                                                            >
                                                                <div className="employment-form-label">
                                                                    {form.label}:
                                                                </div>
                                                                <div
                                                                    style={form.label === 'Employer/Business Name' ? { marginLeft: 70 } : {}}
                                                                    className="employment-form-value"
                                                                >
                                                                    {form.value}{' '}
                                                                    <span style={{ marginLeft: 10 }}>
                                                                        {form.monthly === 'yearly' ? '/year' : form.monthly === 'monthly' ? '/month' : ''}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        )
                                                )}
                                            </Row>
                                        </div>
                                    </div>
                                ))}
                                {occupation.hasoccupation === 'employed' && (
                                    <>
                                        <div className="add-job" style={{ marginTop: '22px' }} onClick={addEmployment}>
                                            <span>Add employment</span>
                                        </div>
                                        <div style={{ height: '1px', backgroundColor: '#DBDCDE', margin: '16px 0' }} />
                                        <div style={{ fontSize: 16, fontWeight: 500, color: '#222222', textAlign: 'right' }}>
                                            Total monthly income: {formatAmount(calculateIncomeWho(this.props.application, who))}
                                        </div>
                                    </>
                                )}
                            </Col>
                        </Row>
                    )}
                    {occupation.hasoccupation === 'unemployed' && (
                        <>
                            <Row>
                                <Col className="mt-2 px-0" md={12} xs={12}>
                                    <Form.Group className="inputSection">
                                        <Form.Label>
                                            * If you claim employment income, you will need to demonstrate at least two years of employment, which can include tertiary education.
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-2 px-0" md={6} xs={12}>
                                    <Form.Group className="inputSection">
                                        <Form.Label>
                                            Have you been employed in the previous two years?
                                        </Form.Label>
                                        <CheckGroup
                                            data={[
                                                { value: 'true', label: 'Yes' },
                                                { value: 'false', label: 'No' },
                                            ]}
                                            onChangeSelect={(e) => changePreviousJob(e)}
                                            value={employed2years}
                                            type="radio"
                                            direction="horizontal"
                                            required={true}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    )}
                    {previousoccupations && previousoccupations.length > 0 && (
                        <Row>
                            <Col className='mt-4 px-0' md={12} xs={12}>
                                <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 8, color: '#62707C' }}>Previous employments</div>
                                {previousEmployed().map(
                                    (employment, index) => (
                                        <div className="employment-list" key={index} >
                                            <div className="employment-item">
                                                <div className="employment-top">
                                                    <div className="employment-top-l">
                                                        <div className="employment-icon">
                                                            <img src="/images/employmentIcon.png" alt='employment' />
                                                        </div>
                                                        <span className="employment-name">
                                                            Employment {index + 1}
                                                        </span>
                                                    </div>
                                                    <div className="employment-top-r">
                                                        <div
                                                            className="employment-edit"
                                                            onClick={() =>
                                                                editPreviousEmployment(index)
                                                            }
                                                        >
                                                            <img src="/images/employmentEdit.svg" alt='edit' />
                                                        </div>
                                                        <div
                                                            className="employment-delete"
                                                            onClick={(e) =>
                                                                deletePreviousEmployment(e, index)
                                                            }
                                                        >
                                                            <img src="/images/employmentDelete.svg" alt='delete' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <Row className="employment-form">
                                                    {employment.map((form, formIndex) => (
                                                        <Col
                                                            className="employment-form-item"
                                                            md={form.span ? form.span : 6}
                                                            key={formIndex}
                                                        >
                                                            <div className="employment-form-label">
                                                                {form.label} :
                                                            </div>
                                                            <div className="employment-form-value">
                                                                {form.value}
                                                            </div>
                                                        </Col>
                                                    )
                                                    )}
                                                </Row>
                                            </div>
                                        </div>
                                    )
                                )}
                                <div className="add-job" style={{ marginTop: '12px' }} onClick={addPreviousEmployment}>
                                    <span>Add employment</span>
                                </div>
                            </Col>
                        </Row>
                    )}
                </>
            )
        }

        const displayNonQM = () => {
            const { mortgageapplied } = this.props.application.product
            if (mortgageapplied !== 'nonqm') {
                return ''
            }

            const { who } = this.props
            const { nonqm } = this.props.application[who]

            return (
                <div>
                    <div className="mt-4">
                        <Form.Group className="inputSection">
                            <Form.Label>
                                Which income documentation would you like to submit for loan qualification?
                            </Form.Label>
                            <Checkbox.Group
                                className="w-600px d-flex flex-column gap-3"
                                onChange={(value) =>
                                    this.props.changeNonQMInfo(
                                        value,
                                        who,
                                        'nonqmincometype'
                                    )
                                }
                                value={nonqm.nonqmincometype}
                                required
                            >
                                <Checkbox value="VOE">VOE</Checkbox>
                                <Checkbox value="DebtServiceCoverageRatio">
                                    Debt Service Coverage Ratio (DSCR)
                                </Checkbox>
                                <Checkbox value="PersonalBankStatement12Mo">
                                    1 year personal bank statement
                                </Checkbox>
                                <Checkbox value="PersonalBankStatement24Mo">
                                    2 years personal bank statement
                                </Checkbox>
                                <Checkbox value="BusinessBankStatement12Mo">
                                    1 year business bank statement
                                </Checkbox>
                                <Checkbox value="BusinessBankStatement24Mo">
                                    2 years business bank statement
                                </Checkbox>
                                <Checkbox value="Form109912Mo">
                                    1 year Form 1099
                                </Checkbox>
                                <Checkbox value="Form109924Mo">
                                    2 years Form 1099
                                </Checkbox>
                                <Checkbox value="PL12">
                                    1 year profit and loss
                                </Checkbox>
                                <Checkbox value="PL24">
                                    2 years profit and loss
                                </Checkbox>
                                <Checkbox value="AssetDepletion">
                                    Asset depletion
                                </Checkbox>
                            </Checkbox.Group>
                        </Form.Group>
                    </div>
                    <div className="mt-4">
                        <Form.Group
                            controlId="nonqmamount"
                            className="text-left"
                            style={{ alignItems: 'baseline', width: '100%', justifyContent: 'space-between' }}
                        >
                            <Form.Label className="text-left text-nowrap employment-label">
                                What is your estimated income?
                            </Form.Label>
                            <div className="w-600px">
                                <Space.Compact className="w-100">
                                    <DollarInput
                                        value={nonqm.nonqmincomeamount}
                                        onChange={(e) =>
                                            this.props.changeNonQMInfo(
                                                e,
                                                who,
                                                'nonqmincomeamount'
                                            )
                                        }
                                    />
                                    <Select
                                        style={{ height: 44, width: '40%' }}
                                        defaultValue="monthly"
                                        value={nonqm.nonqmincomeperiod}
                                        onChange={(e) => {
                                            this.props.changeNonQMInfo(
                                                e,
                                                who,
                                                'nonqmincomeperiod'
                                            )
                                        }}
                                    >
                                        <Select.Option value="monthly">Monthly</Select.Option>
                                        <Select.Option value="yearly">Yearly</Select.Option>
                                    </Select>
                                </Space.Compact>
                            </div>
                        </Form.Group>
                    </div>
                </div>
            )
        }

        if (this.state.isNonQMCustomer === null) {
            return (
                <div className="p-5">
                    <Skeleton active />
                </div>
            )
        }

        return (
            <div className="text-left income-application newInterviewPaneProperty" style={{ paddingBottom: '10px' }}>
                <div className="page-header">
                    <span className="page-header-primary">Income</span>
                    <span className="page-header-separator">/</span>
                    <span className="page-header-secondary">Employment</span>
                </div>
                {employmentModal()}
                {PreviousEmploymentModal()}

                {this.state.isNonQMCustomer && (
                    <div className="mt-4 text-wrap">
                        <Form.Group className="inputSection">
                            <Form.Label>
                                Can you provide full documentation of your
                                income for the past two years, including tax
                                returns and W-2 forms?{' '}
                                <Tips
                                    content={
                                        <>
                                            <div>
                                                Full documentation typically includes:
                                                <ul>
                                                    <li>
                                                        Tax returns for the past 2 years
                                                    </li>
                                                    <li>
                                                        W-2 forms for the past 2 years
                                                    </li>
                                                    <li>Recent pay stubs</li>
                                                </ul>
                                            </div>

                                            <div>
                                                Conventional loans usually require full documentation to verify your income.
                                            </div>

                                            <div className="mt-2">
                                                Non-QM (Non-Qualified Mortgage) loans may be an option if you can't provide full documentation. These loans are designed for:
                                            </div>
                                            <ul>
                                                <li>
                                                    Those with non-traditional income sources
                                                </li>
                                                <li>
                                                    Borrowers with unique financial situations
                                                </li>
                                            </ul>

                                            <div>
                                                Your answer helps us guide you to the most appropriate loan type for your situation.
                                            </div>
                                        </>
                                    }
                                    placement="top"
                                    title="Income Documentation Explanation"
                                />
                            </Form.Label>
                            <div>
                                <CheckGroup
                                    className="w-600px w-100"
                                    data={[
                                        {
                                            value: 'true',
                                            label: 'Yes',
                                        },
                                        {
                                            value: 'false',
                                            label: 'No',
                                        },
                                    ]}
                                    onChangeSelect={(e) => {
                                        if (e === 'true') {
                                            this.props.updateProduct(
                                                'conventional',
                                                'mortgageapplied'
                                            )
                                        } else {
                                            this.props.updateProduct(
                                                'nonqm',
                                                'mortgageapplied'
                                            )
                                        }
                                    }}
                                    value={
                                        this.props.application.product
                                            .mortgageapplied !== 'nonqm'
                                            ? 'true'
                                            : 'false'
                                    }
                                    type="radio"
                                    direction="horizontal"
                                />
                            </div>
                        </Form.Group>
                    </div>
                )}

                {displayNonQM()}
                {displayConventional()}
            </div>
        )
    }
}

Income = connect(mapStateToPropsDetails, mapDispatchToPropsDetails, null, {
    forwardRef: true,
})(Income)

export class OtherIncome extends Component {
    constructor(props) {
        super(props)

        this.formRef = React.createRef()
        this.form = React.createRef()

        const { who } = this.props
        const { firstname, lastname } = this.props.application[who]
        this.state = {
            tabAvtive: 0,
            showEmploymentModal: false,
            incometype: '',
            description: '',
            amount: '',
            who: com.borrower,
            isEdit: false,
            editIndex: -1,
            validated: false,
            hasborrowerTab: true,
            tabs: [
                {
                    tab: who === com.borrower ? 'Primary' : 'Co-borrower',
                    value: `${firstname} ${lastname}`,
                },
            ],
        }
    }

    componentDidMount() {
        sessionStorage.setItem('progressValue', 100)
        if (this.personalCoborrower()) {
            const { firstname, lastname } = this.props.application.coborrower
            this.setState((prevState) => ({
                tabs: [
                    ...prevState.tabs,
                    {
                        tab: 'Co-borrower',
                        value: `${firstname} ${lastname}`,
                    },
                ],
            }))
        }
    }

    componentDidUpdate() {}

    componentWillUnmount() {}

    personalCoborrower = () => {
        const { hascoborrower, married } = this.props.application
        return (
            hascoborrower === 'withcoborrower' ||
            (hascoborrower === 'multiple' && married)
        )
    }

    canShowDividends = () => {
        const { occupation, income } = this.props.application[this.props.who]
        if (occupation.hasoccupation === null) return false
        if (occupation.hasoccupation !== 'employed') return true

        const {
            isbasevalid,
            iscomissionvalid,
            isovertimevalid,
            isbonusesvalid,
        } = this.state

        if (isbasevalid && income.hasbonuses === false) return true

        if (isbasevalid && income.hasbonuses === true && iscomissionvalid && isovertimevalid && isbonusesvalid)
            return true

        return false
    }

    canShowOtherIncome = () => {
        if (!this.canShowDividends()) return false

        const { hasdividends } = this.props.application[this.props.who].income
        if (hasdividends === null) return false
        if (hasdividends === false) return true

        if (hasdividends === true && this.state.isdividendsvalid) return true

        return false
    }

    addOtherSources = (e) => {
        com.touch()

        const { who } = this.props
        if (e !== 'true') {
            this.props.clearBorrowerAdditionalIncome(who)
        } else {
            setTimeout(() => {
                const { amount, description, incometype } = this.props.application[who].income.otherincome[0]
                this.setState({
                    isEdit: true,
                    validated: false,
                    editIndex: 0,
                    amount,
                    description,
                    incometype,
                    showEmploymentModal: true,
                })
            }, 200)

            this.props.addBorrowerAdditionalIncome(0, who)
        }
    }

    processOtherIncome = (val, index) => {
        if (null === val) return ''

        const { description, monthlyamount, key } = val
        return this.extraIncome(index, description, monthlyamount, key)
    }

    addMoreSources = () => {
        com.touch()
        this.props.addBorrowerAdditionalIncome(0, this.props.who)
    }

    onFinish = () => {
        const { who } = this.props
        const { completedsteps } = this.props.application[who] || {}

        if (!completedsteps?.otherincome) {
            this.props.changeBorrowerInfo(
                { ...completedsteps, otherincome: true },
                who,
                'completedsteps'
            )
        }
    }

    handleSubmit = (e) => {
        return true
    }

    handlePrevious = () => {
        return true
    }

    changeTab = (tab) => {
        this.setState(
            { tabAvtive: tab, who: tab === 0 ? com.borrower : com.coborrower },
            () => { eventBus.emit('changeProgressValue') }
        )
    }

    render() {
        const formatAmount = (value) => {
            return value ? Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, }) : '--'
        }

        const getEmploymentList = () => {
            const { otherincome } = this.props.application[this.props.who].income
            const otherIncome = otherincome ? otherincome : []
            return otherIncome.map((obj) => [
                {
                    // label: 'Employer',
                    label: 'Amount',
                    value: formatAmount(obj.amount) + '(monthly)',
                },
                {
                    label: 'Income type',
                    value: obj.description ? obj.description : '--',
                },
                // {
                //     label: 'Additional info',
                //     value: obj.description ? obj.description : '--',
                //     span: 12
                // }
            ])
        }

        const editEmployment = (index) => {
            const { amount, description, incometype } = this.props.application[this.props.who].income.otherincome[index]

            this.setState({
                isEdit: true,
                validated: false,
                editIndex: index,
                amount,
                description,
                incometype,
                showEmploymentModal: true,
            })
        }

        const deleteEmployment = (index) => {
            this.props.removeBorrowerAdditionalIncome(index, this.props.who)
        }

        const addEmployment = () => {
            this.setState({
                isEdit: false,
                validated: false,
                amount: '',
                description: '',
                incometype: '',
                showEmploymentModal: true,
            })
        }

        const onSelectAdditionalIncome = (value) => {
            com.touch()

            let description = ''
            com.othersouresofincome.forEach((x) => {
                if (x[0] === value) {
                    description = x[1]
                }
            })
            if (value === 'Other') description = ''
            this.setState({ incometype: value, description: description })
        }

        const employmentModal = () => {
            const creditCheckContinue = () => {
                const { who } = this.props

                let index = 0
                if (this.state.isEdit) {
                    index = this.state.editIndex
                } else {
                    this.addMoreSources()
                    const { otherincome } = this.props.application[who].income
                    index = otherincome.length
                }

                this.props.updateBorrowerAdditionalIncome(
                    { target: { value: this.state.amount } },
                    who,
                    'amount',
                    index
                )
                this.props.updateBorrowerAdditionalIncome(
                    { target: { value: this.state.incometype } },
                    who,
                    'incometype',
                    index
                )
                this.props.updateBorrowerAdditionalIncome(
                    { target: { value: this.state.description } },
                    who,
                    'description',
                    index
                )
                this.setState({ showEmploymentModal: false })
            }

            const submitIncome = (e) => {
                e.preventDefault()
                e.stopPropagation()
                if (this.state.amount === '') {
                    this.setState({ validated: true })
                    return false
                }
                creditCheckContinue()
            }

            return (
                <Modal
                    className="employment-modal"
                    size="lg"
                    show={this.state.showEmploymentModal}
                    onHide={() => this.setState({ showEmploymentModal: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add employment</Modal.Title>
                    </Modal.Header>
                    <Form ref={this.form} noValidate onSubmit={submitIncome} validated={this.state.validated}>
                        <Modal.Body style={{ padding: '0 40px 14px', maxHeight: '80vh', minHeight: '500px', overflow: 'auto' }}>
                            <div style={{ fontSize: '14px', lineHeight: '20px', color: '#6E6E70', margin: '14px 0 0px 0' }}>
                                * Indicates required
                            </div>
                            <Form.Row>
                                <Col xs="12" sm="6" style={{ width: '100%' }} className="mt-4">
                                    <Form.Group controlId="overtime" className="text-left">
                                        <Form.Label className="text-left text-nowrap employment-label">
                                            Amount*
                                        </Form.Label>
                                        <div style={{ position: 'relative' }}>
                                            <div>
                                                <DollarInput
                                                    suffix="Monthly"
                                                    onChange={(e) => {
                                                        this.setState({ amount: e < 0 ? 0 : e })
                                                    }}
                                                    value={this.state.amount}
                                                />
                                            </div>
                                        </div>
                                        {this.state.validated ? (
                                            <div style={{ color: 'red' }}>
                                                Please provide income.
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="6" className="mt-4">
                                    <Form.Group
                                        controlId="description"
                                        className="text-left"
                                    >
                                        <Form.Label className="text-left text-nowrap employment-label">
                                            What's the type of this income?
                                        </Form.Label>
                                        <div>
                                            <Select
                                                className="w-100"
                                                style={{ height: 44, width: '100%' }}
                                                value={this.state.incometype}
                                                onChange={onSelectAdditionalIncome}
                                            >
                                                {com.othersouresofincome.map(
                                                    (st, index) => (
                                                        <Select.Option value={st[0]} key={index}>
                                                            {st[1]}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        </div>
                                        <InputGroup></InputGroup>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="zeitro-primary" className="income-save-btn">
                                Save
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )
        }

        const handleMilitaryService = (e) => {
            com.touch()
            this.props.changeDeclaration(e, this.props.who, 'servearmedforces')
        }

        const handleHasDividends = (e) => {
            com.touch()
            const value = e === 'true' ? true : false
            this.props.updateIncomeAtttribute(value, this.props.who, 'hasdividends')
        }

        const { who } = this.props
        const { otherincome } = this.props.application[who].income
        const { servearmedforces } = this.props.application[who].declarations
        const { hasdividends } = this.props.application[who].income

        return (
            <div className="text-left income-application">
                <div className="page-header">
                    <span className="page-header-primary">Income</span>
                    <span className="page-header-separator">/</span>
                    <span className="page-header-secondary">Other income</span>
                </div>

                {employmentModal()}

                <Form ref={this.formRef}>
                    <div className="mt-4 text-left newInterviewPaneProperty">
                        <Row>
                            <Col className="px-0" md={12} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Do you have any other sources of income?{' '}
                                        <Tips
                                            content="You don't need to provide information about alimony or child support unless you want it considered in determining your qualification for the loan."
                                            placement="top"
                                            title=""
                                        />
                                    </Form.Label>
                                    <Row>
                                        <Col className="pl-0" md={6} xs={12}>
                                            <Form.Group className="inputSection">
                                                <CheckGroup
                                                    data={[
                                                        { value: 'true', label: 'Yes' },
                                                        { value: 'false', label: 'No' },
                                                    ]}
                                                    onChangeSelect={this.addOtherSources}
                                                    value={otherincome !== null && otherincome.length !== 0 ? 'true' : 'false'}
                                                    type="radio"
                                                    direction="horizontal"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>

                        {getEmploymentList().map((employment, index) => (
                            <div className="employment-list mt-3" key={index}>
                                <div className="employment-item income-item">
                                    <div className="employment-top">
                                        <div className="employment-top-l">
                                            <div className="employment-icon">
                                                <img src="/images/employmentIcon.png" alt="employment" />
                                            </div>
                                            <span className="employment-name">Other income #{index + 1}</span>
                                        </div>
                                        <div className="employment-top-r">
                                            <div className="employment-edit" onClick={() => editEmployment(index)}>
                                                <img src="/images/employmentEdit.svg" alt="edit" />
                                            </div>
                                            <div className="employment-delete" onClick={() => deleteEmployment(index)}>
                                                <img src="/images/employmentDelete.svg" alt="delete" />
                                            </div>
                                        </div>
                                    </div>
                                    <Row className="employment-form mb-2">
                                        {employment.map(
                                            (form, formIndex) => (
                                                <Col
                                                    className="employment-form-item mb-2"
                                                    md={form.span ? form.span : 6}
                                                    key={formIndex}
                                                >
                                                    <div className="employment-form-label">{form.label}:</div>
                                                    <div className="employment-form-value">{form.value}</div>
                                                </Col>
                                            )
                                        )}
                                    </Row>
                                </div>
                            </div>
                        ))}

                        {otherincome && otherincome.length > 0 && otherincome.length < 3 && (
                            <div className="add-job" style={{ marginTop: '12px' }} onClick={addEmployment}>
                                <span>Add another source of income</span>
                            </div>
                        )}

                        <Row>
                            <Col className="mt-4 px-0" md={12} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Do you have income from dividends or interest (Form 1099-DIV or Form 1099-INT)?
                                    </Form.Label>
                                    <Row>
                                        <Col className="pl-0" md={6} xs={12}>
                                            <Form.Group className="inputSection">
                                                <CheckGroup
                                                    data={[
                                                        { value: 'true', label: 'Yes' },
                                                        { value: 'false', label: 'No' },
                                                    ]}
                                                    onChangeSelect={handleHasDividends}
                                                    value={hasdividends ? 'true' : 'false'}
                                                    type="radio"
                                                    direction="horizontal"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mt-4 px-0" md={12} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Have you or your deceased spouse ever served, or are you currently serving, in the United States Armed Forces?
                                    </Form.Label>
                                    <Row>
                                        <Col className="pl-0" md={6} xs={12}>
                                            <Form.Group className="inputSection">
                                                <CheckGroup
                                                    data={[
                                                        { value: "yes", label: "Yes" },
                                                        { value: "no", label: "No" },
                                                    ]}
                                                    onChangeSelect={handleMilitaryService}
                                                    value={servearmedforces === 'yes' ? 'yes' : 'no'}
                                                    type="radio"
                                                    direction="horizontal"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
        )
    }
}

OtherIncome = connect(mapStateToPropsDetails, mapDispatchToPropsDetails, null, {
    forwardRef: true,
})(OtherIncome)
