import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { Select, Spin } from 'antd'
import smartlookClient from 'smartlook-client'
import './PreApp.css'
import * as st from '../State'
import * as act from '../Store/actions'
import { UpdateChangeProgressType, UpdateChangeLastTouch } from '../Store/progress'
import { PrequalHeader } from '../Landing/LandingMenu.js'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { Button, Col, Container, Row, Form } from 'react-bootstrap'
import CheckGroup, { CheckGroupWithImage } from '../Common/components/CheckGroup'
import ScrollBar from '../Common/components/ScrollBar'
import EmailVerification from '../Application/EmailVerification'

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        property: state.application.property,
        borrower: state.borrower,
        hidden: false,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateApplication: (t) => {
        dispatch(act.updateApplication(t))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },
    updateChangeProgressType: (step, stage) => {
        dispatch(UpdateChangeProgressType(step, stage))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    changeReviewedLoans: (value) => {
        dispatch(act.ChangeReviewedLoans(value))
    },
    changeMainPropertyPurpose: (val) => {
        dispatch(act.ChangeMainPropertyPurpose(val))
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb))
    },
    changeMainPropertyAppraisal: (event) => {
        dispatch(act.ChangeMainPropertyAppraisal(event))
    },
    changeMainPropertySalesPrice: (event) => {
        dispatch(act.ChangeMainPropertySalesPrice(event))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    changeBorrowerInfoByValue: (val, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(val, who, verb))
    },
    clearBorrowerGift: () => {
        dispatch(act.ClearBorrowerGift())
    },
    clearBorrowerInsurance: () => {
        dispatch(act.ClearBorrowerInsurance())
    },
})

export default class PreApp extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            purpose: '',
            ownorrent: '',
            showHasContract: false,
            showHousePrice: false,
            focusedIndex: null, // Index of the currently focused component
            hasLO: '',
            loanofficers: [],
            selectedLOId: '',
            open: false,
        }

        const searchparams = new URLSearchParams(window.location.search)
        this.customerid = searchparams.get('customerid')
        this.loid = searchparams.get('loid')
        this.fromMarketplace = searchparams.get('marketplace') === 'true' ? true : false
        this.componentRefs = [] // Array to store the refs of each component

        fetch('/auth/getlos', {
            method: 'POST',
            body: JSON.stringify({
                CustomerID: this.customerid,
            }),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.warn('/auth/getlos:Looks like there was a problem. Status Code: ' + response.status)
                    return
                }

                // Examine the text in the response
                response.json().then((js) => {
                    if (js.Status !== 'OK') {
                    } else {
                        const los = js.LoanOfficers.map(
                            ({ FirstName, LastName, Email, ID }) => ({
                                ID,
                                FirstName,
                                LastName,
                                Email,
                            })
                        )
                        this.setState({ loanofficers: los })
                    }
                })
            })
            .catch((err) => {
                console.error('Fetch Error :', err)
            })
    }

    componentDidMount() {
        // Add message event listener
        window.addEventListener('message', this.handleMessage)
        
        window.document.addEventListener('checkwalkback', this.onTouch)
        smartlookClient.init('d837cbb6bdd09c2c425261ab6ab338c05aac8ef0')
        smartlookClient.record({
            emails: true,
            forms: true,
            numbers: true,
            ips: true,
        })
        this.setFocus(0)
        if (this.props.property.salesprice === '') {
            this.props.changeMainPropertySalesPrice(500000)
            this.props.changeMainPropertyAppraisal(500000)
        }

        const hasLoid = sessionStorage.getItem('loid')
        const hasLO = sessionStorage.getItem('hasLO')
        this.setState({
            hasLO,
            selectedLOId: hasLoid ? hasLoid : '',
        })
    }

    componentWillUnmount() {
        // Remove message event listener
        window.removeEventListener('message', this.handleMessage)
        
        window.document.removeEventListener('checkwalkback', this.onTouch)
    }

    // Add message handler
    handleMessage = (event) => {
        // Verify the origin for security
        // Replace 'http://localhost:3001' with your marketplace domain
        // if (event.origin !== 'http://localhost:3001') {
        //     return
        // }

        // Handle the message
        if (event.data.type === 'SET_SESSION_STORAGE') {
            try {
                console.log('SET_SESSION_STORAGE', event.data.key, event.data.value)
                
                const s = JSON.parse(event.data.value)

                this.props.updateApplication(s.borrowerState.application)
                sessionStorage.setItem("borrowerExists", s.borrowerExists)

                // Optionally send confirmation back to parent window
                event.source.postMessage({
                    type: 'STORAGE_SET_CONFIRMATION',
                    key: event.data.key
                }, event.origin)
            } catch (error) {
                console.error('Error setting session storage:', error)
                // Optionally send error back to parent window
                event.source.postMessage({
                    type: 'STORAGE_SET_ERROR',
                    key: event.data.key,
                    error: error.message
                }, event.origin)
            }
        }
    }

    setFocus(index) {
        // Update the focusedIndex state and scroll to the component
        this.setState({ index }, () => {
            // const { top, height } = this.componentRefs[index].getBoundingClientRect();
            const { innerWidth } = window
            // const scrollPosition = innerHeight / 2;

            // const centerY = top + height / 2;
            // const scrollY = window.innerHeight / 3 +( index - 1) * 400;
            let scrollY = 0
            if (innerWidth < 450) {
                switch (index) {
                    case 0:
                        scrollY = 0
                        break
                    case 1:
                        scrollY = 400
                        break
                    case 2:
                        scrollY = 800
                        break
                    case 3:
                        scrollY = 1000
                        break
                    default:
                        break
                }
            } else {
                switch (index) {
                    case 0:
                        scrollY = 0
                        break
                    case 1:
                        scrollY = 200
                        break
                    case 2:
                        scrollY = 600
                        break
                    case 3:
                        scrollY = 1000
                        break
                    default:
                        break
                }
            }
            window.scrollTo({
                top: scrollY,
                behavior: 'smooth',
            })
        })
    }

    onTouch = () => {
        const d = new Date()
        this.props.updateChangeLastTouch(d.toUTCString())
    }

    render = () => {
        let nextPage =
            '/services/preapp/emailverification?customerid=' +
            this.customerid +
            '&loid=' +
            this.state.selectedLOId

        if (sessionStorage.getItem('ZeitroA') !== null) {
            nextPage = '/application/interview/main/applicant'
        }

        const companyName = sessionStorage.getItem('companyName')
        const helmet = companyName ? companyName : 'Mortgage Service'

        const showSelectLO = () => {
            if (this.loid !== null) {
                return false
            }
            return this.state.purpose !== ''
        }

        const showPrice = () => {
            if (this.customerid === 'individualcustomers' || this.loid !== null) {
                return this.state.purpose !== ''
            } else {
                const { hasLO, selectedLOId } = this.state
                return (hasLO === 'no' || (hasLO === 'yes' && selectedLOId !== ''))
            }
        }

        const changePurpose = (e) => {
            this.onTouch()
            this.props.updateSelectField(new st.ChosenLoan(), 'selectedloan')
            this.props.changeReviewedLoans(0)
            if (e === 'purchase') {
                this.props.changeMainPropertyPurpose('purchase')
                this.props.changeMainPropertyAttribute(true, 'hascontract')
                // if change from purpose, clear gifts/insurance
                this.props.clearBorrowerGift()
                this.props.clearBorrowerInsurance()
            } else if (e === 'refinance') {
                this.props.changeMainPropertyPurpose('refinance')
                this.props.changeMainPropertyAttribute(false, 'hascontract')
            } else if (e === 'cashout') {
                this.props.changeMainPropertyPurpose(st.POL_Cashoutrefinance)
                this.props.changeMainPropertyAttribute(false, 'hascontract')
            }
            this.setState({ purpose: e })
            this.setFocus(1)
        }

        const onAppraisal = (e) => {
            this.props.changeMainPropertyAppraisal(e)
            this.props.changeMainPropertySalesPrice(e)
        }

        const getClsName = () => {
            if (this.state.purpose === '') {
                return ''
            }
            return 'h-1000'
        }

        const { appraisal } = this.props.property
        const { purpose } = this.props.application.property

        return (
            <div className={getClsName()}>
                <Helmet>
                    <title>{helmet + ' - What brings you here today?'}</title>
                </Helmet>

                <PrequalHeader app={true} setid={true} />

                <Container>
                    <div
                        key={0}
                        ref={(ref) => (this.componentRefs[0] = ref)}
                        className="preappSection1 "
                    >
                        <Row>
                            <Col className="preappTitle mb-3">
                                What brings you here today?
                            </Col>
                        </Row>
                        <Row>
                            <Col className="preappLabel">I would like to</Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <CheckGroupWithImage
                                    className="preappCheckgroupWithImage"
                                    data={[
                                        {
                                            value: 'purchase',
                                            label: 'Buy a property',
                                            image: '/images/buyproperty.svg',
                                        },
                                        {
                                            value: 'refinance',
                                            label: 'Refinance my home',
                                            image: '/images/refinance.svg',
                                        },
                                        {
                                            value: 'cashout',
                                            label: 'Get cash-out',
                                            image: '/images/cashout.svg',
                                        },
                                    ]}
                                    onChangeSelect={changePurpose}
                                    value={this.state.purpose}
                                    type="radio"
                                    hideCircle={true}
                                    direction="horizontal"
                                    style={{ width: '100%' }}
                                />
                            </Col>
                        </Row>
                    </div>

                    {showSelectLO() && (
                        <div key={1} ref={(ref) => (this.componentRefs[2] = ref)}>
                            <Row className="">
                                <Col md={6} xs={12}>
                                    <div style={{ marginTop: 30 }} className="preappLabel">
                                        Do you currently have a loan officer?
                                    </div>
                                    <div className="">
                                        <CheckGroup
                                            data={[
                                                {
                                                    value: 'yes',
                                                    label: 'Yes',
                                                },
                                                {
                                                    value: 'no',
                                                    label: 'No',
                                                },
                                            ]}
                                            onChangeSelect={(e) => {
                                                this.setState({ hasLO: e })
                                                sessionStorage.setItem('hasLO', e)
                                                if (e === 'no') {
                                                    sessionStorage.removeItem('loid')
                                                    this.setState({ selectedLOId: '' })
                                                }
                                            }}
                                            value={this.state.hasLO}
                                            type="radio"
                                            direction="horizontal"
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {this.state.hasLO === 'yes' && (
                                <Row>
                                    <Col md={6} xs={12}>
                                        <div style={{ marginTop: 30 }} className="preappLabel">
                                            Please select your loan officer
                                        </div>
                                        <div className="">
                                            <Select
                                                className="w-100"
                                                size="large"
                                                value={this.state.selectedLOId}
                                                onChange={(v) => {
                                                    this.setState({ selectedLOId: v })
                                                    sessionStorage.setItem('loid', v)
                                                }}
                                            >
                                                {this.state.loanofficers
                                                    .filter((lo) => !lo.Email.includes( 'noreply'))
                                                    .map((lo) => (
                                                        <option value={lo.ID}>
                                                            {`${lo.FirstName} ${lo.LastName} (${lo.Email})`}
                                                        </option>
                                                    ))}
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    )}

                    {showPrice() && (
                        <div key={2} ref={(ref) => (this.componentRefs[3] = ref)}>
                            <Row className="">
                                <Col md={6} xs={12}>
                                    <div style={{ marginTop: 60 }} className="preappLabel">
                                        What’s your estimated home value?
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <ScrollBar
                                            max={5000000}
                                            min={100000}
                                            step={10000}
                                            initVal={appraisal === '' ? 500000 : parseInt(appraisal)}
                                            scrollChangeValue={onAppraisal}
                                            hideSlider={true}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} xs={12} className="hideOnMobile">
                                    <img src="/images/preappHouse.png" alt="preappHouse" style={{ height: 180 }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={12} className="mt-5">
                                    <Link to={nextPage}>
                                        <Button variant="zeitro-primary" disabled={purpose === null ? true : false}>
                                            Next Step
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Container>
            </div>
        )
    }
}

PreApp = withRouter(connect(mapStateToProps, mapDispatchToProps)(PreApp))

export class PreAppEmailVerification extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            showVerification: false,
            validated: false,
        }

        const searchparams = new URLSearchParams(window.location.search)

        this.customerid = searchparams.get('customerid')
        this.loid = searchparams.get('loid')
        this.emailref = React.createRef()
    }

    onTouch = () => {
        const d = new Date()
        this.props.updateChangeLastTouch(d.toUTCString())
    }

    render = () => {
        const companyName = sessionStorage.getItem('companyName')
        const helmet = companyName ? companyName : 'Mortgage Service'

        const changeBorrowerInfo = (who, verb) => {
            return (event) => {
                this.onTouch()
                this.props.changeBorrowerInfo(event, who, verb)
            }
        }

        const handleSubmit = (event) => {
            event.preventDefault()
            const form = event.currentTarget
            // console.log(form.checkValidity())
            if (form.checkValidity() === false) {
                event.stopPropagation()
                this.setState({ validated: true })
                return
            }
            this.setState({ showVerification: true })
            this.setState({ validated: true })
        }

        const back = (type) => {
            if (type === 1) {
                this.setState({ showVerification: false })
            }
        }

        const { firstname, lastname, email } = this.props.application.borrower

        return (
            <Spin spinning={this.state.loading}>
                <div className="preapp">
                    <Helmet>
                        <title>{helmet + ' - Email Verification'}</title>
                    </Helmet>

                    <PrequalHeader app={true} setid={true} />

                    <Container className="mt-5">
                        {!this.state.showVerification ? (
                            <Row className="text-center">
                                <Col className="preappSection1" style={{ marginTop: 0 }}>
                                    <Row
                                        className="preappTitleRow"
                                        style={{ display: 'flex', alignItems: 'center', marginBottom: 50 }}
                                    >
                                        <Col className="saveProgressTitle">
                                            Save your progress
                                        </Col>
                                    </Row>
                                    <Form noValidate validated={this.state.validated} onSubmit={handleSubmit}>
                                        <Form.Group style={{ marginBottom: 26 }}>
                                            <Form.Label
                                                style={{ color: '#62707C', fontWeight: 500 }}
                                                className="col-md-6 col-xs-12 mx-auto text-left pl-0"
                                            >
                                                First name
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                autoComplete="given-name"
                                                className="col-md-6 col-xs-12 mx-auto"
                                                placeholder="Enter your first name"
                                                value={firstname}
                                                onChange={changeBorrowerInfo('borrower', 'firstname')}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter your first name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group style={{ marginBottom: 26 }}>
                                            <Form.Label
                                                style={{ color: '#62707C', fontWeight: 500 }}
                                                className="col-md-6 col-xs-12 mx-auto text-left pl-0 mt-4"
                                            >
                                                Last name
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                autoComplete="family-name"
                                                className="col-md-6 col-xs-12 mx-auto"
                                                placeholder="Enter your last name"
                                                value={lastname}
                                                onChange={changeBorrowerInfo('borrower', 'lastname')}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter your last name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group style={{ marginBottom: 26 }}>
                                            <Form.Label
                                                style={{ color: '#62707C', fontWeight: 500 }}
                                                className="col-md-6 col-xs-12 mx-auto text-left pl-0 mt-4"
                                            >
                                                Email
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="email"
                                                autoComplete="email"
                                                // pattern={com.emailReges}
                                                className="col-md-6 col-xs-12 mx-auto"
                                                placeholder="Enter your email address"
                                                value={email}
                                                onChange={changeBorrowerInfo('borrower', 'email')}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a valid email address.
                                            </Form.Control.Feedback>
                                            <div
                                                style={{ fontSize: 14, color: '#6E6E70', marginTop: 6 }}
                                                className="col-md-6 col-xs-12 mx-auto text-left pl-0"
                                            >
                                                *You will be sent a verification code
                                            </div>
                                        </Form.Group>
                                        <div className="col-md-6 col-xs-12 mx-auto pl-0 pr-0 mt-4">
                                            <Button
                                                disabled={email === ''}
                                                className="saveProgressBtn"
                                                variant="zeitro-primary"
                                                type="submit"
                                            >
                                                Continue
                                            </Button>
                                        </div>
                                        <div className="saveProgressBottom">
                                            Already have an account?
                                            <span
                                                onClick={() =>
                                                    (window.location.href = '/services/signin?customerid=' + this.customerid)
                                                }
                                            >
                                                Sign in
                                            </span>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        ) : (
                            <>
                                <EmailVerification
                                    back={(type) => back(type)}
                                    fromMarketplace={this.fromMarketplace}
                                />
                            </>
                        )}
                    </Container>
                </div>
            </Spin>
        )
    }
}

PreAppEmailVerification = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PreAppEmailVerification)
)
