import React, { Component } from 'react'
import { connect } from 'react-redux'
import { InputNumber, Modal } from 'antd'
import 'bootstrap/dist/css/bootstrap.min.css'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import './ApplicationInterview.css'
import './SSN.css'
import * as act from '../Store/actions'
import * as com from '../Common'
import { getCurrentState } from '../Store'
import { UpdateChangeProgress, UpdateChangeLastTouch } from '../Store/progress'
import CheckGroup from '../Common/components/CheckGroup'
import { isMortgageLoan } from '../Tools/Loan'
import { assembleFullName } from '../Tools/String'

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    copyBorrowerLoan: (p, who) => {
        dispatch(act.CopyBorrowerLoan(p, who))
    },
    changeBorrowerLoan: (payload, index, verb) => {
        dispatch(act.ChangeBorrowerLoan(payload, index, verb))
    },
    addBorrowerAdditionalPropertiesFromCredit: (payload) => {
        dispatch(act.AddBorrowerAdditionalPropertiesFromCredit(payload))
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },
    changeReviewedLoans: (value) => {
        dispatch(act.ChangeReviewedLoans(value))
    },
    updateFico: (score) => {
        dispatch(act.UpdateFico(score))
    },
    clearBorrowerLoans: (event, who) => {
        dispatch(act.ClearBorrowerLoans(event, who))
    },
    updateCoborrower: (event) => {
        dispatch(act.UpdateHasCoborrower(event.target.value))
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event, who, verb))
    },
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },
    updateCRErrors: (ar) => {
        dispatch(act.UpdateCRErrors(ar))
    },
    clearCRErrors: () => {
        dispatch(act.ClearCRErrors())
    },
    updateApplicationAttribute: (t, verb) => {
        dispatch(act.UpdateApplicationAttribute(t, verb))
    },
})

class SSN extends Component {
    constructor(props) {
        super(props)

        const { loans } = this.props.application.assetsandliabilities
        const { borrower, coborrower, hascoborrower, scre, hascreditfreeze } = this.props.application

        this.state = {
            automatedCreditPull: false,
            pullCredit: (() => {
                // If loans is null, return empty string
                if (loans === null) return ''

                // If there's only one estimated loan, return false
                if (loans.length === 1 && loans[0].name === 'Estimated') {
                    return 'false'
                }

                // Otherwise return true if loans exist
                return 'true'
            })(),
            borrowerSSN: borrower.socialsecurity,
            originalBorrowerSSN: borrower.socialsecurity,
            coborrowerSSN: coborrower.socialsecurity,
            originalCoborrowerSSN: coborrower.socialsecurity,
            hasCoborrower: hascoborrower === 'withcoborrower',
            authorized: Boolean(
                scre !== 0 &&
                loans !== null &&
                !(loans.length === 1 && loans[0].name === 'Estimated')
            ),
            expand: [],
            stepsandsubsteps: [],
            invalidPrimaryAddress: false,
            showContactModal: false,
            useEstimated: false,
            estimatedLoan: {
                openeddate: '01/01/2023',
                name: 'Estimated',
                address: '',
                accountnumber: '',
                monthlypayment: '',
                monthlydefinedbycustomer: true,
                remainingbalance: '',
                highbalance: '',
                monthslefttopay: '',
                mannerofpaymentcode: 'C',
                currentratingtype: 'AsAgreed',
                creditloantype: 'ConventionalRealEstateMortgage',
                creditliabilityaccounttype: 'Mortgage',
                creditliabilityaccountownershiptype: 'Individual',
                creditliabilityaccountstatustype: 'Open',
                detailcreditbusinesstype: 'FinanceMortgageCompanies',
                creditliabilityhighestadverserating: 'NoDataAvailable',
                reportedby: ['Equifax', 'Experian', 'TransUnion'],
                deferredpaymentamount: '',
                deferredpaymentdate: '',
                comment: 'CONVENTIONAL REAL ESTATE MORTGAGE',
                rate: '',
                correction: 'none',
                explanation: '',
                attribution: 2,
                propertyaddress: '',
                key: '0',
            },
            validated: false,
            errorMessages: [],
            pwdType: 'text',
            coPwdType: 'text',
            showCreditModal: false,
            confirm: false,
            showCheckMsg: false,
            showStatusInfo: false,
            showCheckCredit: true,
            showFico: false,
            changeTips: false,
            scre: '',
            debt: '',
        }

        // TBD
        if (hascreditfreeze === null) {
            this.props.updateApplicationAttribute(false, 'hascreditfreeze')
        }

        this.originalBorrowerSSN = this.state.borrowerSSN.slice()
        this.originalCoborrowerSSN = this.state.coborrowerSSN.slice()
    }

    componentDidMount() {
        this.getCurProgress()
        this.initStatus()

        const warnings = this.props.application.assetsandliabilities.warnings
        if (warnings !== null) {
            this.setState({
                errorMessages: warnings,
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.pullCredit === 'true' && this.state.showCheckCredit) {
            this.props.changeContinueButtonText('Check Credit')
        } else {
            this.props.changeContinueButtonText('Next')
        }
        this.getCurProgress()
    }

    initStatus = () => {
        const { scre } = this.props.application
        setTimeout(() => {
            this.checkAutomatedCreditPull()
            this.setState({
                scre: scre === 0 ? '' : scre,
                debt: this.getEstimatedMonthlyPay(),
            })

            if (this.checkStatus() === 'success') {
                this.setState({
                    showStatusInfo: true,
                    showCheckCredit: false,
                    showFico: false,
                })
            }
            if (this.checkStatus() === 'fail') {
                this.setState({
                    showStatusInfo: true,
                    showCheckCredit: false,
                    showFico: true,
                })
            }
        }, 0)
    }

    getCurProgress = () => {
        const obj = {
            borrowerSSN: this.state.borrowerSSN,
        }
        if (this.state.hasCoborrower) {
            obj.coborrowerSSN = this.state.coborrowerSSN
        }
        const emptyNum = Object.keys(obj).filter((key) => {
            const value = obj[key]
            return value === '' || value === null || value === undefined
        }).length
        const progressValue = Math.round(
            ((Object.keys(obj).length - emptyNum) / Object.keys(obj).length) *
                100
        )
        sessionStorage.setItem('progressValue', progressValue)
    }

    getEstimatedMonthlyPay = () => {
        const loans = this.props.application.assetsandliabilities.loans
        if (
            loans !== null &&
            loans.length === 1 &&
            loans[0].monthlydefinedbycustomer === true
        ) {
            return loans[0].monthlypayment && loans[0].monthlypayment !== ''
                ? loans[0].monthlypayment
                : 0
        }

        return 0
    }

    checkAutomatedCreditPull = () => {
        const token = sessionStorage.getItem('ZeitroA')
        fetch('/borrower/checkautomatedcreditpull', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                Cache: 'no-cache',
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.log(response)
                    return
                }
                response.json().then((js) => {
                    this.setState({
                        automatedCreditPull: js.UseAutomatedCreditPull,
                    })

                    if (!js.UseAutomatedCreditPull)
                        this.setState({ pullCredit: 'false' })
                })
                return
            })
            .catch((err) => {
                console.log('Fetch Error :', err)
            })
    }

    checkPulled = () => {
        const loans = this.props.application.assetsandliabilities.loans
        if (
            this.props.application.scre !== 0 &&
            loans !== null &&
            !(loans.length === 1 && loans[0].name === 'Estimated')
        ) {
            return true
        }
        return false
    }

    checkStatus = () => {
        if (this.checkPulled()) {
            return 'success'
        } else {
            if (this.props.application.assetsandliabilities.warnings !== null) {
                return 'fail'
            } else {
                return 'none'
            }
        }
    }

    reconcileSSN = (js, borrowers) => {}

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }
        return true
    }

    fillPerson = (who) => {
        const a = this.props.application[who]
        return {
            firstname: a.firstname,
            middlename: a.middlename,
            lastname: a.lastname,
            suffix: a.suffix,
            email: a.email,
            socialsecurity: a.socialsecurity,
            dateofbirth: a.dateofbirth,
        }
    }

    fillAddress = (who) => {
        const a = this.props.application[who]
        return {
            street: a.presentaddressstreet,
            city: a.presentaddresscity,
            state: a.presentaddressstate,
            zip: a.presentaddresszip,
            years: a.presentaddressyears,
            months: a.presentaddressmonths,
            ownership: a.presentaddressownership,
        }
    }

    fillPreviouswAddress = (index, who) => {
        const a = this.props.application[who].previousaddresses[index]
        return {
            street: a.street,
            city: a.city,
            state: a.state,
            zip: a.zip,
            years: a.years,
            months: a.months,
            ownership: a.ownership,
        }
    }

    fillApplicant = (who) => {
        let addresses = [this.fillAddress(who)]
        const a = this.props.application[who].previousaddresses

        for (let i = 0; i < a.length; i++) {
            addresses.push(this.fillPreviouswAddress(i, who))
        }
        if (addresses.length > 0 && addresses[0].street === '') {
            addresses = [this.fillAddress(who)]
        }
        return { person: this.fillPerson(who), addresses: addresses }
    }

    fillRequestBody = (who) => {
        const { hascoborrower, aloneMarriedWantsFHA } = this.props.application
        const borrowers = [this.fillApplicant(who)]

        if (
            hascoborrower === 'withcoborrower' ||
            hascoborrower === 'multiple' ||
            (hascoborrower === 'alone' && aloneMarriedWantsFHA)
        ) {
            borrowers.push(this.fillApplicant('coborrower'))
        }

        return {
            // true for LO, false for borrower
            force_ucs: false,
            debug: 'just_request',
            app_data: borrowers,
            request: 'credit_report',
        }
    }

    getCreditReport() {
        this.props.changeLoading(true)
        this.props.changeReviewedLoans(0)

        if (
            false &&
            null !== this.props.application.assetsandliabilities.loans
        ) {
            return
        }

        const token = window.sessionStorage.getItem('ZeitroA')
        let id = com.getUserId()

        if ('undefined' === typeof id) {
            id = this.props.borrowerid
        }

        const clearBorrowerCreditRecords = () => {
            this.props.clearCRErrors()
            this.props.updateFico('')
            this.props.clearBorrowerLoans(null, this.props.who)
            const borrowerSSN = this.state.borrowerSSN
            const coBorrowerSSN = this.state.coborrowerSSN
            this.setState({ originalBorrowerSSN: borrowerSSN, originalCoborrowerSSN: coBorrowerSSN })
            this.props.changeContinueButtonText('Next step')
        }

        const processStatusNotOK = () => {
            clearBorrowerCreditRecords()
            const errorMessages = ['The credit report is currently unavailable due to an internal error.']
            this.props.updateCRErrors(errorMessages)
            this.setState({ errorMessages: errorMessages })
            this.props.changeLoading(false)
            this.initStatus()
        }

        const processData = (js) => {
            clearBorrowerCreditRecords()

            if (
                !(
                    typeof js.errorMessages === 'undefined' ||
                    js.errorMessages === null
                )
            ) {
                console.log(js.errorMessages)
                this.props.updateCRErrors(js.errorMessages)
                this.setState({ errorMessages: js.errorMessages })
                this.props.changeLoading(false)
                if (
                    js.errorMessages.includes(
                        'Invalid or missing Primary Applicant residential address'
                    )
                ) {
                    this.setState({ invalidPrimaryAddress: true })
                }
                this.initStatus()
                return
            }

            this.props.clearBorrowerLoans(null, this.props.who)
            this.props.updateFico(js.creditscore)

            if (js.creditscore === '') {
                this.initStatus()
                return
            }

            // this.reconcileSSN(js, borrowers)

            const remap = (o) => {
                const l = { ...o }

                l.correction = 'none'
                //l.attribution = attr

                // const isstudent = l.creditliabilityaccounttype === 'Educational'
                const islease = l.creditliabilityaccounttype === 'AutoLease' || l.creditliabilityaccounttype === 'Lease'
                const isinstallment = l.creditliabilityaccounttype === 'Installment'

                if (l.creditloantype !== null && l.creditloantype.toLowerCase().indexOf('education') >= 0) {
                    if (l.monthlypayment === '') l.monthlypayment = 0
                } else {
                    if ((islease || isinstallment) && (l.monthlypayment === 0 || l.monthlypayment === '')) {
                        l.monthlydefinedbycustomer = true
                    }
                }

                // TBD: better ways to match addresses!
                if (
                    l.address !== null &&
                    l.address.split(',')[0].toLowerCase() ===
                        this.props.application.borrower.presentaddressstreet.toLowerCase() &&
                    l.creditliabilityaccountstatustype === 'Open' &&
                    isMortgageLoan(l)
                ) {
                    l.forprimaryresidence = true
                }
                return l
            }

            const opened = js.liabilities
            let dupresolved = null
            const expand = []
            let steps = -1
            let substeps = 0
            const stepsandsubsteps = []
            for (let i = 0; i < opened.length; i++) {
                const remapped = remap(opened[i])
                if (
                    opened[i].remainingbalance === '' ||
                    parseInt(opened[i].remainingbalance) === 0
                )
                    continue
                this.props.copyBorrowerLoan(remapped, this.props.who)

                expand.push(false)
                let st
                if (remapped.correction === 'duplicate') {
                    substeps += 1
                    st = { steps, substeps, dupresolved }
                } else {
                    steps += 1
                    substeps = 0
                    st = { steps, substeps, dupresolved }
                }
                stepsandsubsteps.push(st)
            }

            this.props.addBorrowerAdditionalPropertiesFromCredit(null)
            getCurrentState()

            this.originalBorrowerSSN = this.state.borrowerSSN.slice()
            this.setState({ loadingcr: false, expand, stepsandsubsteps })
            this.props.changeLoading(false)
            this.initStatus()
        }

        // if (window.location.hostname === "localhost") {
        //     processData(mockUCS)
        //     return
        // }

        fetch(window.location.origin + '/borrower/customercreditreport', {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                InitiatedByLO: false,
                Type: 'CustomerSoftCreditReport',
                BorrowerID: id, // should switch to loan ID?
            }),
            headers: {
                Authorization: 'Bearer ' + token,
                Cache: 'no-cache',
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    processStatusNotOK()
                    return
                }

                response.text().then((text) => {
                    try {
                        const js = JSON.parse(text)
                        processData(js)
                    } catch (error) {
                        console.error(error)
                        this.initStatus()
                        this.props.changeLoading(false)
                    }
                })
            })
            .catch((error) => {
                this.initStatus()
                this.props.changeLoading(false)
            })
    }

    onFinish = () => {
        const { who } = this.props
        const { completedsteps } = this.props.application[who] || {}
        if (!completedsteps?.creditcheck) {
            this.props.changeBorrowerInfo(
                { ...completedsteps, creditcheck: true },
                who,
                'completedsteps'
            )
        }
    }

    handleSubmit = (e) => {
        const { who } = this.props
        const {
            pullCredit,
            showFico,
            borrowerSSN,
            coborrowerSSN,
            scre,
            debt,
            showCheckCredit,
        } = this.state
        const ssn = who === com.borrower ? borrowerSSN : coborrowerSSN

        if (pullCredit === 'false' || (pullCredit === 'true' && showFico)) {
            this.props.clearBorrowerLoans(null, who)
            const c = { ...this.state.estimatedLoan }
            this.props.copyBorrowerLoan(c, who)
            this.props.updateFico(scre)
            this.updateEstimatedPayment(debt, who)
        }
        if (pullCredit === 'true' && !showFico) {
            this.props.updateFico(scre)
        }
        if (ssn.length !== 11) {
            console.log('🚀 ~ file: SSN.js:594 ~ SSN ~ ssn:', ssn);
            return false
        }
        if (pullCredit === 'true' && showCheckCredit) {
            console.log('🚀 ~ file: SSN.js:598 ~ SSN ~ showCheckCredit:', showCheckCredit);
            this.creditCheckContinue()
            return false
        }
        if ((pullCredit === 'false' && scre < 500) || (showFico && scre < 500)) {
            console.log('🚀 ~ file: SSN.js:606 ~ SSN ~ scre:', scre);
            this.setState({ showCheckMsg: true })
            return false
        }

        return true
    }

    updateSSN = (who, e) => {
        com.touch()
        this.props.changePhoneInfo(e, who, 'socialsecurity')
    }

    formatSSN = (value) => {
        const ssn = value.replace(/[^\d]/g, '')
        const ssnLength = ssn.length
        if (ssnLength < 4) return ssn
        if (ssnLength < 6) {
            return `${ssn.slice(0, 3)}-${ssn.slice(3)}`
        }
        return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`
    }

    enterSSN = () => {
        const { who } = this.props
        const { borrower, coborrower } = this.props.application
        const borrowerName = (
            <span style={{ color: '#325CEB' }}>
                {`${borrower.firstname} ${borrower.lastname}`}
            </span>
        )
        const coborrowerName = (
            <span style={{ color: '#325CEB' }}>
                {`${coborrower.firstname} ${coborrower.lastname}`}
            </span>
        )
        const name = who === com.borrower ? borrowerName : coborrowerName
        const label = this.props.hasCoborrower
            ? `What's ${name}'s Social Security Number?`
            : `What's your Social Security Number?`

        const ssn = who === com.borrower ? this.state.borrowerSSN : this.state.coborrowerSSN

        return (
            <div>
                <Form.Label>{label}</Form.Label>
                <InputGroup>
                    <Form.Control
                        isValid={ssn.length === 11}
                        isInvalid={ssn !== '' && ssn.length !== 11}
                        required
                        size="md"
                        onChange={(e) => {
                            const value = this.formatSSN(e.target.value)
                            this.updateSSN(who, value)
                            this.setState({
                                [who === com.borrower ? 'borrowerSSN' : 'coborrowerSSN']: value,
                                changeTips: true,
                                showCheckCredit: true,
                                showFico: false,
                                showCheckMsg: false,
                            })
                            if (this.checkStatus() === 'success') {
                                this.setState({ showStatusInfo: false })
                            }
                        }}
                        value={ssn}
                        placeholder=""
                    />
                    <Form.Control.Feedback type="invalid">
                        SSN must be 9 digits
                    </Form.Control.Feedback>
                </InputGroup>
            </div>
        )
    }

    contactLO = () => {
        this.setState({ showContactModal: true })

        const token = sessionStorage.getItem('ZeitroA')
        fetch('/borrower/contactloaboutcreditpull', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                Cache: 'no-cache',
                'X-Borrower': this.props.borrowerid,
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.warn(response)
                    return
                }
            })
            .catch((err) => {
                console.error('Fetch Error :', err)
            })
    }

    updateEstimatedPayment = (e, who) => {
        this.props.clearBorrowerLoans([], who)
        const c = { ...this.state.estimatedLoan }
        c.monthlypayment = e
        this.setState({ estimatedLoan: c })
        this.props.copyBorrowerLoan(c, who)
    }

    creditCheckContinue = () => {
        this.getCreditReport()
    }

    checkBtnDisabled = () => {
        if (this.state.pullCredit === 'true') {
            return false
        } else if (
            this.state.pullCredit === 'false' &&
            this.props.application.scre &&
            this.props.application.scre !== 0
        ) {
            return false
        }
        return true
    }

    showCredit = () => {
        this.setState({ showCreditModal: true })
    }

    softPullIcon = () => {
        return <div className="creditCheck-recommend">Recommend</div>
    }

    handleLoanBalance = (e) => {
        this.props.updateApplicationAttribute(e.target.value, 'loanbalance')
    }

    render() {
        const getContactModal = () => {
            return (
                <Modal
                    open={this.state.showContactModal}
                    onCancel={() => this.setState({ showContactModal: false })}
                    centered
                    width='auto'
                    footer={[
                        <Button
                            type="primary"
                            onClick={() => this.setState({ showContactModal: false })}
                        >
                            OK
                        </Button>
                    ]}
                    closable={false}
                >
                    We've notified your loan officer about this issue, they will contact you soon.
                </Modal>
            )
        }

        const displayEstimatedFicoAndDebt = () => {
            const { scre, showCheckMsg, debt } = this.state

            return (
                <div className="mt-4">
                    <Form.Group className="inputSection">
                        <Form.Label>
                            <div className="creditCheck-label">
                                What is your estimated FICO score?
                            </div>
                        </Form.Label>
                        <InputNumber
                            style={{ width: '100%' }}
                            size="large"
                            value={scre}
                            min={500}
                            max={1000}
                            precision={0}
                            onChange={(e) => {
                                this.setState({ scre: e })
                            }}
                        />

                        {showCheckMsg && (
                            <div className="warning-msg">
                                Please enter your FICO score
                            </div>
                        )}
                    </Form.Group>
                    <Form.Group className="inputSection mt-4">
                        <Form.Label>
                            <div className="creditCheck-label">
                                What is your estimated monthly debt payment?
                            </div>
                        </Form.Label>
                        <InputNumber
                            style={{ width: '100%' }}
                            size="large"
                            formatter={(value) => {
                                if (value === '') {
                                    return '$ '
                                }
                                return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }}
                            parser={(value) => {
                                if (value === '$ ') {
                                    return 0
                                }
                                return value.replace(/\$\s?|(,*)/g, '')
                            }}
                            min={0}
                            max={1000000}
                            precision={0}
                            value={debt}
                            onChange={(e) => {
                                this.setState({ debt: e })
                            }}
                        />
                        {showCheckMsg && (
                            <div className="warning-msg">
                                Please enter your monthly debt payment
                            </div>
                        )}
                    </Form.Group>
                </div>
            )
        }

        const { who } = this.props
        const { max, step } = this.props.progress.interview
        const { borrower, coborrower } = this.props.application
        const {
            originalBorrowerSSN,
            originalCoborrowerSSN,
            automatedCreditPull,
            showContactModal,
            showStatusInfo,
            errorMessages,
            confirm,
            showCheckCredit,
            showFico,
            changeTips,
            pullCredit,
        } = this.state
        const name =
            who === com.borrower
                ? assembleFullName(
                      borrower.firstname,
                      borrower.middlename,
                      borrower.lastname
                  )
                : assembleFullName(
                      coborrower.firstname,
                      coborrower.middlename,
                      coborrower.lastname
                  )
        const originalSSN = who === com.borrower ? originalBorrowerSSN : originalCoborrowerSSN
        const suffix = who === com.borrower ? borrower.suffix : coborrower.suffix

        return (
            <div className="text-left mb-3 newInterviewPaneEmployment">
                {showContactModal && getContactModal()}
                {this.checkStatus() === 'fail' && showStatusInfo && (
                    <div className="report-error">
                        <img
                            src="/images/warningSignYellow.png"
                            alt="warning"
                            style={{ width: 28, height: 25, marginRight: 12 }}
                        />
                        <div style={{ whiteSpace: 'initial', width: '100%' }}>
                            <div className="report-error-title">
                                <div>
                                    Sorry, we were unable to pull your credit report.
                                </div>
                                <div onClick={() => this.contactLO()}>
                                    Contact my loan officer
                                </div>
                            </div>
                            <div style={{ fontSize: '16px', color: '#333', lineHeight: 'normal' }}>
                                Your SSN:{' '}
                                <span>{originalSSN}</span>
                            </div>
                            {errorMessages.length > 0 && (
                                <div
                                    style={{ marginBottom: 2, display: 'flex' }}
                                    className="creditCheck-fail-msg"
                                >
                                    <span style={{ marginRight: 6 }}>
                                        Potential Error Cause:
                                    </span>{' '}
                                    <div style={{ fontWeight: 500 }}>
                                        {errorMessages[0]}
                                    </div>
                                </div>
                            )}
                            <div
                                style={{ marginBottom: 6, lineHeight: 'normal' }}
                                className="creditCheck-fail-operate"
                            >
                                Continue by entering your estimated FICO score, or Re-entering your SSN to redo the credit check
                            </div>
                        </div>
                    </div>
                )}
                {this.enterSSN()}
                {!automatedCreditPull ? (
                    displayEstimatedFicoAndDebt()
                ) : (
                    <div className="credit-info-box">
                        <div className="text-wrap" style={{ color: '#62707C', lineHeight: '20px', marginBottom: '8px' }}>
                            Please cross check that the following information is correct
                        </div>
                        <div className="credit-info">
                            <div>
                                <span className="credit-info-lable">Name:</span>
                                <span className="credit-info-value">
                                    {name}
                                </span>
                            </div>
                            <div>
                                <span className="credit-info-lable">Suffix:</span>
                                <span className="credit-info-value">
                                    {suffix}
                                </span>
                            </div>
                            <div>
                                <span className="credit-info-lable">
                                    Current address:
                                </span>
                                <span className="credit-info-value text-wrap">
                                    {com.formatAddress(who === com.borrower ? borrower : coborrower)}
                                </span>
                            </div>
                        </div>
                        {this.checkStatus() === 'success' && showStatusInfo && (
                            <div className="creditCheck-success">
                                <img src="/images/congratulation2.png" alt="congratulation" />
                                <div className="creditCheck-success-title">
                                    Congratulations
                                </div>
                                <div className="creditCheck-success-subtitle">
                                    Your soft pull is successful!
                                </div>
                            </div>
                        )}
                        {max === step && this.checkStatus() === 'none' && (
                            <Form.Group controlId="confirm">
                                <Form.Check
                                    type="checkbox"
                                    className="credit-checkbox"
                                    defaultChecked={confirm}
                                    required
                                    key={Math.random()}
                                    name="confirm"
                                    onChange={(e) =>
                                        this.setState({
                                            confirm: e.target.checked,
                                        })
                                    }
                                    label="*I confirm the above information is correct."
                                />
                            </Form.Group>
                        )}
                        {showCheckCredit && !showFico && (
                            <>
                                {changeTips && this.checkStatus() !== 'none' && (
                                    <div
                                        className="text-wrap"
                                        style={{ color: '#62707C', fontWeight: 500, lineHeight: '20px', marginBottom: '26px' }}
                                    >
                                        We noticed that you have changed your SSN, Do you want to redo Soft Credit Check, or enter your estimated FICO score ? *** This may affect the relevance of your credit record in our system
                                    </div>
                                )}
                                <div className="creditCheck-select mt-4">
                                    <Form.Group className="inputSection">
                                        <Form.Label>
                                            <div className="creditCheck-label">
                                                I would like to check my credit by
                                            </div>
                                        </Form.Label>
                                        <CheckGroup
                                            data={[
                                                {
                                                    value: 'true',
                                                    label: 'Soft pull',
                                                    tag: this.softPullIcon(),
                                                },
                                                {
                                                    value: 'false',
                                                    label: 'FICO score',
                                                },
                                            ]}
                                            onChangeSelect={(e) => {
                                                if (e === 'true') {
                                                    this.setState({ scre: 0 })
                                                    this.props.changeContinueButtonText('Check Credit')
                                                }
                                                if (e === 'false') {
                                                    this.props.changeContinueButtonText('Next step')
                                                }
                                                this.setState({ pullCredit: e, showCheckMsg: false })
                                            }}
                                            value={pullCredit}
                                            type="radio"
                                            direction="horizontal"
                                        />
                                        {pullCredit === 'true' && (
                                            <div
                                                className="text-wrap"
                                                style={{ color: '#6E6E70', fontSize: 14, lineHeight: '20px' }}
                                            >
                                                *By clicking check credit, I understand{' '} {com.getCompanyName()} has the permission to use your SSN do a soft pull.
                                            </div>
                                        )}
                                    </Form.Group>
                                    {this.state.pullCredit === 'false' &&
                                        displayEstimatedFicoAndDebt()}
                                </div>
                            </>
                        )}
                        {!showCheckCredit && showFico && displayEstimatedFicoAndDebt()}
                    </div>
                )}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(SSN)
