import React from "react";

const EligibilityForeignNational2ForeignIncome = () => {
  return (
    <div style={{ height: "50vh" }}>
      <div
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "left",
          padding: "0 0 1em 1em",
        }}
      >
        Eligibility
      </div>
      <ul>
        <li>Eligible for foreign nationals with verifiable foreign income.</li>
      </ul>
    </div>
  );
};

export default EligibilityForeignNational2ForeignIncome;
