enum Lender {
  AmWest = "AmWest",
  NewWave = "New Wave",
  Greenbox = "Greenbox",
  TheLender = "The Lender",
  AAA = "AAA",
  NewOmniBank = "New Omni Bank",
  MoneyHouse = "Money House",
  Plaza = "Plaza",
}

export enum ProgramID {
  ConformingLoan = "ConformingLoan",
  HighBalanceLoan = "HighBalanceLoan",
  JumboLoan = "JumboLoan",
  VOENoBankStatement = "VOENoBankStatement",
  BankStatementCalculate12MonthIncome = "BankStatementCalculate12MonthIncome",
  PnLNoBankStatement = "PnLNoBankStatement",
  DSCRNoRatio = "DSCRNoRatio",
  DSCREasy = "DSCREasy",
  ForeignNational1NoIncome = "ForeignNational1NoIncome",
  ForeignNational2ForeignIncome = "ForeignNational2ForeignIncome",
  ReverseMortgage = "ReverseMortgage",
  RenovationLoan = "RenovationLoan",
}

interface IProgram {
  id: ProgramID;
  name: string;
  // Description for the program card
  description: string;
  // Description for the questionnaire
  questionnaireDescription?: string;
  // Highlights for the program card, displayed in a list
  highlights: string[];
  // Lender for the program, might not be applicable for all programs
  lender?: string;
  // Flyer file name for the program, might not be applicable for all programs
  flyerFileName?: string;
}

/**
 * List of all programs, each program is an object with program details and program ID
 */
export const programs: IProgram[] = [
  {
    id: ProgramID.ConformingLoan,
    name: "Conforming Loan",
    questionnaireDescription: "Loan amount up to $802,650",
    description: "Loan amount up to $802,650",
    highlights: ["Loan amount up to $802,650"],
  },
  {
    id: ProgramID.HighBalanceLoan,
    name: "High Balance Loan",
    questionnaireDescription: "Loan amount up to $1,209,750",
    description: "Loan amount up to $1,209,750",
    highlights: ["Loan amount up to $1,209,750"],
  },
  {
    id: ProgramID.JumboLoan,
    name: "Jumbo Loan",
    questionnaireDescription: "Loan amount over $1,209,750",
    description: "Loan amount over $1,209,750",
    highlights: ["Loan amount over $1,209,750"],
  },
  {
    id: ProgramID.VOENoBankStatement,
    name: "VOE: No Bank Statement",
    questionnaireDescription:
      "No 4506-C or tax returns required, no bank statement required",
    description:
      "No 4506-C or tax returns required, no bank statement required",
    highlights: [
      "Self-employed and wage earners use alternative doc types",
      "No 4506-C or tax returns required, no bank statement required",
      "Max DTI 49%",
      "First Time Home Buyer Restrictions: Primary residence only, Max DTI 45%",
      "Gift funds allowed on Primary and 2nd Homes",
    ],
    lender: Lender.AmWest,
    flyerFileName: "05_VOE.png",
  },
  {
    id: ProgramID.BankStatementCalculate12MonthIncome,
    name: "Bank Statement: Calculate 12-Month Income",
    description: "Up to 55% DTI",
    highlights: [
      "Up to 55% DTI",
      "Downpayment 20% or more",
      "Use most recent 24 or 12 months personal or business bank statements to verify income",
    ],
    lender: Lender.NewWave,
    flyerFileName: "02_Bank_Statement.png",
  },
  {
    id: ProgramID.PnLNoBankStatement,
    name: "P&L: No Bank Statement",
    description: "Personal or business P&L only, no bank statements needed",
    highlights: [
      "Personal or business P&L only, no bank statements needed",
      "No 4506-C or tax returns required",
      "Max DTI 49%",
      "Gift funds allowed on Primary and 2nd Homes",
      "LTV/CLTV up to 80%",
    ],
    lender: Lender.AmWest,
    flyerFileName: "06_PnL.png",
  },
  {
    id: ProgramID.DSCRNoRatio,
    name: "DSCR No Ratio",
    description: "Minimum DSCR 0",
    highlights: ["Minimum DSCR 0"],
    lender: Lender.Greenbox,
    flyerFileName: "01_DSCR_No_Ratio.png",
  },
  {
    id: ProgramID.DSCREasy,
    name: "DSCR Easy",
    description: "Up to 90% LTV",
    highlights: [
      "Short term rental income can be used as qualified income",
      "Up to 90% LTV",
      "Up to $4.0 Million loan amount",
      "Min Fico 620",
    ],
    lender: Lender.TheLender,
    flyerFileName: "03_DSCR_Easy.png",
  },
  {
    id: ProgramID.ForeignNational1NoIncome,
    name: "Foreign National 1: No Income",
    description: "No income doc required",
    highlights: ["LTV : 60%", "No income doc required"],
    lender: Lender.AAA,
    flyerFileName: "04_Foreign_Nationals_No_Income.png",
  },
  {
    id: ProgramID.ForeignNational2ForeignIncome,
    name: "Foreign National 2: Foreign Income",
    description: "Foreign income allowed for credit history building, LTV 65%",
    highlights: [
      "Foreign income allowed for credit history building",
      "LTV: 65%",
    ],
    lender: Lender.NewOmniBank,
  },
  {
    id: ProgramID.ReverseMortgage,
    name: "Reverse Mortgage",
    description: "",
    highlights: [
      "Payout Options for Reverse Mortgages: Lump sum, Monthly Payments, Line of credit, combination",
    ],
    lender: Lender.NewOmniBank,
  },
  {
    id: ProgramID.RenovationLoan,
    name: "Renovation Loan",
    description: "Minimum credit Score of 620",
    highlights: [
      "Credit Score: Minimum score of 620.",
      "(DTI): DTI ratio must not exceed 43%",
    ],
    lender: Lender.Plaza,
  },
];

/**
 * Map of all programs: ProgramID (from enum) -> Program
 */
export const programMap = new Map(
  programs.map((program) => [program.id, program])
);

/* ------- Grouped programs into different categories ------- */

const ConventionalPrograms: IProgram[] = [
  ProgramID.ConformingLoan,
  ProgramID.HighBalanceLoan,
  ProgramID.JumboLoan,
]
  .map((id) => programMap.get(id))
  .filter((program) => program !== undefined);

const NonQMLoanPrograms: IProgram[] = [
  ProgramID.VOENoBankStatement,
  ProgramID.BankStatementCalculate12MonthIncome,
  ProgramID.PnLNoBankStatement,
  ProgramID.DSCRNoRatio,
  ProgramID.DSCREasy,
]
  .map((id) => programMap.get(id))
  .filter((program) => program !== undefined);

const SpecialPrograms: IProgram[] = [
  ProgramID.ForeignNational1NoIncome,
  ProgramID.ForeignNational2ForeignIncome,
  ProgramID.ReverseMortgage,
  ProgramID.RenovationLoan,
]
  .map((id) => programMap.get(id))
  .filter((program) => program !== undefined);

/**
 * Grouped programs into different categories for display
 * in the program list
 */
export const groupedProgramsUnclickable: {
  name: string;
  key: string;
  programs: IProgram[];
}[] = [
  {
    name: "Conventional Loans",
    key: "conventional-grouped-loans",
    programs: ConventionalPrograms,
  },
];

/**
 * Grouped programs into different categories for display
 * in the program list
 */
export const groupedProgramsClickable: {
  name: string;
  key: string;
  programs: IProgram[];
}[] = [
  {
    name: "Non-QM Loans",
    key: "non-qm-grouped-loans",
    programs: NonQMLoanPrograms,
  },
  {
    name: "Special Programs",
    key: "special-grouped-loans",
    programs: SpecialPrograms,
  },
];

/**
 * Grouped programs used for questionnaire
 */
export const questionnairedPrograms: Record<string, ProgramID[]> = {
  "1": [ProgramID.ForeignNational1NoIncome],
  "2": [ProgramID.ForeignNational2ForeignIncome],
  "3": [
    ProgramID.BankStatementCalculate12MonthIncome,
    ProgramID.PnLNoBankStatement,
  ],
  "4": [ProgramID.DSCRNoRatio, ProgramID.DSCREasy],
  "5": [ProgramID.VOENoBankStatement],
  "6": [ProgramID.RenovationLoan],
  "7": [ProgramID.ReverseMortgage],
  noresults: [],
};
