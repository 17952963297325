import React from "react";

const EligibilityForeignNational1NoIncome = () => {
  return (
    <div style={{ height: "50vh" }}>
      <div
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "left",
          padding: "0 0 1em 1em",
        }}
      >
        Eligibility
      </div>
      <ul>
        <li>
          Eligible for US citizens, permanent/temporary residents, non-permanent
          residents.
        </li>
        <li>
          Down Payment Assistance for Santa Clara and Los Angeles counties.
        </li>
        <li>FICO min 620; LTV max 97%.</li>
      </ul>
    </div>
  );
};

export default EligibilityForeignNational1NoIncome;
