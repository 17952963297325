import React from "react";

const EligibilityBankStatement12Month = () => {
  return (
    <div  style={{ height: "50vh" }}>
      <div
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "left",
          padding: "0 0 1em 1em",
        }}
      >
        Eligibility
      </div>
      <ul>
        <li>
          US citizens, permanent/non-permanent resident aliens, LLCs, and
          corporations.
        </li>
        <li>
          Income documents: 12/24 months bank statements or 1099s, asset
          utilization, P&L statements.
        </li>
        <li>
          Alternative docs: Business narrative, recent 12/24 months bank
          statements, NSF checks, and 1099s.
        </li>
        <li>Requires 4506-C for all income types on full doc loans.</li>
      </ul>
    </div>
  );
};

export default EligibilityBankStatement12Month;
