import React, { useState } from "react";
import { Divider, Spin } from "antd";
import { Form, Col, Row, Button } from "react-bootstrap";
import * as com from "../Common";
import { Result } from "antd";
import { CommaizedInput } from "../NumericalInput";

/**
 * Eligibility for PNL NO Bank Statement
 * 1. Loan purpose
 * 2. Occupancy
 * 3. FICO score
 * 4. Income
 * 5. Property value
 * 6. Down payment
 *
 * Source eligibility requirements:
 * - Eligible for primary residences, second homes, and investment properties.
 * - Terms: 12/24 months.
 * - Loan types: 30-year fixed, 7/6 SOFR ARMs (no I/O option).
 * - Purchase: max LTV/CLTV 80%; Rate/Term max 75%; Cash-out max 70%; min FICO 660.
 * - Investment properties max LTV 75%; prepayment penalty may apply.
 * - Borrowers must meet guideline ratios.
 *
 * @returns
 */
const EligibilityPNLNOBank = () => {
  // Property related fields and calculation
  const [purpose, setPurpose] = useState("");
  const [occupancy, setOccupancy] = useState("");

  // Loan related fields and calculation
  const [propertyValue, setPropertyValue] = useState("");
  const [fico, setFico] = useState<number>(780);
  const [downPayment, setDownPayment] = useState("");
  const [cashOutAmount, setCashOutAmount] = useState("");

  const displayedLoanAmount = () => {
    if (propertyValue === "" || downPayment === "") return "";
    let dp = com.safeParseInt(downPayment);
    let pp = com.safeParseInt(propertyValue);

    let loanAmount = purpose === "purchase" ? pp - dp : dp;
    if (purpose === "cashOutRefinance")
      loanAmount += com.safeParseInt(cashOutAmount);

    return (
      <Row className="blueTips bold mb-2">
        <Col>
          <div>Your loan amount is ${com.commaize(loanAmount)}</div>
        </Col>
      </Row>
    );
  };
  // Submit and check eligibility
  const [loading, setLoading] = useState(false);
  const [qualified, setQualified] = useState<boolean | null>(null);
  const [unqualifiedReason, setUnqualifiedReason] = useState("");
  const [qualifiedTitle, setQualifiedTitle] = useState("");

  // Submit and check eligibility
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // Set loading to true
    setLoading(true);
    // set all other states to defualt
    setQualified(null);
    setUnqualifiedReason("");

    e.preventDefault();
    const dp = com.safeParseInt(downPayment);
    const pp = com.safeParseInt(propertyValue);
    const co = com.safeParseInt(cashOutAmount);
    const ltv = purpose === "purchase" ? (pp - dp) / pp : (co + dp) / pp;

    if (occupancy === "investment") {
      if (ltv > 0.75) {
        setUnqualifiedReason("Investment properties max LTV 75%");
        setLoading(false);
        setQualified(false);
        return;
      }
      setQualifiedTitle(
        "You're qualified for the program (prepayment penalty may apply)."
      );
      setLoading(false);
      setQualified(true);
      return;
    }

    if (fico < 660) {
      setUnqualifiedReason("Fico score cannot be less than 660.");
      setLoading(false);
      setQualified(false);
      return;
    }

    if (purpose === "purchase") {
      if (ltv > 0.8) {
        setUnqualifiedReason("Purchase max LTV/CLTV 80%");
        setLoading(false);
        setQualified(false);
        return;
      }
      setQualifiedTitle("You're qualified for the program.");
      setLoading(false);
      setQualified(true);
      return;
    }

    if (purpose === "rateTermRefinance") {
      if (ltv > 0.75) {
        setUnqualifiedReason("Rate/Term refinance max LTV/CLTV 75%");
        setLoading(false);
        setQualified(false);
        return;
      }
      setQualifiedTitle("You're qualified for the program.");
      setLoading(false);
      setQualified(true);
      return;
    }

    if (purpose === "cashOutRefinance") {
      if (ltv > 0.7) {
        setUnqualifiedReason("Cash out refinance max LTV/CLTV 70%");
        setLoading(false);
        setQualified(false);
        return;
      }
      setQualifiedTitle("You're qualified for the program.");
      setLoading(false);
      setQualified(true);
      return;
    }
  };

  return (
    <div className="qualifierFormWrap">
      <Form onSubmit={handleSubmit} className="qualifierForm">
        <Row>
          <Col md={6} xs={12}>
            <Form.Group controlId="purpose" className="">
              <Form.Label className="text-left">Loan purpose</Form.Label>
              <Form.Control
                required
                name="purpose"
                as="select"
                size="sm"
                defaultValue={purpose}
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
              >
                <option value="">...</option>
                <option value="purchase">Purchase</option>
                <option value="rateTermRefinance">Rate/Term refinance</option>
                <option value="cashOutRefinance">Cash out refinance</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6} xs={12}>
            <Form.Group controlId="purpose" className="">
              <Form.Label className="text-left">Occupancy</Form.Label>
              <Form.Control
                required
                name="occupancy"
                as="select"
                size="sm"
                defaultValue={occupancy}
                value={occupancy}
                onChange={(e) => setOccupancy(e.target.value)}
              >
                <option value="">...</option>
                <option value="primary">Primary</option>
                <option value="secondHome">Second home</option>
                <option value="investment">Investment(rental)</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6} xs={12}>
            <Form.Group>
              <Form.Label>FICO (optional)</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                value={fico}
                defaultValue={fico}
                onChange={(e) => setFico(com.safeParseInt(e.target.value))}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
            <Form.Group>
              <Form.Label>Property price</Form.Label>
              <CommaizedInput
                value={propertyValue}
                onChange={(e) => setPropertyValue(e.target.value)}
                withDollarSign
                required
              />
            </Form.Group>
          </Col>
          <Col md={purpose === "cashOutRefinance" ? 4 : 6} xs={12}>
            <Form.Group>
              <Form.Label>
                {purpose === "purchase" ? "Down payment" : "Current balance"}
              </Form.Label>
              <CommaizedInput
                className="gmccCalculatorInput"
                value={downPayment}
                onChange={(e) => setDownPayment(e.target.value)}
                withDollarSign
                required
              />
            </Form.Group>
          </Col>
          {purpose === "cashOutRefinance" && (
            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Cash out amount</Form.Label>
                <CommaizedInput
                  className="gmccCalculatorInput"
                  value={cashOutAmount}
                  onChange={(e) => setCashOutAmount(e.target.value)}
                  withDollarSign
                  required
                />
              </Form.Group>
            </Col>
          )}
        </Row>

        {displayedLoanAmount()}

        <Row className="ml-2 mt-2">
          <Col className="text-center">
            <Button type="submit" variant="zeitro-primary">
              {loading ? (
                <div className="gmccSpin">
                  <Spin />
                </div>
              ) : (
                "Check my eligibility"
              )}
            </Button>
          </Col>
        </Row>
      </Form>

      {!loading && qualified === true && (
        <Result
          status="success"
          title={qualifiedTitle}
          subTitle={
            <div className="text-left">
              <div>1. Terms: 12/24 months.</div>
              <div>
                2. Loan types: 30-year fixed, 7/6 SOFR ARMs (no I/O option).
              </div>
              <div>3. Borrowers must meet guideline ratios.</div>
            </div>
          }
        />
      )}
      {!loading && qualified === false && (
        <Result
          status="warning"
          title="You're not qualified for the program."
          subTitle={<div>{unqualifiedReason}</div>}
        />
      )}
      <Divider />
      <Row className="qualifierDisclaimerWrap">
        <div className="disclaimerContainer">
          <div className="title-6 mb-2">Disclaimer:</div>
          <div className="title-6">
            All loans are subject to credit approval. Interest rates are subject
            to change daily and without notice. Current interest rates shown our
            indicative of market conditions and individual qualifications and
            will vary upon your lock-in period, loan type, credit score, purpose
            and loan to value.
          </div>
        </div>
      </Row>
    </div>
  );
};

export default EligibilityPNLNOBank;
