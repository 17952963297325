import React from "react";

const EligibilityDSCREasy = () => {
  return (
    <div  style={{ height: "50vh" }}>
      <div
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "left",
          padding: "0 0 1em 1em",
        }}
      >
        Eligibility
      </div>
      <ul>
        <li>Borrowers: US citizens, permanent/non-permanent resident aliens.</li>
        <li>Prepayment penalties of 5% for prepaid amount (prepay options of 0-5 years available).</li>
        <li>State/county restrictions apply.</li>
        <li>Qualify at note rate.</li>
        <li>Impounds {">"} 80% for all states.</li>
        <li>Interest only: purchase 80% max LTV; refinances 75% max LTV; min FICO 680.</li>
      </ul>
    </div>
  );
};

export default EligibilityDSCREasy;
