import React, { useState, useRef } from "react";
import { Carousel, Button } from "antd";
import { Row, Col } from "react-bootstrap";
import { LeftOutlined } from "@ant-design/icons";
import { questionnairedPrograms, ProgramID, programMap } from "./programs";

interface QuestionnaireProps {
  showProgram: (programID: ProgramID) => void;
}

const Questionnaire: React.FC<QuestionnaireProps> = ({ showProgram }) => {
  const [resultIndex, setResultIndex] = useState<string | null>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const carouselRef = useRef(null);

  const goToPrev = () => {
    setCurrentSlideIndex(currentSlideIndex - 1);
    if (carouselRef.current) {
      (carouselRef.current as any).prev();
    }
  };

  /**
   * Navigate to a specific slide
   * @param slideIndex - The index of the slide to navigate to, starting from 0
   */
  const navigateToSlide = (slideIndex: number) => {
    setCurrentSlideIndex(slideIndex);
    if (carouselRef.current) {
      (carouselRef.current as any).goTo(slideIndex, false);
    }
  };

  const resetSlide = () => {
    setResultIndex(null);
    if (carouselRef.current) {
      (carouselRef.current as any).goTo(0, false);
    }
    setCurrentSlideIndex(0);
  };

  /**
   * Questionnaire questions
   * Only show going back button if onBack is defined
   */
  const questionnaire: {
    name: string;
    question: string;
    onYes: () => void;
    onNo: () => void;
    onBack?: () => void;
  }[] = [
    {
      name: "question1",
      question:
        "Are you a foreign national looking to purchase property inthe U.S.?",
      onYes: () => navigateToSlide(1),
      onNo: () => navigateToSlide(2),
    },
    {
      name: "question1a",
      question:
        "Do you have verifiable income documentation from your home country?",
      onYes: () => setResultIndex("2"),
      onNo: () => setResultIndex("1"),
      onBack: () => goToPrev(),
    },
    {
      name: "question1b",
      question: "    Are you 62 years old or older?",
      onYes: () => navigateToSlide(3),
      onNo: () => navigateToSlide(4),
      onBack: () => navigateToSlide(0),
    },
    {
      name: "question1c",
      question:
        "Do you want to get a credit line or cash out from your current primary residence?",
      onYes: () => setResultIndex("7"),
      onNo: () => navigateToSlide(4),
      onBack: () => navigateToSlide(2),
    },
    {
      name: "question2",
      question:
        "Can you provide alternative income verification such as bank statements or a Profit & Loss statement?",
      onYes: () => setResultIndex("3"),
      onNo: () => navigateToSlide(5),
      onBack: () => navigateToSlide(0),
    },
    {
      name: "question3",
      question: "Are you purchasing an investment property?",
      onYes: () => setResultIndex("4"),
      onNo: () => navigateToSlide(6),
      onBack: () => goToPrev(),
    },
    {
      name: "question4",
      question:
        "Can your employer provide a Verification of Employment (VOE) to confirm your income and employment status?",
      onYes: () => setResultIndex("5"),
      onNo: () => navigateToSlide(7),
      onBack: () => goToPrev(),
    },
    {
      name: "question5",
      question:
        "Are you looking to purchase a property that requires renovation?",
      onYes: () => setResultIndex("6"),
      onNo: () => setResultIndex("noresults"),
      onBack: () => goToPrev(),
    },
  ];

  const displayResult = (): React.ReactNode | null => {
    if (resultIndex === null) {
      console.error("resultIndex is null");
      return null;
    }
    const programIDsToDisplay = questionnairedPrograms[resultIndex];
    let programsDiv: React.ReactNode | undefined = undefined;
    if (programIDsToDisplay?.length === 0) {
      programsDiv = (
        <div className="programResults">
          <div style={{ fontSize: 20 }}>
            Based on your answers, we recommend contacting a mortgage
            professional to discuss your options.
          </div>
        </div>
      );
    } else {
      const programsToDisplay = programIDsToDisplay
        .map((programID) => programMap.get(programID))
        .filter((program) => program !== undefined);
      if (programsToDisplay.length === 0) {
        console.error("No programs to display");
        return null;
      }
      programsDiv = (
        <div className="programResults">
          {programsToDisplay.map((program) => (
            <div className="program" onClick={() => showProgram(program.id)}>
              <div className="aAsLink d-flex align-items-center mb-1 bold">
                <img src="/images/condo.png" alt={program.name} />{" "}
                {program.name}
              </div>
              <div className="mt-1">{program.description}</div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="programResultWrap">
        <div
          className="text-left bold align-items-center d-flex"
          style={{ fontSize: 15, cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            resetSlide();
          }}
        >
          <LeftOutlined className="mr-2" />
          Start again
        </div>
        <div style={{ fontSize: "18px" }}>
          We have the right programs for you!
        </div>
        <div style={{ fontSize: "12px" }}>
          Please use the program quailifer to check your eligibility and contact
          your loan officer for more details.
        </div>
        {programsDiv}
      </div>
    );
  };

  return (
    <div>
      {resultIndex !== null ? (
        displayResult()
      ) : (
        <div className="programInitialGuideWrap">
          <Row>
            <Col md={6} xs={12}>
              <div className="slideDotsWrap">
                <div className="title-1" style={{ color: "#6E6E70" }}>
                  Find the right programs{" "}
                </div>
                <div className="title-1">for your needs</div>
                <div className="mt-3" style={{ fontSize: "20px" }}>
                  RateDNA can help you find the right programs for your needs.
                </div>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="carouselWrap">
                <Carousel
                  afterChange={setCurrentSlideIndex}
                  ref={carouselRef}
                  dots={false}
                  autoplay={false}
                >
                  {questionnaire.map((question) => (
                    <div className="slide">
                      {question.onBack !== undefined ? (
                        <div className="d-flex justify-content-left align-items-center">
                          <div className="text-left pt-3">
                            <LeftOutlined
                              onClick={() => (question as any).onBack()}
                            />
                          </div>
                          <div className="text-center title-3 ml-2 pt-3 w-100">
                            {question.question}
                          </div>
                        </div>
                      ) : (
                        <div className="title-3 pt-3">{question.question}</div>
                      )}
                      <div className="mt-3 d-flex justify-content-around">
                        <Button type="default" onClick={() => question.onNo()}>
                          No
                        </Button>
                        <Button type="primary" onClick={() => question.onYes()}>
                          Yes
                        </Button>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Questionnaire;
