import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  groupedProgramsClickable,
  groupedProgramsUnclickable,
  ProgramID,
} from "./programs";
import { RightOutlined } from "@ant-design/icons";

import "./SpecialRateDNA.css";
import Questionnaire from "./Questionnaire";
import SingleProgram from "./SingleProgram";

// Usage: http://localhost:3000/resources/special-ratedna

const SpecialRateDNA = () => {
  const [currentShownProgramID, setCurrentShownProgramID] =
    useState<ProgramID | null>(null);
  const showProgram = (programID: ProgramID) => {
    setCurrentShownProgramID(programID);
  };
  return (
    <div className="special-rate-dna">
      {groupedProgramsUnclickable.map(({ name, key, programs }) => (
        <div key={key}>
          <div className="program-list-title">{name}</div>
          <div className="program-list">
            {programs.map((item, index) => {
              return (
                <div
                  className="program-card-container"
                  style={{ cursor: "default" }}
                  key={index}
                >
                  <div className="program-title">
                    <div className="label">{item.name} </div>
                  </div>
                  <div className="program-description">{item.description}</div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
      <Questionnaire showProgram={showProgram} />
      {currentShownProgramID && (
        <div className="single-program-container">
          <SingleProgram
            programID={currentShownProgramID}
            closeSingleProgram={() => setCurrentShownProgramID(null)}
          />
        </div>
      )}
      {!currentShownProgramID &&
        groupedProgramsClickable.map(({ name, key, programs }) => (
          <div key={key}>
            <div className="program-list-title">{name}</div>
            <div className="program-list">
              {programs.map((item, index) => {
                return (
                  <div
                    className="program-card-container"
                    key={index}
                    onClick={() => showProgram(item.id)}
                  >
                    <div className="program-title">
                      <div className="label">{item.name} </div>
                      <RightOutlined
                        className="label"
                        style={{ color: "#979797" }}
                      />
                    </div>
                    <div className="program-description">
                      {item.description}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
    </div>
  );
};

export default withRouter(SpecialRateDNA);
