import { LeftOutlined } from '@ant-design/icons'
import { Dropdown, Tag, Tooltip, Progress } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { isEmpty, trim, every } from 'lodash'
import { CheckSignInMethod, checkCustomerInfo } from '../api/api'
import './styles/newSideBarApplication.css'
import { getLoans } from '../api/api'
import {
    addTaskStatusUpdateListener,
    removeTaskStatusUpdateListener,
    updateTaskStatus,
} from '../../AppEvents'
import * as com from '../../Common'
import { assembleFullName, camelToTitleCase } from '../../Tools/String'
import { getLoanAddress } from '../../Tools/Loan'

function NewSideBarApplication(props) {
    const [method, setMethod] = useState('')
    const [taskCount, setTaskCount] = useState(0)
    const [companyName, setCompanyName] = useState('')
    const customerId = com.getCustomerId()
    const application = useSelector((state) => state.application)
    const progress = useSelector((state) => state.progress)

    const purpose = useMemo(() => application.property.purpose, [application.property.purpose])
    const address = useMemo(() => application.property.address, [application.property.address])
    const county = useMemo(() => application.property.county, [application.property.county])
    const state = useMemo(() => application.property.state, [application.property.state])
    const step = useMemo(() => progress.interview.step, [progress.interview.step])
    const maxStep = useMemo(() => progress.interview.max, [progress.interview.max])
    const firstName = useMemo(() => application.borrower.firstname, [application.borrower.firstname])
    const email = useMemo(() => application.borrower.email, [application.borrower.email])
    const hasCoBorrower = useMemo(() => application.hascoborrower === 'withcoborrower', [application.hascoborrower])

    // const [percent, setPercent] = useState(25)

    const [loanCurrentStatus, setLoanCurrentStatus] = useState(localStorage.getItem('LOAN_CURRENT_STATUS') || '')
    const [loanCurrentStage, setLoanCurrentStage] = useState(localStorage.getItem('LOAN_CURRENT_STAGE') || '')
    const [isPrequalLetterNew, setIsPrequalLetterNew] = useState(false)

    useEffect(() => {
        getLoans().then((res) => {
            if (res.Status === 'OK') {
                const loan = res.loans.find((item) => item.loanid === res.loanid)
                if (loan) {
                    loan.state = JSON.parse(loan.state)
                    loan.lostate = JSON.parse(loan.lostate)
                    const { currentStage, currentStatus } = loan.state.application
                    setLoanCurrentStatus(currentStatus)
                    setLoanCurrentStage(currentStage)
                    localStorage.setItem('LOAN_OFFICER_NAME', loan.loanofficername)
                    localStorage.setItem('LOAN_CURRENT_STAGE', currentStage)
                    localStorage.setItem('LOAN_CURRENT_STATUS', currentStatus)
                    localStorage.setItem('LOAN_ADDRESS', getLoanAddress(loan))

                    const token = com.getUserToken()
                    fetch('/borrower/get_all_prequal_letters_for_loan', {
                        method: 'POST',
                        headers: {
                            Authorization: 'Bearer ' + token,
                            Cache: 'no-cache',
                        },
                        body: JSON.stringify({ loanid: res.loanid }),
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                res.json().then((data) => {
                                    if (!isEmpty(data) && data.length >= 1) {
                                        setIsPrequalLetterNew(true)
                                    }
                                })
                            }
                        })
                        .catch((err) => {
                            console.error('Get prequal letters error: ', err)
                        })
                }
            }
        }).catch((err) => {
            console.error('Get loans error: ', err)
        })
    }, [])

    useEffect(() => {

    }, [])

    const borrowerFullName = useMemo(() => {
        return assembleFullName(
            firstName,
            application.borrower.middlename,
            application.borrower.lastname
        )
    }, [firstName, application.borrower])

    const coBorrowerFullName = useMemo(() => {
        if (hasCoBorrower) {
            return assembleFullName(
                application.coborrower.firstname,
                application.coborrower.middlename,
                application.coborrower.lastname
            )
        }
    }, [hasCoBorrower, application.coborrower])

    const dropdownMenuItems = useMemo(
        () => [
            {
                label: (
                    <Link
                        to={method === 'email' ? '/application/password' : '/application/changepassword'}
                        className="DropdownP"
                    >
                        {method === 'email' ? 'Set up password' : 'Change password'}
                    </Link>
                ),
                key: '1',
            },
            { type: 'divider' },
            {
                label: (
                    <Link to="/application/logout" className="DropdownP">
                        Sign out
                    </Link>
                ),
                key: '3',
            },
        ],
        [method]
    )

    const applicationURL = useMemo(() => {
        const baseUrl = '/application/interview/main/applicant'
        return maxStep ? `${baseUrl}/${maxStep}` : baseUrl
    }, [maxStep])

    const isApplicationActive = useMemo(() => com.CurrentStage[loanCurrentStage] >= 1, [loanCurrentStage])
    const isProcessingActive = useMemo(() => com.CurrentStage[loanCurrentStage] >= 2, [loanCurrentStage])
    const isClosingActive = useMemo(() => com.CurrentStage[loanCurrentStage] === 3, [loanCurrentStage])

    const purposeImage = useMemo(
        () =>
            purpose !== 'purchase' ? (
                <img src="/money.svg" alt="money" />
            ) : (
                <img src="/house.svg" alt="house" />
            ),
        [purpose]
    )

    const displayAddress = useMemo(() => {
        // Return TBD if both address and county are empty
        if (isEmpty(address) && isEmpty(county)) {
            return 'TBD'
        }

        // Return address if it exists, otherwise format county and state
        return isEmpty(address)
            ? `${com.removeCountySuffix(county)}, ${com.convertStateToAbbr(state)}`
            : address
    }, [address, county, state])

    useEffect(() => {
        let isMounted = true

        const fetchData = async () => {
            updateTaskStatus()
            addTaskStatusUpdateListener((e) => {
                if (isMounted) {
                    const taskDataLength = e.detail
                    setTaskCount(taskDataLength)
                }
            })

            try {
                const { Method } = await CheckSignInMethod({
                    CustomerID: sessionStorage.getItem('cid'),
                    Email: application.borrower.email,
                })
                if (isMounted) setMethod(Method)

                const { CompanyName } = await checkCustomerInfo({
                    'X-CustomerId': com.getCustomerId(),
                })
                if (isMounted) setCompanyName(CompanyName)
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()

        return () => {
            isMounted = false
            removeTaskStatusUpdateListener((e) => {
                const taskDataLength = e.detail
                setTaskCount(taskDataLength)
            })
        }
    }, [application.borrower.email])

    function progressIndicator(isCoBorrower = false, fullName) {
        if (isCoBorrower && !hasCoBorrower) {
            return <></>
        }

        const completedsteps = isCoBorrower ? application.coborrower.completedsteps : application.borrower.completedsteps
        const isNewBorrower = every(completedsteps, (step) => step === false)

        if (isNewBorrower) {
            const isValidName = fullName && !isEmpty(fullName.trim())
            if (!isValidName) {
                return <></>
            }
            return (
                <div className="progress-wrapper">
                    <Progress
                        type="circle"
                        percent={0}
                        size={28}
                        strokeWidth={10}
                        format={() => null}
                        strokeColor="#325CEB"
                        trailColor="#BDCBFB"
                    />
                    <div className="progress-text">NEW</div>
                </div>
            )
        }

        let totalSteps = 0
        if (application.hascoborrower !== 'remote') {
            totalSteps += 1 // personal info
            totalSteps += 1 // current residence
            totalSteps += 1 // property
        }
        totalSteps += 1 // employment
        totalSteps += 1 // other income
        totalSteps += 1 // assets
        totalSteps += 1 // credit check
        totalSteps += 1 // liabilities
        totalSteps += 1 // declarations
        totalSteps += 1 // finances

        const {
            yourinfo,
            residence,
            property,
            employment,
            otherincome,
            assets,
            creditcheck,
            liabilities,
            propertymoney,
            finances,
        } = completedsteps

        let completedSteps = 0
        if (application.hascoborrower !== 'remote') {
            if (yourinfo) completedSteps += 1
            if (residence) completedSteps += 1
            if (property) completedSteps += 1
        }
        if (employment) completedSteps += 1
        if (otherincome) completedSteps += 1
        if (assets) completedSteps += 1
        if (creditcheck) completedSteps += 1
        if (liabilities) completedSteps += 1
        if (propertymoney) completedSteps += 1
        if (finances) completedSteps += 1

        const percent = Math.round((completedSteps / totalSteps) * 100)

        return percent === 100 ? (
            <img
                src="/images/sidebaricons/blue-check-mark.svg"
                alt="completed"
                className="sub-step-icon"
            />
        ) : (
            <div className="progress-wrapper">
                <Progress
                    type="circle"
                    percent={percent}
                    size={28}
                    strokeWidth={10}
                    format={() => null}
                    strokeColor="#325CEB"
                    trailColor="#BDCBFB"
                />
                <div className="progress-text">{percent}%</div>
            </div>
        )
    }

    const highlightSection = (section) => {
        switch (section) {
            case 'overview':
                return step === com.stageToNumberApplication.overview
            case 'applicant':
                return step >= com.stageToNumberApplication.applicant && step < com.stageToNumberApplication.coborrower
            case 'coborrower':
                return step >= com.stageToNumberApplication.coborrower && step < com.stageToNumberApplication.overview
            case 'rates':
                return step === com.stageToNumberApplication.rates
            case 'prequalletter':
                return step === com.stageToNumberApplication.prequalletter
            case 'documents':
                return step === com.stageToNumberApplication.documents
            case 'tasks':
                return step === com.stageToNumberApplication.tasks
            default:
                return false
        }
    }

    return (
        <div className="side-bar-container">
            <div>
                <div className="w-100 d-flex align-items-center position-relative company-name-container">
                    <div className="company-name">{companyName}</div>
                    <div className="company-name-underline" />
                </div>

                <div
                    className="text-left back-to-loans"
                    onClick={() => {
                        window.open('/application/myloans', '_self')
                    }}
                >
                    <LeftOutlined className="back-to-loans-icon" />
                    <span className="back-to-loans-text">Back to Loans</span>
                </div>

                <Link
                    to={{
                        pathname: '/application/interview/main/overview',
                        state: { fromManualClick: true },
                    }}
                    className="d-flex align-items-start application-overview"
                    style={{ backgroundColor: highlightSection('overview') ? '#E0E7FF' : '#F6F6F6' }}
                >
                    {purposeImage}
                    <div className="text-left" style={{ marginLeft: '10px' }}>
                        <div
                            className="overview-title"
                            style={{ color: highlightSection('overview') ? '#081265' : '#222222' }}
                        >
                            OVERVIEW
                        </div>
                        <div className="address-text">{displayAddress}</div>
                        <div className="text-center purpose-text">
                            {camelToTitleCase(loanCurrentStatus)}
                        </div>
                    </div>
                </Link>

                <div className="sidebar-steps">
                    {/* Application */}
                    <div className="custom-step">
                        <div className="step-header">
                            <div className="step-number active">1</div>
                            <div className={`step-title ${isApplicationActive ? 'active' : ''}`}>
                                APPLICATION
                            </div>
                        </div>
                        <div className={`step-content ${isApplicationActive ? 'active' : ''}`}>
                            <Link
                                to={applicationURL}
                                className={`sub-step ${highlightSection('applicant') ? 'active' : ''}`}
                            >
                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {borrowerFullName}
                                </span>
                                {progressIndicator(false, borrowerFullName)}
                            </Link>
                            <Link
                                to={'/application/interview/main/coborrower'}
                                className={`sub-step ${highlightSection('coborrower') ? 'active' : ''}`}
                            >
                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {hasCoBorrower && !isEmpty(trim(coBorrowerFullName)) ? coBorrowerFullName : '+ Co-borrower'}
                                </span>
                                {progressIndicator(true, coBorrowerFullName)}
                            </Link>
                            {/* <Link
                                to={'/application/interview/main/creditreport'}
                                className={`sub-step completed ${
                                    step ===
                                    com.stageToNumberApplication.creditreport
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <span>Credit report</span>
                                <img
                                    src="/images/sidebaricons/blue-check-mark.svg"
                                    alt="completed"
                                    className="sub-step-icon"
                                />
                            </Link> */}
                            <Link
                                to={'/application/interview/main/rates'}
                                className={`sub-step ${highlightSection('rates') ? 'active' : ''}`}
                            >
                                <span>Rates</span>
                            </Link>
                            {customerId !== 'serenityhomeloans' && (
                                <Link
                                    to={
                                        '/application/interview/main/prequalletter'
                                    }
                                    className={`sub-step ${highlightSection('prequalletter') ? 'active' : ''}`}
                                >
                                    <span>Pre-qual letter</span>
                                    {isPrequalLetterNew && (
                                        <Tag color="#325CEB" className="new-tag">
                                            New
                                        </Tag>
                                    )}
                                </Link>
                            )}
                        </div>
                    </div>

                    {/* Processing */}
                    <div className="custom-step">
                        <div className="step-header">
                            <div className="step-number active">2</div>
                            <div className={`step-title ${isProcessingActive ? 'active' : ''}`}>
                                PROCESSING
                            </div>
                        </div>
                        <div className={`step-content ${isProcessingActive ? 'active' : ''}`}>
                            {/* <Link
                                to={'/application/interview/main/escrow'}
                                className={`sub-step completed ${
                                    step === com.stageToNumberApplication.escrow
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <span>Escrow info</span>
                                <img
                                    src="/images/sidebaricons/blue-check-mark.svg"
                                    alt="completed"
                                    className="sub-step-icon"
                                />
                            </Link> */}
                            <Link
                                to={'/application/interview/main/documents'}
                                className={`sub-step ${highlightSection('documents') ? 'active' : ''}`}
                            >
                                <span>Documents</span>
                            </Link>
                            <Link
                                to={'/application/interview/main/tasks'}
                                className={`sub-step ${highlightSection('tasks') ? 'active' : ''}`}
                            >
                                <span>Tasks</span>
                                {taskCount > 0 && (
                                    <Tag color="#325CEB" className="new-tag">
                                        {taskCount}
                                    </Tag>
                                )}
                            </Link>
                        </div>
                    </div>

                    {/* Closing */}
                    <div className="custom-step">
                        <Link
                            to={'/application/interview/main/closing'}
                            className="step-header"
                            onClick={(e) => {
                                if (!isClosingActive) {
                                    e.preventDefault()
                                }
                            }}
                            style={{ cursor: isClosingActive ? 'pointer' : 'not-allowed' }}
                        >
                            <div
                                className="step-number active"
                                style={{ backgroundColor: isClosingActive ? '#081265' : '#D9D9D9' }}
                            >
                                3
                            </div>
                            <div
                                className={`step-title ${isClosingActive ? 'active' : ''}`}
                                style={{ color: isClosingActive ? '#081265' : '#6E6E70' }}
                            >
                                CLOSING
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="sidebar-footer">
                <div className="sidebar-footer-divider" />
                <div className="sidebar-footer-content">
                    <Dropdown menu={{ items: dropdownMenuItems }}>
                        <div className="d-flex align-items-center pt-2 pb-2">
                            <div className="NameBar">{firstName.charAt(0)}</div>
                            <div style={{ flex: 1, minWidth: 0, overflow: 'hidden', marginLeft: '10px' }}>
                                <Tooltip placement="right" title={borrowerFullName}>
                                    <div className="text-left overflow-h user-full-name">
                                        {borrowerFullName}
                                    </div>
                                </Tooltip>
                                <Tooltip placement="right" title={email}>
                                    <div className="text-left overflow-h user-email">
                                        {email}
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}

export default NewSideBarApplication
