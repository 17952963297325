import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";

import { ProgramID, programMap } from "./programs";
import "./SingleProgram.css";
import EligibilityVOE from "./EligibilityVOE";
import EligibilityBankStatement12Month from "./EligibilityBankStatement12Month";
import EligibilityPNLNOBank from "./EligibilityPNLNOBank";
import EligibilityDSCRNoRatio from "./EligibilityDSCRNoRatio";
import EligibilityDSCREasy from "./EligibilityDSCREasy";
import EligibilityForeignNational1NoIncome from "./EligibilityForeignNational1NoIncome";
import EligibilityForeignNational2ForeignIncome from "./EligibilityForeignNational2ForeignIncome";

interface SingleProgramProps {
  programID: ProgramID;
  closeSingleProgram: () => void;
}

const programToEligibilityMap: Record<ProgramID, React.ReactNode> = {
  [ProgramID.ConformingLoan]: <div style={{ height: "50vh" }} />,
  [ProgramID.HighBalanceLoan]: <div style={{ height: "50vh" }} />,
  [ProgramID.JumboLoan]: <div style={{ height: "50vh" }} />,
  [ProgramID.VOENoBankStatement]: <EligibilityVOE />,
  [ProgramID.BankStatementCalculate12MonthIncome]: (
    <EligibilityBankStatement12Month />
  ),
  [ProgramID.PnLNoBankStatement]: <EligibilityPNLNOBank />,
  [ProgramID.DSCRNoRatio]: <EligibilityDSCRNoRatio />,
  [ProgramID.DSCREasy]: <EligibilityDSCREasy />,
  [ProgramID.ForeignNational1NoIncome]: <EligibilityForeignNational1NoIncome />,
  [ProgramID.ForeignNational2ForeignIncome]: (
    <EligibilityForeignNational2ForeignIncome />
  ),
  [ProgramID.ReverseMortgage]: <div style={{ height: "50vh" }} />,
  [ProgramID.RenovationLoan]: <div style={{ height: "50vh" }} />,
};

export default function SingleProgram({
  programID,
  closeSingleProgram,
}: SingleProgramProps) {
  const program = programMap.get(programID);
  return (
    <Card
      title={
        <div
          style={{
            fontSize: "24px",
            textAlign: "center",
            padding: "20px 0 20px 0",
          }}
        >
          {program?.name}
        </div>
      }
      className="single-program-card"
      extra={<CloseOutlined onClick={closeSingleProgram} />}
    >
      <Row gutter={[16, 16]}>
        <Col
          span={12}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            {program?.flyerFileName ? (
              <img
                src={`/flyers/rate-dna/${program.flyerFileName}`}
                alt={program?.name}
                style={{ width: "100%" }}
              />
            ) : (
              <img src="/images/condo.png" alt={program?.name} />
            )}
          </div>
        </Col>
        <Col span={12}>
          <div
            className="description"
            style={{
              fontSize: "18px",
              textAlign: "left",
              padding: "0 0 1em 1em",
            }}
          >
            Program Highlights
          </div>
          <div className="highlights">
            <ul>
              {program?.highlights.map((highlight, index) => (
                <li key={index}>{highlight}</li>
              ))}
            </ul>
          </div>
          <div className="checkEligibility">
            {programToEligibilityMap[programID]}
          </div>
        </Col>
      </Row>
    </Card>
  );
}
