import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

import * as com from '../Common.js'
import Form from 'react-bootstrap/Form'
import NumericalInput from '../NumericalInput'
import { InputNumber, Select } from 'antd'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import AddressAutocomplete from '../Common/AddressAutocomplete'
import { connect } from 'react-redux'
import * as act from '../Store/actions'
import * as st from '../State'
import InputGroup from 'react-bootstrap/InputGroup'
import Steps from 'awesome-steps'
import { getTotalExpenses } from './Expenses'
import 'awesome-steps/dist/style.css'
import { Tips } from '../Common/Tooltip'
import CheckGroup from '../Common/components/CheckGroup'
import { isEmpty } from 'lodash'
import { getCurrentState } from '../Store'
import { DollarInput } from '../Common/components/DollarInput.js'
import { getStates, getCounties } from '../Tools/Address.js'
import { isMortgageLoan } from '../Tools/Loan.js'

var parser = require('parse-address')

class PropertyModal extends Component {
    constructor(props) {
        super(props)

        const { propertyIndex, ownedproperties } = props
        const property = propertyIndex < ownedproperties.length ? ownedproperties[propertyIndex] : {}

        this.state = {
            loanLimits: {},
            validated: false,
            property: property,
        }

        this.form = React.createRef()
    }

    componentDidMount() {
        this.fetchLoanLimits()
    }

    componentDidUpdate(prevProps) {
        const { propertyIndex, ownedproperties } = this.props
        if (prevProps.ownedproperties !== ownedproperties || prevProps.propertyIndex !== propertyIndex) {
            const property = propertyIndex < ownedproperties.length ? ownedproperties[propertyIndex] : {}
            this.setState({ property })
        }
    }

    fetchLoanLimits = async () => {
        try {
            const response = await fetch('/data/loanlimits')
            if (!response.ok) {
                throw Error(response.statusText)
            }
            const data = await response.json()
            this.setState({ loanLimits: data })
        } catch (err) {
            console.error(err)
        }
    }

    onStreetAddressCompletion = (address, index, changeOwnedProperty) => {
        const num = com.getSubaddress(address, 'street_number', 'long_name')
        const city = com.getSubaddress(address, 'locality', 'long_name')
        const state = com.getSubaddress(address, 'administrative_area_level_1', 'long_name')
        const county = com.getSubaddress(address, 'administrative_area_level_2', 'long_name')
        let zip = com.getSubaddress(address, 'postal_code', 'long_name')
        const zips = com.getSubaddress(address, 'postal_code_suffix', 'short_name')
        if (zips !== '') zip = zip + '-' + zips

        const short = com.getSubaddress(address, 'route', 'short_name')
        const stradd = num + ' ' + short

        changeOwnedProperty(stradd, index, 'address')
        changeOwnedProperty(city, index, 'city')
        changeOwnedProperty(state, index, 'state')
        changeOwnedProperty(county, index, 'county')
        changeOwnedProperty(zip, index, 'zipcode')
    }

    handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (this.form.current.reportValidity() === false) {
            this.setState({ validated: true })
            return false
        }
        this.props.onSubmit(this.state.formData)
    }

    handleInputChange = (field, value) => {
        const { index, who, onPropertyChange } = this.props
        this.setState((prevState) => ({ [field]: value }))
        onPropertyChange({ [field]: value }, index, who)
    }

    onHaveMortgateForProperty = (e) => {
        const { propertyIndex, changeOwnedProperty } = this.props
        const checked = e.target.checked

        if (checked) {
            changeOwnedProperty([], propertyIndex, 'loans')
        } else {
            const additionalLoan = JSON.parse(new st.loan().toJson())
            additionalLoan.creditloantype = 'ConventionalRealEstateMortgage'
            additionalLoan.key = Math.random().toString()
            changeOwnedProperty([additionalLoan], propertyIndex, 'loans')
        }
    }

    mortgageLoanForm = (property, propertyIndex, loanIndex, changeOwnedProperty) => {
        const loan = !isEmpty(property.loans) && loanIndex < property.loans.length ? property.loans[loanIndex] : {}
        if (isEmpty(loan)) return <></>

        const handleFieldChange = (field, value) => {
            const updatedLoans = [...property.loans]
            updatedLoans[loanIndex][field] = value
            changeOwnedProperty(updatedLoans, propertyIndex, 'loans')
        }

        return (
            <div key={loan.key} style={{ marginTop: '24px' }}>
                <div style={{ border: '1px solid #DBDCDE', borderRadius: '8px', overflow: 'hidden' }}>
                    {/* Header Section */}
                    <div className="property-mortgage-header">
                        <img src="/images/mortgage-loan-icon.svg" alt="bank" />
                        <span style={{ color: '#222222', fontSize: '16px', fontWeight: '500', marginLeft: '8px' }}>
                            Add mortgage loan
                        </span>
                    </div>

                    {/* Form Section */}
                    <div style={{ padding: '16px 24px 24px 24px' }}>
                        <Form.Row>
                            <Col md={6} xs={12} className="mb-3">
                                <Form.Label style={{ color: '#6E6E70' }}>Type</Form.Label>
                                <Select style={{ width: '100%' }} placeholder="Select" value={loan.creditloantype} onChange={(e) => handleFieldChange('creditloantype', e)}>
                                    <Select.Option value="ConventionalRealEstateMortgage">Conventional</Select.Option>
                                    <Select.Option value="FHARealEstateMortgage">FHA</Select.Option>
                                    <Select.Option value="VeteransAdministrationRealEstateMortgage">VA</Select.Option>
                                    <Select.Option value="USDA-RD">USDA-RD</Select.Option>
                                    <Select.Option value="Other">Other</Select.Option>
                                </Select>
                            </Col>
                            <Col md={6} xs={12} className="mb-3">
                                <Form.Label style={{ color: '#6E6E70' }}>Lender</Form.Label>
                                <Form.Control
                                    value={loan.name}
                                    style={{
                                        height: '44px',
                                        borderRadius: '4px'
                                    }}
                                    onChange={(e) => handleFieldChange('name', e.target.value)}
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row>
                            <Col md={6} xs={12} className="mb-3">
                                <Form.Label style={{ color: '#6E6E70' }}>Account number</Form.Label>
                                <Form.Control
                                    placeholder="Account number"
                                    value={loan.accountnumber}
                                    style={{
                                        height: '44px',
                                        borderRadius: '4px'
                                    }}
                                    onChange={(e) => handleFieldChange('accountnumber', e.target.value)}
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label style={{ color: '#6E6E70' }}>Mo. payment</Form.Label>
                                <DollarInput
                                    placeholder="---"
                                    value={loan.monthlypayment}
                                    style={{
                                        height: '44px',
                                        borderRadius: '4px'
                                    }}
                                    onChange={(e) => handleFieldChange('monthlypayment', e)}
                                />
                            </Col>
                        </Form.Row>

                        <Form.Row>
                            <Col md={6} className="mb-3">
                                <Form.Label style={{ color: '#6E6E70' }}>Unpaid balance</Form.Label>
                                <DollarInput
                                    placeholder="---"
                                    value={loan.remainingbalance}
                                    style={{
                                        height: '44px',
                                        borderRadius: '4px'
                                    }}
                                    onChange={(e) => handleFieldChange('remainingbalance', e)}
                                />
                            </Col>
                            <Col md={6} xs={12} className="mb-3">
                                <Form.Label style={{ color: '#6E6E70' }}>Paid off at/before closing?</Form.Label>
                                <Select style={{ width: '100%' }} placeholder="Select" value={loan.paidoffatclosing} onChange={(e) => handleFieldChange('paidoffatclosing', e)}>
                                    <Select.Option value="yes">Yes</Select.Option>
                                    <Select.Option value="no">No</Select.Option>
                                </Select>
                            </Col>
                        </Form.Row>

                        <Form.Row>
                            <Col md={6} xs={12}>
                                <Form.Label style={{ color: '#6E6E70' }}>Credit limit (optional)</Form.Label>
                                <DollarInput
                                    placeholder="---"
                                    value={loan.creditlimit}
                                    style={{
                                        height: '44px',
                                        borderRadius: '4px'
                                    }}
                                    onChange={(e) => handleFieldChange('creditlimit', e)}
                                />
                            </Col>
                        </Form.Row>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { show, onHide, propertyIndex, ownership, changeOwnedProperty } = this.props
        const { loanLimits, validated, property } = this.state

        const isAdditional = ownership === st.O_Own ? propertyIndex > 0 : true

        return (
            <Modal className="employment-modal" size="lg" show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <div style={{ paddingLeft: '20px' }}>
                        <Modal.Title>
                            {isAdditional ? 'Additional property you own' : 'Property you own'}
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Form ref={this.form} noValidate onSubmit={this.handleSubmit} validated={validated}>
                    <Modal.Body style={{ padding: '0 40px 14px', maxHeight: '80vh', overflow: 'auto' }}>
                        <div style={{ marginTop: '20px' }}>
                            {/* Address Section */}
                            <Form.Label>Address</Form.Label>
                            <Form.Row>
                                <Col xs={12} className="mb-2">
                                    <AddressAutocomplete
                                        name={'adress' + propertyIndex}
                                        id={'address' + propertyIndex}
                                        placeholder="Address line 1"
                                        defaultValue={property.address}
                                        onChange={(e) => {
                                            changeOwnedProperty(e.target.value, propertyIndex, 'address')
                                        }}
                                        onCompletion={(address) => this.onStreetAddressCompletion(address, propertyIndex, changeOwnedProperty)}
                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                        required
                                    />
                                </Col>
                                <Col xs={12} className="mb-2">
                                    <Form.Control
                                        placeholder="Address line 2"
                                        value={property.unit}
                                        onChange={(e) => {
                                            changeOwnedProperty(e.target.value, propertyIndex, 'unit')
                                        }}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col md={3} xs={12}>
                                    <Select
                                        placeholder="State"
                                        value={property.state}
                                        onChange={(val) => {
                                            changeOwnedProperty(val, propertyIndex, 'state')
                                        }}
                                        style={{ width: '100%' }}
                                        required
                                    >
                                        {getStates(loanLimits).map(x => (
                                            <Select.Option value={x.value} key={x.value}>
                                                {x.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col md={3} xs={12}>
                                    <Select
                                        placeholder="County"
                                        value={property.county}
                                        onChange={(val) => {
                                            changeOwnedProperty(val, propertyIndex, 'county')
                                        }}
                                        style={{ width: '100%' }}
                                        required
                                    >
                                        {getCounties(loanLimits, property.state).map(x => (
                                            <Select.Option value={x.value} key={x.value}>
                                                {x.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col md={3} xs={12}>
                                    <Form.Control
                                        required
                                        size="sm"
                                        onChange={(e) => {
                                            changeOwnedProperty(e.target.value, propertyIndex, 'city')
                                        }}
                                        value={property.city}
                                        placeholder="City"
                                    />
                                </Col>
                                <Col md={3} xs={12}>
                                    <Form.Control
                                        required
                                        size="sm"
                                        placeholder="Zip code"
                                        value={property.zipcode}
                                        onChange={(e) => {
                                            changeOwnedProperty(e.target.value, propertyIndex, 'zipcode')
                                        }}
                                    />
                                </Col>
                            </Form.Row>

                            {/* Property Info Section */}
                            <Form.Label className="property-section-title" style={{ marginTop: '32px', marginBottom: '20px' }}>
                                Property Info
                            </Form.Label>
                            <Form.Row>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Property value</Form.Label>
                                    <InputNumber
                                        prefix="$"
                                        className="w-100"
                                        value={property.marketvalue}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={(e) => {
                                            changeOwnedProperty(e, propertyIndex, 'marketvalue')
                                        }}
                                        size="large"
                                    />
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Status</Form.Label>
                                    <Select
                                        className="w-100"
                                        style={{ height: 44 }}
                                        value={property.status}
                                        options={[
                                            { value: 'sold', label: 'Sold' },
                                            { value: 'pending', label: 'Pending sale' },
                                            { value: 'retained', label: 'Retained' },
                                        ]}
                                        onChange={(e) => {
                                            changeOwnedProperty(e, propertyIndex, 'status')
                                        }}
                                    />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Property intended occupancy</Form.Label>
                                    <Select
                                        className="w-100"
                                        style={{ height: 44 }}
                                        value={property.occupancy}
                                        options={[
                                            { value: 'principal', label: 'Primary Residence' },
                                            { value: 'secondhome', label: 'Second Home' },
                                            { value: 'investment', label: 'Investment' },
                                            { value: 'other', label: 'Other' },
                                        ]}
                                        onChange={(e) => {
                                            changeOwnedProperty(e, propertyIndex, 'occupancy')
                                        }}
                                    />
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Mo. insurance, taxes, association dues, etc</Form.Label>
                                    <InputNumber
                                        prefix="$"
                                        className="w-100"
                                        value={property.insurancemaintenancetaxes}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={(e) => {
                                            changeOwnedProperty(e, propertyIndex, 'insurancemaintenancetaxes')
                                        }}
                                        size="large"
                                    />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col xs={12}>
                                    <Form.Check
                                        type="checkbox"
                                        checked={property.propertytype === 'twotofour'}
                                        className="formLabel"
                                        label="This is a 2-4 unit property"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                changeOwnedProperty('twotofour', propertyIndex, 'propertytype')
                                            } else {
                                                changeOwnedProperty(null, propertyIndex, 'propertytype')
                                            }
                                        }}
                                    />
                                </Col>
                            </Form.Row>

                            {(property.occupancy === 'investment' || property.propertytype === 'twotofour') && (
                                <Form.Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Label>Expected monthly rental income</Form.Label>
                                        <InputNumber
                                            prefix="$"
                                            className="w-100"
                                            value={property.grossrentalincome}
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={(e) => {
                                                changeOwnedProperty(String(e), propertyIndex, 'grossrentalincome')
                                            }}
                                            size="large"
                                        />
                                    </Col>
                                </Form.Row>
                            )}

                            <hr style={{ margin: '24px 0', border: 'none', borderTop: '1px solid #DBDCDE' }} />

                            {/* Mortgage Section */}
                            <div>
                                <Form.Label className="property-section-title">
                                    Mortgage on this property
                                </Form.Label>
                                <Form.Row>
                                    <Col xs={12}>
                                        <Form.Check
                                            type="checkbox"
                                            label="I don't have a mortgage on this property."
                                            className="mortgage-checkbox"
                                            checked={isEmpty(property.loans)}
                                            onChange={this.onHaveMortgateForProperty}
                                        />
                                    </Col>
                                </Form.Row>

                                {!isEmpty(property.loans) && property.loans.map(
                                    (loan, index) => this.mortgageLoanForm(property, propertyIndex, index, changeOwnedProperty)
                                )}

                                {!isEmpty(property.loans) && (
                                    <Button
                                        variant="link"
                                        className="p-0 add-additional-mortgage-btn"
                                        onClick={() => {
                                            const additionalLoan = JSON.parse(new st.loan().toJson())
                                            additionalLoan.creditloantype = 'ConventionalRealEstateMortgage'
                                            additionalLoan.key = Math.random().toString()
                                            changeOwnedProperty([...property.loans, additionalLoan], propertyIndex, 'loans')
                                        }}
                                    >
                                        Add additional mortgage to this property
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="zeitro-primary">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        finances: state.application.borrower.finances,
        application: state.application,
        hascoborrower: state.application.hascoborrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateSolarPanels: (payload, verb) => {
        dispatch(act.UpdateSolarPanels(payload, verb))
    },
    copyBorrowerLoan: (p, who) => {
        dispatch(act.CopyBorrowerLoan(p, who))
    },
    clearBorrowerLoans: (event, who) => {
        dispatch(act.ClearBorrowerLoans(event, who))
    },
    changeBorrowerLoan: (payload, index, verb) => {
        dispatch(act.ChangeBorrowerLoan(payload, index, verb))
    },
    changeBorrowerLoanMonthly: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanMonthly(event.target.value, i, who))
    },
    changeBorrowerLoanSatisfiedUpon: (event, i, who) => {
        dispatch(
            act.ChangeBorrowerLoanSatisfiedUpon(event.target.value, i, who)
        )
    },
    changeBorrowerLoanRate: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanRate(event.target.value, i, who))
    },
    changeBorrowerLoanForSubject: (val, i, who) => {
        dispatch(act.ChangeBorrowerLoanForSubject(val, i, who))
    },
    changeBorrowerLoanCorrection: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanCorrection(event.target.value, i, who))
    },
    changeBorrowerLoanExplanation: (event, i, who) => {
        dispatch(act.ChangeBorrowerLoanExplanation(event.target.value, i, who))
    },
    updateFinances: (t, who, verb) => {
        dispatch(act.UpdateFinances(t, who, verb))
    },
    addBorrowerAdditionaCredits: (event, who) => {
        dispatch(act.AddBorrowerAdditionaCredits(event, who))
    },
    clearBorrowerAdditionalCredits: (event, who) => {
        dispatch(act.ClearBorrowerAdditionalCredits(event, who))
    },
    updateJointly: (event) => {
        dispatch(act.UpdateJointly(event.target.checked))
    },
    updateApplicationAttribute: (event, verb) => {
        dispatch(act.UpdateApplicationAttribute(event.target.value, verb))
    },
    updateFico: (score) => {
        dispatch(act.UpdateFico(score))
    },
    updateCRErrors: (ar) => {
        dispatch(act.UpdateCRErrors(ar))
    },
    changeBorrowerInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },
    addBorrowerAdditionalPropertiesFromCredit: (payload) => {
        dispatch(act.AddBorrowerAdditionalPropertiesFromCredit(payload))
    },
    changeReviewedLoans: (value) => {
        dispatch(act.ChangeReviewedLoans(value))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    changeBorrowerFreeAndClear: (payload, who) => {
        dispatch(act.ChangeBorrowerFreeAndClear(payload, who))
    },
    changePaymentType: (event, who, i) => {
        dispatch(act.ChangePaymentType(event.target.value, who, i))
    },
    addBorrowerAdditionalProperty: (event, who) => {
        dispatch(act.AddBorrowerAdditionalProperty(event, who))
    },
    addBorrowerOwnedProperty: (event) => {
        dispatch(act.AddBorrowerOwnedProperty(event))
    },
    addCoborrowerOwnedProperty: (event) => {
        dispatch(act.AddCoborrowerOwnedProperty(event))
    },
    removeOwnedProperty: (i) => {
        dispatch(act.RemoveOwnedProperty(i))
    },
    removeBorrowerOwnedProperty: (i) => {
        dispatch(act.RemoveBorrowerOwnedProperty(i))
    },
    removeCoborrowerOwnedProperty: (i) => {
        dispatch(act.RemoveCoborrowerOwnedProperty(i))
    },
    clearOwnedProperty: () => {
        dispatch(act.ClearOwnedProperty())
    },
    clearCoborrowerOwnedProperty: () => {
        dispatch(act.ClearCoborrowerOwnedProperty())
    },
    clearBorrowerOwnedProperty: () => {
        dispatch(act.ClearBorrowerOwnedProperty())
    },
    changeOwnedProperty: (t, i, verb) => {
        dispatch(act.ChangeOwnedProperty(t, i, verb))
    },
    changeBorrowerOwnedProperty: (t, i, verb) => {
        dispatch(act.ChangeBorrowerOwnedProperty(t, i, verb))
    },
    changeCoborrowerOwnedProperty: (t, i, verb) => {
        dispatch(act.ChangeCoborrowerOwnedProperty(t, i, verb))
    },
    clearBorrowerAdditionalProperty: (event, who) => {
        dispatch(act.ClearBorrowerAdditionalProperty(event, who))
    },
    addNewMortgageLoan: () => {
        dispatch(act.AddNewMortgageLoan())
    },
    removeNewMortgageLoan: (index) => {
        dispatch(act.RemoveNewMortgageLoan(index))
    },
    clearNewMortgageLoans: () => {
        dispatch(act.ClearNewMortgageLoans())
    },
    updateNewMortgageLoanAttribute: (value, index, verb) => {
        dispatch(act.UpdateNewMortgageLoanAttribute(value, index, verb))
    },
    changeBorrowerInfoByValue: (val, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(val, who, verb))
    },
    updateExpenses: (event, who, verb) => {
        dispatch(act.UpdateExpenses(event.target.value, who, verb))
    },
    addAlimony: (event, who) => {
        dispatch(act.AddAlimony(event, who))
    },
    removeAlimony: (i, who) => {
        dispatch(act.RemoveAlimony(i, who))
    },
    clearAlimonies: (event, who) => {
        dispatch(act.ClearAlimonies(event, who))
    },
    changeAlimony: (event, who, i) => {
        dispatch(act.ChangeAlimony(event, who, i))
    },
    changeAlimonyField: (value, verb, who, i) => {
        dispatch(act.ChangeAlimonyField(value, verb, who, i))
    },
})

export class Liabilities extends Component {
    constructor(props) {
        super(props)

        const { who } = this.props
        const { otherloans } = this.props.application
        const { loansreviewed, properties, loans, newloans, ownedproperties } = this.props.application.assetsandliabilities
        const { havesolarpanels } = this.props.application.property.solarpanels
        const { currentfirstmortgage, currentotherfinancing } = this.props.application[who].expenses

        this.state = {
            loadingcr: false,
            show: {},
            usestepper: true,
            status: 'process',
            expand: [],
            stepsandsubsteps: [],
            observedIndex: 0,
            showWarning: false,
            message: '',
            monthlyIncome: 0,
            monthlyDebt: 0,
            preDTI: 0,
            validated: false,
            showPropertyModal: false,
            currentPropertyIndex: 0,
        }

        this.isValidated = this.isValidated.bind(this)

        // see if you need to seed the defaults for
        let firstmortgage = 0
        let otherfinancing = 0
        if (who === com.borrower) {
            if (loans !== null) {
                for (let index = 0; index < loans.length; index++) {
                    const loan = loans[index]
                    // don't want to do this for a coborrower until we see it
                    //if( (loan.attribution & 1) === 0)
                    //    continue
                    if (!loan.creditloantype) continue
                    if (loan.creditloantype.toLowerCase().indexOf("mortgage") >= 0) {
                        if (loan.forsubjectproperty === true) {
                            if (firstmortgage === 0)
                                firstmortgage += parseInt(loan.monthlypayment)
                            else
                                otherfinancing += parseInt(loan.monthlypayment)
                        }
                    } else {
                        if (loan.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
                            loan.creditloantype.toLowerCase().indexOf("realestate") >= 0) {
                            if (loan.forsubjectproperty === true)
                                otherfinancing += parseInt(loan.monthlypayment)
                        }
                    }
                }
            }

            for (let i = 0; (otherloans != null) && (i < otherloans.length); i++) {
                const loan = otherloans[i]
                if (loan.forsubjectproperty === true && loan.satisfiedupon === "withproceeds") {
                    if (firstmortgage === 0)
                        firstmortgage += parseInt(loan.monthlypayment)
                    else
                        otherfinancing += parseInt(loan.monthlypayment)
                }
            }

            setTimeout(() => {
                if (currentfirstmortgage === "") {
                    this.props.updateExpenses({ target: { value: firstmortgage.toString() } }, com.borrower, "currentfirstmortgage")
                    this.props.updateExpenses({ target: { value: "monthly" } }, com.borrower, "currentfirstmortgageperiod")
                    if (currentotherfinancing === "") {
                        this.props.updateExpenses({ target: { value: otherfinancing.toString() } }, com.borrower, "currentotherfinancing")
                        this.props.updateExpenses({ target: { value: "monthly" } }, com.borrower, "currentotherfinancingperiod")
                    }
                }
            }, 100)
        }

        const { alimony } = this.props.application[who].finances
        if (!alimony) {
            this.props.clearAlimonies("", who)
        }

        if (loansreviewed === null) {
            this.props.application.assetsandliabilities.loansreviewed = 0
            this.props.changeReviewedLoans(0)
        }

        this.getDTI()

        if (loans != null) {
            this.state.stepsandsubsteps = this.recalcStepper()
        }

        if (properties === null) {
            this.props.clearBorrowerAdditionalProperty(0, this.props.who)
        }
        if (newloans === null) {
            this.props.clearNewMortgageLoans()
        }
        if (havesolarpanels === null) {
            this.props.updateSolarPanels(false, 'havesolarpanels')
        }

        const { presentaddressownership } = this.props.application[who]
        const propertyNoLoans = !isEmpty(ownedproperties) && ownedproperties[0].loans.length === 0
        if (presentaddressownership === st.O_Own && propertyNoLoans && !isEmpty(loans)) {
            const loansForCurrentResidence = []
            for (const loan of loans) {
                if (loan.forprimaryresidence) {
                    loansForCurrentResidence.push(loan)
                }
            }
            this.props.changeOwnedProperty(loansForCurrentResidence, 0, 'loans')
        }
    }

    recalcStepper = () => {
        const loans = this.getFilteredLoans()
        const dupresolved = null
        const stepsandsubsteps = []
        let steps = -1
        let substeps = 0
        for (let i = 0; i < loans.length; i++) {
            const remapped = loans[i]
            let st
            if (remapped.correction === 'duplicate') {
                substeps += 1
                st = { steps, substeps, dupresolved }
            } else {
                steps += 1
                substeps = 0
                st = { steps, substeps, dupresolved }
            }
            stepsandsubsteps.push(st)
        }

        return stepsandsubsteps
    }

    fillPerson = (who) => {
        const {
            firstname,
            middlename,
            lastname,
            suffix,
            email,
            socialsecurity,
            dateofbirth,
        } = this.props.application[who]

        return {
            firstname: firstname,
            middlename: middlename,
            lastname: lastname,
            suffix: suffix,
            email: email,
            socialsecurity: socialsecurity,
            dateofbirth: dateofbirth,
        }
    }

    fillAddress = (who) => {
        const {
            presentaddressstreet,
            presentaddresscity,
            presentaddressstate,
            presentaddresszip,
            presentaddressyears,
            presentaddressmonths,
            presentaddressownership,
        } = this.props.application[who]

        return {
            street: presentaddressstreet,
            city: presentaddresscity,
            state: presentaddressstate,
            zip: presentaddresszip,
            years: presentaddressyears,
            months: presentaddressmonths,
            ownership: presentaddressownership,
        }
    }

    fillPreviouswAddress = (index, who) => {
        const {
            street,
            city,
            state,
            zip,
            years,
            months,
            ownership,
        } = this.props.application[who].previousaddresses[index]

        return {
            street: street,
            city: city,
            state: state,
            zip: zip,
            years: years,
            months: months,
            ownership: ownership,
        }
    }

    fillApplicant = (who) => {
        let addresses = [this.fillAddress(who)]
        const { previousaddresses } = this.props.application[who]

        for (let i = 0; i < previousaddresses.length; i++) {
            addresses.push(this.fillPreviouswAddress(i, who))
        }

        if (addresses.length > 0 && addresses[0].street === '') {
            addresses = [this.fillAddress(who)]
        }

        return { person: this.fillPerson(who), addresses: addresses }
    }

    fillRequestBody = (who) => {
        const borrowers = [this.fillApplicant(who)]

        return {
            // true for LO, false for borrower
            force_ucs: false,
            debug: 'just_request',
            app_data: borrowers,
            request: 'credit_report',
        }
    }

    load = () => {
        this.props.changeReviewedLoans(0)

        if (
            false &&
            'undefined' === typeof this.props.borrowerid &&
            null !== this.props.application.assetsandliabilities.loans
        ) {
            return
        }

        this['state'].loadingcr = true
        this.setState({ loadingcr: true })

        const token = window.sessionStorage.getItem('ZeitroA')

        let id = com.getUserId()
        if ('undefined' === typeof id) {
            id = this.props.borrowerid
        }

        const body = this.fillRequestBody(this.props.who)

        fetch(window.location.origin + '/borrower/creditreport/' + id, {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: 'Bearer ' + token,
                Cache: 'no-cache',
            },
        })
            .then((response) => {
                response.text().then((text) => {
                    try {
                        const js = JSON.parse(text)
                        this.props.clearBorrowerLoans(null, this.props.who)
                        this.props.updateFico(js.creditscore)
                        if (js.creditscore === '0') {
                            this.props.updateFico('750')
                        }

                        let remap = (o) => {
                            const l = { ...o }
                            const islease = l.creditliabilityaccounttype === 'AutoLease' || l.creditliabilityaccounttype === 'Lease'
                            const isinstallment = l.creditliabilityaccounttype === 'Installment'

                            if (l.creditloantype.toLowerCase().indexOf('education') >= 0) {
                                if (l.monthlypayment === '')
                                    l.monthlypayment = 0
                            } else {
                                if (
                                    (islease || isinstallment) &&
                                    (l.monthlypayment === 0 || l.monthlypayment === '')
                                ) {
                                    l.monthlydefinedbycustomer = true
                                }
                            }

                            return l
                        }

                        const opened = js.liabilities
                        const dupresolved = null
                        const expand = []
                        let steps = -1
                        let substeps = 0
                        const stepsandsubsteps = []
                        for (let i = 0; i < opened.length; i++) {
                            const remapped = remap(opened[i])
                            if (opened[i].remainingbalance === '' || parseInt(opened[i].remainingbalance) === 0)
                                continue

                            this.props.copyBorrowerLoan(remapped, this.props.who)

                            expand.push(false)

                            let st
                            if (remapped.correction === 'duplicate') {
                                substeps += 1
                                st = { steps, substeps, dupresolved }
                            } else {
                                steps += 1
                                substeps = 0
                                st = { steps, substeps, dupresolved }
                            }
                            stepsandsubsteps.push(st)
                        }

                        this.props.addBorrowerAdditionalPropertiesFromCredit(null)
                        getCurrentState()

                        this.setState({ loadingcr: false, expand, stepsandsubsteps })
                        if (typeof js.errorMessages !== 'undefined') {
                            this.props.updateCRErrors(js.errorMessages)
                        }
                    } catch (err) {
                        console.error(err)
                        this.setState({ loadingcr: false })
                    }
                })
            })
            .catch((error) => {
                this.setState({ loadingcr: false })
            })
    }

    getDTI = () => {
        const token = sessionStorage.getItem('ZeitroA')
        fetch('/data/getPreDTI', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                Cache: 'no-cache',
                'Content-Type': 'application/json',
                'X-Borrower': this.props.borrowerid,
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.warn('Bad response, should not happen')
                    return
                }
                response.json().then((pr) => {
                    if (typeof pr['Error'] !== 'undefined') {
                        console.error(pr.Error)
                    } else {
                        if (pr.dtiData.length === 0) {
                            this.setState({ monthlyIncome: 0, monthlyDebt: 0, preDTI: 0 })
                        } else {
                            const income = pr.dtiData[0].monthly_income
                            const debt = pr.dtiData[0].monthly_payment
                            const dti = income === 0 ? 0 : Math.round((debt / income) * 100)
                            this.setState({ monthlyIncome: income, monthlyDebt: debt, preDTI: dti })
                        }
                    }
                })
            })
            .catch((err) => {
                alert('Network error')
            })
    }

    componentDidMount() {
        setTimeout(() => {
            this.completion()
        }, 100)
    }

    componentDidUpdate() {
        this.completion()
    }

    componentWillUnmount() { }

    completion = () => {
        const form = this.props.self.current
        const formElements = form?.elements
        if (formElements && formElements.length === 0) {
            sessionStorage.setItem('progressValue', 100)
            return
        }

        if (formElements && formElements.length !== 0) {
            let totalCount = 0
            let fillNum = 0
            for (let i = 0; i < formElements.length; i++) {
                const element = formElements[i]
                if (element.type !== 'button' && element.type !== 'fieldset' && element.type !== 'submit') {
                    totalCount++
                    if (!isEmpty(element.value)) {
                        fillNum++
                    }
                }
            }
            const progressValue = Math.round((fillNum / totalCount) * 100)
            sessionStorage.setItem('progressValue', progressValue)
        }
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }
        return true
    }

    showCoborrower = () => {
        const { hascoborrower } = this.props
        const { married } = this.props.application
        if (hascoborrower === "withcoborrower" && married)
            return true
        if (hascoborrower === "multiple" && married)
            return true
        return false
    }

    totalExpenses = getTotalExpenses.bind(this)

    updateJointly = (e) => {
        com.touch()
        const f = e.target.value === 'true'
        this.props.updateJointly({ target: { checked: f } })
    }

    processOtherCredits = (val, index) => {
        if (null === val) return ''

        const { altname, creditor, account, key } = val

        return this.renderRow(index, altname, creditor, account, key)
    }

    addOtherCredits = (e) => {
        com.touch()

        const val = e.target.value === 'true'
        if (!val) {
            this.props.clearBorrowerAdditionalCredits(0, this.props.who)
        } else {
            this.props.addBorrowerAdditionaCredits(0, this.props.who)
        }
    }

    updateFinancesCheck = (verb) => {
        return (e) => {
            const val = e.target.value === 'true'
            this.props.updateFinances(val, this.props.who, verb)
        }
    }
    updateFinances = (verb) => {
        return (e) =>
            this.props.updateFinances(e.target.value, this.props.who, verb)
    }

    freeAndClear = (e) => {
        com.touch()
        const t = e === 'true'
        this.props.changeBorrowerFreeAndClear(t)
    }

    filterNonDuplicates = (loan) => {
        return loan.correction !== 'duplicate'
    }

    getFilteredLoans = () => {
        const { loans } = this.props.application.assetsandliabilities
        return loans == null
            ? null
            : loans.filter(this.filterNonDuplicates).filter(isMortgageLoan)
    }
    getUnfilteredIndex = (index) => {
        const { loans } = this.props.application.assetsandliabilities

        let acc = 0
        for (let i = 0; i < loans.length; i++) {
            const loan = loans[i]
            if (isMortgageLoan(loan) && this.filterNonDuplicates(loan)) {
                if (acc === index) return i
                acc += 1
            }
        }
        alert('should not be here')
        return -1
    }

    processSteps = (val, index) => {
        if (null === val) return ''

        const { name, address, accountnumber, monthlypayment, remainingbalance, satisfiedupon, monthslefttopay, key } = val

        return this.renderLoanStep(
            index,
            name,
            address,
            accountnumber,
            monthlypayment,
            remainingbalance,
            monthslefttopay,
            satisfiedupon,
            key
        )
    }
    process = (val, index) => {
        if (null === val) return ''

        const { name, address, accountnumber, monthlypayment, remainingbalance, satisfiedupon, monthslefttopay, key } = val

        return this.renderLoan(
            index,
            name,
            address,
            accountnumber,
            monthlypayment,
            remainingbalance,
            monthslefttopay,
            satisfiedupon,
            key,
            true
        )
    }

    updateApplicationAttribute = (val) => {
        this.props.updateApplicationAttribute(
            { target: { value: val } },
            'loanbalance'
        )
    }

    onFinish = () => {
        const { who } = this.props
        const { completedsteps } = this.props.application[who] || {}

        if (!completedsteps?.liabilities) {
            this.props.changeBorrowerInfo(
                { ...completedsteps, liabilities: true },
                who,
                'completedsteps'
            )
        }
    }

    handleSubmit = (event) => {
        const { presentaddressownership, expenses } = this.props.application[this.props.who]
        if (presentaddressownership === null) {
            alert("Please specify present address ownership!")
            return false
        }
        if (presentaddressownership === st.O_Rent && !expenses.currentrent) {
            alert('Please enter your monthly rent payment!')
            return false
        }

        return true
    }

    processAlimonies = (val, index) => {
        if (null === val) return ""
        const { amount, alimonyorsupport, key } = val
        return this.generateAlimony(index, amount, alimonyorsupport, key)
    }

    generateAlimony = (index, amount, alimonyorsupport, key) => {
        index = parseInt(index)
        const removeAlimony = (event) => {
            com.touch()
            this.props.removeAlimony(index, this.props.who)
        }
        const changeAlimony = (event) => {
            com.touch()
            this.props.changeAlimony(event, this.props.who, index)
        }
        const addAlimony = (event) => {
            com.touch()
            return this.props.addAlimony(event, this.props.who)
        }
        const updateWhat = (event) => {
            com.touch()
            this.props.changePaymentType(event, this.props.who, index)
        }

        const { alimony } = this.props.application[this.props.who].finances

        return (
            <div key={key} className="incard mt-2">
                <Row>
                    <Col>
                        <Form.Row>
                            <Col md={6} xs={12} >
                                <Form.Group controlId="type" className="text-left" size="sm" >
                                    <Form.Label className="text-left" >Payment type</Form.Label>
                                    <div>
                                        <Select
                                            style={{ width: "100%", height: 44 }}
                                            value={alimonyorsupport}
                                            onChange={(e) => { updateWhat({ target: { value: e } }) }}
                                        >
                                            <Select.Option value="alimony">Alimony</Select.Option>
                                            <Select.Option value="childsupport">Child Support</Select.Option>
                                            <Select.Option value="maintenancepayments">Separate Maintenance</Select.Option>
                                            <Select.Option value="jobrelatedexpenses">Job Related Expenses</Select.Option>
                                            <Select.Option value="other">Other</Select.Option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={6} xs={12} style={{ size: "4em" }}>
                                <Form.Group controlId="Amount" className="text-left" size="sm" >
                                    <Form.Label className="text-left" >Monthly amount</Form.Label>
                                    <div>
                                        <DollarInput
                                            value={amount}
                                            onChange={(e) => { changeAlimony(e) }}
                                            pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                        />
                                    </div>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the bank/credit union.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top">
                        {alimony.length < 3 && index === alimony.length - 1 ? (
                            <i className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addAlimony} />
                        ) : (
                            <i className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent" />
                        )}
                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeAlimony} />
                    </Col>
                </Row>
            </div >
        )
    }

    getIcon = (index) => {
        const loans = this.getFilteredLoans()

        const a = loans[index]
        const creditLoanType = a.creditloantype.toLowerCase()
        const mapit = () =>
            creditLoanType.indexOf('mortgage') >= 0
                ? '1'
                : creditLoanType.indexOf('homeequity') >= 0 || creditLoanType.indexOf('realestate') >= 0
                ? '2'
                : creditLoanType.indexOf('education') >= 0
                ? '3'
                : creditLoanType.indexOf('auto') >= 0
                ? '4'
                : creditLoanType.indexOf('card') >= 0 || creditLoanType.indexOf('charge') >= 0
                ? '5'
                : creditLoanType.indexOf('medical') >= 0
                ? '6'
                : creditLoanType.indexOf('collection') >= 0
                ? '7'
                : '8'

        switch (mapit(a)) {
            case '1':
                return <i className="fas fa-home mr-1 blue"></i>
            case '2':
                return <i className="fas fa-house-damage mr-1 blue"></i>
            case '3':
                return <i className="fas fa-graduation-cap mr-1 blue"></i>
            case '4':
                return <i className="fas fa-car-alt mr-1 blue"></i>
            case '5':
                return <i className="fas fa-credit-card mr-1 blue"></i>
            case '6':
                return <i className="fas fa-first-aid mr-1 blue"></i>
            case '7':
                return <i className="fas fa-hand-holding-usd mr-1 blue"></i>
            default:
                return ''
        }
    }

    getSubjAddress = () => {
        const { address, purpose } = this.props.application.property
        if (address === '') {
            if (purpose !== st.POL_Purchase) {
                return "the property you're refinancing"
            } else {
                return "the property you're purchasing"
            }
        }
        return address
    }

    getFlag = (correction) => {
        if (correction === 'paidbybusiness')
            return <i className="fas fa-user-tie ml-2 darkgreen"></i>

        if (correction === 'alreadyclosed')
            return <i className="fa fa-times ml-2 darkgreen"></i>
        if (correction === 'wrongperson')
            return <i className="far fa-hand-paper ml-2 darkred"></i>
        if (correction === 'other')
            return <i className="far fa-flag ml-2 darkred"></i>
        if (correction === 'duplicate')
            return <i className="far fa-clone ml-2 darkred"></i>
        return ''
    }

    renderLoan = (
        index,
        name,
        address,
        accountnumber,
        monthlypayment,
        remainingbalance,
        monthslefttopay,
        satisfiedupon,
        key,
        margin
    ) => {
        index = parseInt(index)
        const loans = this.getFilteredLoans()

        const isHELOC = () => {
            const loan = loans[index]
            return com.isHeloc(loan)
        }
        const handleClose = (e) => {
            const newshow = { ...this.state.show }
            newshow[index] = false
            this.setState({ show: newshow })
        }
        const showCorrect = (e) => {
            const newshow = { ...this.state.show }
            newshow[index] = true
            this.setState({ show: newshow })
        }

        const isPrimary = (trr) => {
            const { purpose } = this.props.application.property
            if (
                purpose !== st.POL_Purchase &&
                (loans[index].forsubjectproperty === true || (trr && loans[index].forsubjectproperty == null))
            ) {
                // see if there is already a loan to refinance
                let alreadyRefinanced = false
                for (let i = 0; i < index; i++) {
                    const loan = loans[i]

                    if (
                        loan.forsubjectproperty &&
                        (loan.satisfiedupon === 'withproceeds' || (trr && loan.satisfiedupon == null))
                    ) {
                        alreadyRefinanced = true
                        break
                    }
                }
                if (alreadyRefinanced) {
                    return false
                }
                return true
            }
            return false
        }

        const changeBorrowerLoanSatisfiedUpon = (event) => {
            com.touch()

            const { purpose } = this.props.application.property
            if (isMortgage() && purpose === st.POL_Refinance && event.target.value === 'withproceeds') {
                // see if there is already a loan to refinance
                let alreadyRefinanced = false
                for (let i = 0; i < index; i++) {
                    const loan = loans[i]
                    if (loan.forsubjectproperty === true && loan.satisfiedupon === 'withproceeds') {
                        alreadyRefinanced = true
                        break
                    }
                }
                if (alreadyRefinanced) {
                    this.setState({ observedIndex: index, showWarning: true })
                    return
                }
            }
            this.props.changeBorrowerLoanSatisfiedUpon(
                event,
                this.getUnfilteredIndex(index),
                this.props.who
            )
        }

        const changeExplanation = (e) => {
            com.touch()
            this.props.changeBorrowerLoanExplanation(
                e,
                this.getUnfilteredIndex(index),
                this.props.who
            )
        }

        const correctionChecked = (e) => {
            com.touch()
            this.props.changeBorrowerLoanCorrection(
                e,
                this.getUnfilteredIndex(index),
                this.props.who
            )
        }

        const changeBorrowerLoanForSubject = (e) => {
            com.touch()
            const val = e.target.value === 'true'

            this.props.changeBorrowerLoanForSubject(
                val,
                this.getUnfilteredIndex(index),
                this.props.who
            )
            if (isPrimary(val)) {
                this.props.changeBorrowerLoanSatisfiedUpon(
                    { target: { value: 'withproceeds' } },
                    this.getUnfilteredIndex(index),
                    this.props.who
                )
            }
            this.getBalancePayment()
        }

        const changeBorrowerLoanForPrimary = (e) => {
            com.touch()
            const val = e.target.value === 'true'

            this.props.changeBorrowerLoan(
                val,
                this.getUnfilteredIndex(index),
                'forprimaryresidence'
            )
        }

        const displayOwnership = () => {
            const borrowerName = `${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`
            const coBorrowerName = `${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname}`
            switch (loans[index].attribution) {
                case 1:
                    return borrowerName
                case 2:
                    return coBorrowerName
                case 3:
                    return 'Joint'
                default:
                    return 'Unknown'
            }
        }

        const onMonthlyChange = (e) => {
            com.touch()
            this.props.changeBorrowerLoanMonthly(
                e,
                this.getUnfilteredIndex(index),
                this.props.who
            )
        }

        const displayMonthly = () => {
            const loans = this.getFilteredLoans()

            const liability = loans[index]
            const userinput = liability.monthlydefinedbycustomer

            if (userinput) {
                return (
                    <Form.Group className="text-left pr-3">
                        <NumericalInput
                            isValid={false}
                            size="sm"
                            id={'loan' + index}
                            type="number"
                            name={'loan' + index}
                            defaultValue={liability.monthlypayment}
                            onChange={onMonthlyChange}
                            min={1}
                        />
                        <Form.Control.Feedback type="invalid">
                            Enter the monthly payment
                        </Form.Control.Feedback>
                    </Form.Group>
                )
            }
            return '$' + com.commaize(loans[index].monthlypayment)
        }

        const askHowToPay = () => {
            if (parseInt(loans[index].remainingbalance) > 0) {
                if (loans[index].name === 'AMEX') {
                    if (loans[index].satisfiedupon) {
                        changeBorrowerLoanSatisfiedUpon({
                            target: { value: 'withownfunds' },
                        })
                    }

                    return (
                        <div>
                            <div className="sectionquestion">
                                <i className="fas fa-angle-right"></i>Will you
                                pay the balance before this transaction?
                            </div>
                            <Form.Group>
                                <fieldset>
                                    <div className="d-flex mb-0">
                                        <div className="ml-2 mr-3 ">
                                            <input
                                                required
                                                onChange={changeBorrowerLoanSatisfiedUpon}
                                                value="withownfunds"
                                                checked={loans[index].satisfiedupon === 'withownfunds'}
                                                type="radio"
                                                id={'satisfiedown' + index}
                                                name={'satisfied' + index}
                                            />
                                            <label
                                                id={'satisfieddownlabel' + index}
                                                className="zeitro-radio divlink"
                                                htmlFor={'satisfiedown' + index}
                                            >
                                                Yes, I will
                                            </label>
                                        </div>
                                        <div className="ml-2 mr-3 ">
                                            <input
                                                required
                                                onChange={changeBorrowerLoanSatisfiedUpon}
                                                value="not"
                                                checked={loans[index].satisfiedupon === 'withproceeds'}
                                                type="radio"
                                                id={'satisfied' + index}
                                                name={'satisfied' + index}
                                            />
                                            <label
                                                id={'satisfiedlabel' + index}
                                                className="zeitro-radio divlink"
                                                htmlFor={'satisfied' + index}
                                            >
                                                No, I won't
                                            </label>
                                        </div>
                                    </div>
                                </fieldset>
                                <Form.Control.Feedback type="invalid">
                                    Please make your choice.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    )
                }

                const canPayoffWithLoanProceeds = () => {
                    const { purpose } = this.props.application.property
                    const loans = this.getFilteredLoans()

                    if (purpose === st.POL_Cashoutrefinance)
                        return true
                    if (purpose === st.POL_Purchase)
                        return false

                    if (isHELOC()) {
                        if (purpose === st.POL_Refinance && false === loans[index].partoforiginalpurchase)
                            return false
                    }

                    // refinance /cashout left
                    if (loans[index].forsubjectproperty === true) return true
                    if (loans[index].partoforiginalpurchase) return true
                    return false
                }

                const paidOff = () => {
                    return (
                        <Form.Label>
                            Will this balance be paid off at the time of (or before) this transaction?{' '}
                            <Tips
                                content={
                                    <div>
                                        This question should be answered having several things in mind:
                                        <ul>
                                            <li>
                                                Are you refinancing this loan?  Answer "Yes, I'm refinancing this loan"
                                            </li>
                                            <li>Are you paying the loan back? For example, you're selling your previous residence. Answer: "Yes, I'm it paying it off myself ".</li>
                                            <li>Closing some credit card balances can also be helpful in lowering your debt payments, and improving your odds of getting qualified for a new loan.</li>
                                            <li>Answer No, if you're keeping the loan as is</li>
                                        </ul>
                                    </div>
                                }
                            />
                        </Form.Label>
                    )
                }

                if (!showBalancePaidOff()) return ''

                return (
                    <div>
                        <div className="sectionquestion d-inline-block">
                            {paidOff()}
                        </div>
                        <Form.Group>
                            <fieldset>
                                <div className="d-flex mb-0">
                                    {canPayoffWithLoanProceeds() && (
                                        <div className="ml-2 mr-3 ">
                                            <input
                                                required
                                                onChange={changeBorrowerLoanSatisfiedUpon}
                                                value="withproceeds"
                                                checked={loans[index].satisfiedupon === 'withproceeds'}
                                                type="radio"
                                                id={'satisfied' + index}
                                                name={'satisfied' + index}
                                            />
                                            <label
                                                id={'satisfiedlabel' + index}
                                                className="zeitro-radio divlink"
                                                htmlFor={'satisfied' + index}
                                            >
                                                {isPrimary() ? "Yes, I'm refinancing this loan" : "Yes, I'm paying it off with the loan proceeds"}
                                            </label>
                                        </div>
                                    )}
                                    <div className="ml-2 mr-3 ">
                                        <input
                                            required
                                            onChange={changeBorrowerLoanSatisfiedUpon}
                                            value="withownfunds"
                                            checked={loans[index].satisfiedupon === 'withownfunds'}
                                            type="radio"
                                            id={'satisfiedown' + index}
                                            name={'satisfied' + index}
                                        />
                                        <label
                                            id={'satisfiedownlabel' + index}
                                            className="zeitro-radio zeitro-radio divlink"
                                            htmlFor={'satisfiedown' + index}
                                        >
                                            Yes, I'm paying it off myself
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            required
                                            onChange={changeBorrowerLoanSatisfiedUpon}
                                            value="not"
                                            checked={loans[index].satisfiedupon === 'not'}
                                            type="radio"
                                            id={'not' + index}
                                            name={'satisfied' + index}
                                        />
                                        <label
                                            id={'notlabel' + index}
                                            className="zeitro-radio ivlink"
                                            htmlFor={'not' + index}
                                        >
                                            No, I won't
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                            <Form.Control.Feedback type="invalid">
                                Please make your choice.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                )
            }

            return ''
        }

        const changePropertyAddress = (event) => {
            com.touch()
            this.props.changeBorrowerLoan(
                event.target.value,
                this.getUnfilteredIndex(index),
                'propertyaddress'
            )
        }

        const isMortgage = () => {
            const creditLoanType = loans[index].creditloantype.toLowerCase()
            return (
                creditLoanType.indexOf('mortgage') >= 0 ||
                creditLoanType.indexOf('homeequity') >= 0 ||
                creditLoanType.indexOf('realestate') >= 0
            )
        }

        const correction = loans[index].correction

        let crossed = ''
        if (['wrongperson', 'alreadyclosed', 'other', 'duplicate'].includes(correction))
            crossed = ' crisscrossed'
        if (correction === 'paidbybusiness' || correction === 'alreadyclosed')
            crossed = ' greencrisscrossed'

        let mt5 = ' mt-4'
        if (index === 0) mt5 = ''

        const onRateChange = (e) => {
            com.touch()
            this.props.changeBorrowerLoanRate(
                e,
                this.getUnfilteredIndex(index),
                this.props.who
            )
        }

        const changePartOfOriginal = (e) => {
            const r = e.target.value === 'true'
            this.props.changeBorrowerLoan(
                r,
                this.getUnfilteredIndex(index),
                'partoforiginalpurchase'
            )
        }

        const showAddress = () => {
            if (!isMortgage()) return false

            const { purpose } = this.props.application.property
            const { financingsameproperty } = this.props.application
            if (
                purpose !== st.POL_Purchase &&
                false === loans[index].forsubjectproperty &&
                financingsameproperty
            )
                return true

            if (purpose === st.POL_Purchase && false === loans[index].forprimaryresidence)
                return true

            if (
                purpose !== st.POL_Purchase &&
                false === financingsameproperty &&
                false === loans[index].forprimaryresidence
            )
                return true

            return false
        }

        const showBalancePaidOff = () => {
            if (isMortgage()) {
                const { forsubjectproperty, forprimaryresidence } = loans[index]
                if (forsubjectproperty == null && forprimaryresidence == null)
                    return false

                const { purpose } = this.props.application.property
                if (purpose !== st.POL_Purchase && forsubjectproperty)
                    return false

                return true
            }
            return true
        }

        let style = {}
        if (loans[index].forsubjectproperty === true && crossed === '') {
            style = {
                backgroundImage: 'url("/housemoney.png")',
                position: 'relative',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom 0px right 50px',
                backgroundSize: 'auto 100%',
            }
        }

        const theRest = () => {
            const ital = 'ml-3 font-weight-light'
            switch (loans[index].correction) {
                case 'duplicate':
                    return (
                        <div className={ital}>
                            This entry is a duplicate, and won't be counted
                            against your debt
                        </div>
                    )
                case 'wrongperson':
                    return (
                        <div className={ital}>
                            This entry does not belong to you, and won't be
                            counted against your debt
                        </div>
                    )
                case 'alreadyclosed':
                    return (
                        <div className={ital}>
                            This loan is already closed, and won't be counted
                            against your debt
                        </div>
                    )
                case 'paidbybusiness':
                    return (
                        <div className={ital}>
                            This loan is paid by business, and won't be counted
                            against your debt
                        </div>
                    )
                case 'other':
                    return (
                        <div className={ital}>
                            This loan won't be counted against your debt
                        </div>
                    )
                default:
                    break
            }

            if (crossed !== '') return ''

            const showQuestionForPrimary = () => {
                if (this.props.application.property.purpose === st.POL_Purchase)
                    return ''
                // only refinance
                if (false !== loans[index].forsubjectproperty) return ''
                if (false !== this.props.application.financingsameproperty)
                    return ''

                return askPrimary()
            }

            const askPrimary = () => {
                return (
                    <div>
                        <Row className="">
                            <Col>
                                <Form.Label>
                                    Is this a loan for {com.getPrimaryAddress(this.props.who, this.props.application)}?
                                </Form.Label>
                                <div className="d-flex mb-0">
                                    <div className="ml-2 mr-3 ">
                                        <input
                                            required
                                            onChange={changeBorrowerLoanForPrimary}
                                            value="true"
                                            checked={true === loans[index].forprimaryresidence}
                                            type="radio"
                                            id={'forprimary' + index}
                                            name={'forsforprimaryubject' + index}
                                        />
                                        <label
                                            id={'forprimarylabel' + index}
                                            className="zeitro-radio divlink"
                                            htmlFor={'forprimary' + index}
                                        >
                                            Yes, it is
                                        </label>
                                    </div>
                                    <div className="ml-2 mr-3 ">
                                        <input
                                            required
                                            onChange={changeBorrowerLoanForPrimary}
                                            value="false"
                                            checked={false === loans[index].forprimaryresidence}
                                            type="radio"
                                            id={'forprimarynot' + index}
                                            name={'forsforprimaryubject' + index}
                                        />
                                        <label
                                            id={'forprimarynotlabel' + index}
                                            className="zeitro-radio divlink"
                                            htmlFor={'forprimarynot' + index}
                                        >
                                            No, it isn't
                                        </label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )
            }

            const { purpose } = this.props.application.property
            const { forsubjectproperty, rate, propertyaddress, partoforiginalpurchase } = loans[index]

            return (
                <div>
                    {purpose !== st.POL_Purchase && isMortgage() && (
                        <Row className="">
                            <Col>
                                <Form.Label>
                                    Is this a loan for {this.getSubjAddress()}?
                                </Form.Label>
                                <div className="d-flex mb-0">
                                    <div className="ml-2 mr-3 ">
                                        <input
                                            required
                                            onChange={changeBorrowerLoanForSubject}
                                            value="true"
                                            checked={forsubjectproperty === true}
                                            type="radio"
                                            id={'forsubject' + index}
                                            name={'forsubject' + index}
                                        />
                                        <label
                                            id={'forsubjectlabel' + index}
                                            className="zeitro-radio divlink"
                                            htmlFor={'forsubject' + index}
                                        >
                                            Yes, it is
                                        </label>
                                    </div>
                                    <div className="ml-2 mr-3 ">
                                        <input
                                            required
                                            onChange={changeBorrowerLoanForSubject}
                                            value="false"
                                            checked={forsubjectproperty === false}
                                            type="radio"
                                            id={'forsubjectnot' + index}
                                            name={'forsubject' + index}
                                        />
                                        <label
                                            id={'forsubjectnotlabel' + index}
                                            className="zeitro-radio divlink"
                                            htmlFor={'forsubjectnot' + index}
                                        >
                                            No, it isn't
                                        </label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}

                    {showQuestionForPrimary()}

                    {forsubjectproperty === true && purpose !== st.POL_Purchase && !isHELOC() && isPrimary() && (
                        <Row>
                            <Col>
                                <Form.Group controlId={'liability' + index} className="text-left">
                                    <Form.Label>
                                        {' '}
                                        What is your current rate?{' '}
                                    </Form.Label>

                                    <InputGroup style={{ maxWidth: '15em' }}>
                                        <Form.Control
                                            isValid={false}
                                            size="sm"
                                            className="ml-1"
                                            required
                                            name={'liability' + index}
                                            type="text"
                                            defaultValue={rate}
                                            onChange={onRateChange}
                                            style={{ width: '4em' }}
                                            pattern="^[0-9]+\.[0-9]+$"
                                            placeholder="XX.XX"
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text style={{ fontSize: '1.1em' }}>
                                                %
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>

                                    <div>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide description.
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    )}

                    <div className="mx-3">
                        {showAddress() && (
                            <AddressAutocomplete
                                isValid={false}
                                size="sm"
                                required
                                label="Provide property address"
                                id={'propertyaddress' + index}
                                type="text"
                                defaultValue={propertyaddress}
                                onChange={changePropertyAddress}
                                pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                goodfeedback=""
                                badfeedback="Please provide property raddress."
                            />
                        )}
                    </div>

                    {forsubjectproperty === true && isHELOC() && purpose === st.POL_Refinance && (
                        <Row className="">
                            <Col>
                                <div className="sectionquestion">
                                    <i className="fas fa-angle-right"></i>Was
                                    this HELOC a part of the original purchase?
                                </div>
                                <div className="d-flex mb-0">
                                    <div className="ml-2 mr-3 ">
                                        <input
                                            required
                                            onChange={changePartOfOriginal}
                                            value="true"
                                            checked={partoforiginalpurchase === true}
                                            type="radio"
                                            id={'partoforiginalpurchase' + index}
                                            name={'partoforiginalpurchase' + index}
                                        />
                                        <label
                                            id={'partoforiginalpurchaselabel' + index}
                                            className="zeitro-radio divlink"
                                            htmlFor={'partoforiginalpurchase' + index}
                                        >
                                            Yes, it was
                                        </label>
                                    </div>
                                    <div className="ml-2 mr-3 ">
                                        <input
                                            required
                                            onChange={changePartOfOriginal}
                                            value="false"
                                            checked={partoforiginalpurchase === false}
                                            type="radio"
                                            id={'partoforiginalpurchasenot' + index}
                                            name={'partoforiginalpurchase' + index}
                                        />
                                        <label
                                            id={'partoforiginalpurchasenotlabel' + index}
                                            className="zeitro-radio divlink"
                                            htmlFor={'partoforiginalpurchasenot' + index}
                                        >
                                            No, it wasn't
                                        </label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}

                    <Row className="">
                        <Col>{askHowToPay()}</Col>
                    </Row>
                </div>
            )
        }

        if (!margin) {
            mt5 = ''
        }

        const toggleShow = () => {
            const expand = this.state.expand
            expand[index] = !expand[index]
            this.setState({ expand })

            var content = document.getElementById('card' + index)
            if (!expand[index]) {
                content.style.maxHeight = '0px'
            } else {
                content.style.maxHeight = 2 * content.scrollHeight + 'px'
            }
        }

        const askAboutDuplicate =
            !margin &&
            loans[index].correction === 'duplicate' &&
            this.state.stepsandsubsteps[index].dupresolved == null

        const askDupe = () => {
            if (index === 0) return 'should not see this'

            const onDupe = (e) => {
                const val = e.target.value === 'true'
                const stepsandsubsteps = this.state.stepsandsubsteps
                stepsandsubsteps[index].dupresolved = val
                this.setState({ stepsandsubsteps })

                if (!val) {
                    com.touch()
                    this.props.changeBorrowerLoanCorrection(
                        { target: { value: 'none' } },
                        this.getUnfilteredIndex(index),
                        this.props.who
                    )
                    setTimeout(() => {
                        let stepsandsubsteps = this.recalcStepper()
                        this.setState({ stepsandsubsteps })
                    }, 1000)
                }
            }

            const { dupresolved } = this.state.stepsandsubsteps[index]

            return (
                <div className="ml-2">
                    <div className="_sectionquestion">
                        This entry looks like a duplicate of the previous one:{' '}
                        {loans[index - 1].name}, acc.{' '}
                        {loans[index - 1].accountnumber}, balance: $
                        {com.commaizeFloat(loans[index - 1].remainingbalance)}
                        <br />
                        Could you please confirm?
                    </div>
                    <Form.Group>
                        <fieldset>
                            <div className="d-flex  mt-2">
                                <div className="mr-3">
                                    <input
                                        required
                                        onChange={onDupe}
                                        value={true}
                                        checked={dupresolved === true}
                                        type="radio"
                                        id="dupe"
                                        name="dupe"
                                    />
                                    <label
                                        className="zeitro-radio divlink"
                                        htmlFor="dupe"
                                    >
                                        Yes, this is a duplicate
                                    </label>
                                </div>
                                <div>
                                    <input
                                        required
                                        onChange={onDupe}
                                        value={false}
                                        checked={dupresolved === false}
                                        type="radio"
                                        id="dupenot"
                                        name="dupe"
                                    />
                                    <label className="zeitro-radio divlink" htmlFor="dupenot">
                                        No, it is not
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                    </Form.Group>
                </div>
            )
        }

        const switchCashout = () => {
            this.setState({ showWarning: false })
            this.props.changeBorrowerLoanSatisfiedUpon(
                { target: { value: 'withproceeds' } },
                this.state.observedIndex,
                this.props.who
            )
            this.props.changeMainPropertyPurpose({
                target: { value: st.POL_Cashoutrefinance },
            })
        }

        const originalPurchase = () => {
            this.setState({ showWarning: false })

            this.props.changeBorrowerLoanSatisfiedUpon(
                { target: { value: 'withproceeds' } },
                this.state.observedIndex,
                this.props.who
            )
            this.props.changeBorrowerLoan(
                true,
                this.getUnfilteredIndex(index),
                'partoforiginalpurchase'
            )
        }

        return (
            <div
                key={key}
                className={
                    loans[index].mannerofpaymentcode !== 'C'
                        ? ' red-shadow  text-wrap' + crossed + mt5
                        : '  drop-shadow  text-wrap' + crossed + mt5
                }
                style={style}
            >
                <Modal show={this.state.showWarning} onHide={() => {this.setState({ showWarning: false })}}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h4>Tell us more about these loans</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="py-3">
                        <div style={{ minWidth: '400px' }}>
                            You indicated that you'd like to refinance more than one loan.
                        </div>
                        <div className="mt-4">This is possible within the limits of rate/term refinance only if these loans were obtained together as a part of a purchase transaction.</div>
                        <div className="mt-4">Otherwise, we can switch to a cash out refinance. Your rates might be higher. Note, that you can always go back and change your choice.</div>
                        <form name={'doo' + index}></form>
                        <Row className="mt-5">
                            <Col className="text-center">
                                <Button onClick={switchCashout}>
                                    Switch to cash out refinance
                                </Button>
                            </Col>
                            <Col className="text-center">
                                <Button onClick={originalPurchase}>
                                    The loans were part of original purchase
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.show[index]}
                    onHide={handleClose}
                    size="sm"
                    // aria-labelledby="contained-modal-title-vcenter"
                    // centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h4>Does this liability need a correction?</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="py-3">
                        <form name={'corr' + index}>
                            <div className="mt-2 ">
                                <Form.Control name="purpose" as="select" value={correction} onChange={correctionChecked}>
                                    <option value="none">No correction needed</option>
                                    <option value="wrongperson">This is not my account!</option>
                                    <option value="alreadyclosed">This account has been paid in full and closed</option>
                                    <option value="paidbybusiness">Debt paid by business</option>
                                    <option value="duplicate">Duplicate entry</option>
                                    <option value="other">Other</option>
                                </Form.Control>
                            </div>
                            {correction !== 'none' && correction !== 'duplicate' && (
                                <div className="mt-4">
                                    <div>Please enter an explanation:</div>
                                    <textarea
                                        rows="5"
                                        onChange={changeExplanation}
                                        className="w-100"
                                        value={loans[index].explanation}
                                    ></textarea>
                                    <div
                                        style={{
                                            fontStyle: 'italic',
                                            fontSize: '0.8em',
                                        }}
                                    >
                                        Documentation is required to support
                                        this explanation
                                    </div>
                                </div>
                            )}
                            <Row className="mt-5">
                                <Col className="text-center">
                                    <Button variant="success" onClick={handleClose}>
                                        Close
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </Modal.Body>
                </Modal>

                <Row className="mt-0 pt-2 bluishbackground">
                    <Col xs>
                        <div style={{ fontSize: '1.2em' }} className="sectionquestion">
                            {this.getIcon(index)}
                            {com.unCamelCase(loans[index].creditloantype)}
                            {this.getFlag(correction)}
                        </div>
                    </Col>
                    {margin && (
                        <Col xs="auto">
                            <i
                                className={
                                    this.state.expand[index]
                                        ? 'fa fa-arrow-circle-up downarrow'
                                        : 'fa fa-arrow-circle-down downarrow'
                                }
                                onClick={toggleShow}
                                aria-hidden="true"
                            ></i>
                        </Col>
                    )}
                </Row>
                <div className="">
                    <Row className="bluishbackground">
                        <Col xs="auto">
                            <div>Lender:</div>
                            <div>{loans[index].name}</div>
                        </Col>
                        <Col xs="auto">
                            <div>Account number:</div>
                            <div>{loans[index].accountnumber}</div>
                        </Col>

                        <Col xs="auto">
                            <div>Balance:</div>
                            <div>
                                ${com.commaize(loans[index].remainingbalance)}
                            </div>
                        </Col>
                        <Col xs="auto">
                            <div>Mo. Payment:</div>
                            <div>{displayMonthly()}</div>
                        </Col>

                        <Col xs="auto">
                            <div>Mo. left:</div>
                            <div>
                                {Number.isInteger(parseInt(loans[index].monthslefttopay)) ? loans[index].monthslefttopay : 'N/A'}
                            </div>
                        </Col>

                        <Col xs="auto" className="">
                            Ownership
                            <br />
                            {displayOwnership()}
                        </Col>

                        {loans[index].currentratingtype !== 'AsAgreed' && (
                            <Col xs="auto" className="text-wrap">
                                <div style={{ fontWeight: '700', color: '#660000' }} className="text-wrap">
                                    {com.unCamelCase(loans[index].currentratingtype)}
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
                <div className={!margin ? '' : 'cardbody'} id={'card' + index}>
                    {askAboutDuplicate ? (
                        askDupe()
                    ) : (
                        <div className="">
                            <Row className="">
                                <Col sm="auto" className="d-flex pl-2 py-0 text-nowrap">
                                    <Button
                                        onClick={showCorrect}
                                        style={{ color: '#b91c1c' }}
                                        className="text-wrap mt-1 p-1"
                                        variant="link"
                                    >
                                        This record needs correction
                                        <Tips
                                            content={
                                                <div>
                                                    Click on this link if
                                                    <ul>
                                                        <li>
                                                            This loan does not belong to you
                                                        </li>
                                                        <li>
                                                            It has been paid in full
                                                        </li>
                                                        <li>
                                                            The debt has been paid by business
                                                        </li>
                                                        <li>
                                                            This is a duplicate entry (happens quite often!)
                                                        </li>{' '}
                                                    </ul>
                                                    In this case the liability won't be counted, but you will have to supply supporting documents.
                                                </div>
                                            }
                                            placement="right"
                                            title="Correct my mortgage loans"
                                        />
                                    </Button>
                                </Col>
                            </Row>
                            {askAboutDuplicate ? askDupe() : theRest()}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    checkForPrimary = (index) => {
        if (this.props.application.property.purpose !== st.POL_Purchase)
            return true

        const loans = this.getFilteredLoans()

        if (loans[index].forprimaryresidence === true) return true

        const adr = loans[index].propertyaddress

        const parsed = parser.parseLocation(adr)
        const subj = parser.parseLocation(this.getSubjAddress())
        if (adr === '') return true

        const ret =
            parsed.city.toLowerCase() === subj.city.toLowerCase() &&
            parsed.number.toLowerCase() === subj.number.toLowerCase() &&
            parsed.state.toLowerCase() === subj.state.toLowerCase() &&
            parsed.street.toLowerCase() === subj.street.toLowerCase()

        return !ret
    }

    checkForSubject = (index) => {
        if (this.props.application.property.purpose === st.POL_Purchase)
            return true

        const loans = this.getFilteredLoans()

        if (loans[index].forsubjectproperty === true) return true

        const adr = loans[index].propertyaddress

        const parsed = parser.parseLocation(adr)
        const subj = parser.parseLocation(this.getSubjAddress())
        if (adr === '') return true

        const ret =
            parsed.city.toLowerCase() === subj.city.toLowerCase() &&
            parsed.number.toLowerCase() === subj.number.toLowerCase() &&
            parsed.state.toLowerCase() === subj.state.toLowerCase() &&
            parsed.street.toLowerCase() === subj.street.toLowerCase()

        return !ret
    }

    renderLoanStep = (
        index,
        name,
        address,
        accountnumber,
        monthlypayment,
        remainingbalance,
        monthslefttopay,
        satisfiedupon,
        key
    ) => {
        index = parseInt(index)
        const loans = this.getFilteredLoans()

        const { loansreviewed } = this.props.application.assetsandliabilities

        const click = () => {
            if (index >= loansreviewed)
                return

            this.props.changeReviewedLoans(index)
        }

        const onBack = (e) => {
            this.props.changeReviewedLoans(loansreviewed - 1)
            this.setState({ status: 'process' })
            this.props.clearValidate()
            document.getElementById('card' + (index - 1)).scrollIntoView({ behavior: 'smooth' })
        }

        const onDone = (e) => {
            // if (this.props.checkValidate(e)) {
            if (!this.checkForSubject(index)) {
                alert("The address provided is for the property that you're refinancing!")
                this.setState({ status: 'error' })
                return false
            }
            if (!this.checkForPrimary(index)) {
                alert("The address provided is for the property that you're purchasing!")
                this.setState({ status: 'error' })
                return false
            }
            this.setState({ status: 'process' })
            this.props.changeReviewedLoans(loansreviewed + 1)
        }

        let grayifneeded = ''
        if (index > loansreviewed)
            grayifneeded = 'graytext'

        const correction = loans[index].correction
        const dd = (
            <div className="mt-0  w-100">
                {index === loansreviewed ? (
                    <div id={'card' + index}>
                        <Row>
                            <Col xs>
                                {this.renderLoan(
                                    index,
                                    name,
                                    address,
                                    accountnumber,
                                    monthlypayment,
                                    remainingbalance,
                                    monthslefttopay,
                                    satisfiedupon,
                                    key,
                                    false
                                )}
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs="auto">
                                <Button
                                    variant="zeitro-outline"
                                    onClick={onDone}
                                >
                                    This record looks good to me
                                </Button>
                            </Col>
                            <Col xs>
                                {loansreviewed > 0 && (
                                    <Button variant="zeitro-outline" onClick={onBack}>
                                        Back
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <Row id={'card' + index} className={grayifneeded}>
                        <Col xs="auto">
                            <div>
                                {this.getIcon(index)}
                                {com.unCamelCase(loans[index].creditloantype)}
                                {this.getFlag(correction)}
                            </div>
                        </Col>
                        <Col xs>{loans[index].name}</Col>
                    </Row>
                )}
            </div>
        )

        const tt = ''
        const dup = loans[index].correction === 'duplicate'

        return (
            <Steps.Step
                sm
                title={dd}
                onClick={click}
                description={tt}
                subStep={dup}
                secondaryCurrent={dup}
            />
        )
    }

    nbsp = () => {
        return (
            <div className="mt-4">
                &nbsp;
                <br />
                &nbsp;
            </div>
        )
    }

    renderStepper = () => {
        const { loansreviewed, loans } = this.props.application.assetsandliabilities
        const { stepsandsubsteps } = this.state
        if (stepsandsubsteps.length === 0) return ''

        const current = loansreviewed == null ? 0 : loansreviewed
        const inx = stepsandsubsteps[current].steps
        const ss = stepsandsubsteps[current].substeps

        return (
            <div id="liabilitystepper">
                <div className="prequalInterviewH3 text-wrap mt-4">
                    Please review your existing mortgages and mark the ones that are already paid off or invalid by clicking on the Needs Correction link
                </div>
                <Steps
                    status={this.state.status}
                    direction="vertical"
                    secondaryCurrent={ss}
                    current={inx}
                    className="text-left"
                >
                    {loans.filter(this.filterNonDuplicates).filter(isMortgageLoan).map(this.processSteps)}
                </Steps>
            </div>
        )
    }

    renderCore = () => {
        const filteredLoans = this.getFilteredLoans()
        if (filteredLoans == null) {
            return ''
        }

        const { loans, loansreviewed } = this.props.application.assetsandliabilities
        if (loans !== null && loans.length === 1 && loans[0].name === 'Estimated') {
            if (loansreviewed === 0) {
                this.props.changeReviewedLoans(1)
            }
            return ''
        }

        if (loansreviewed == null || loansreviewed < filteredLoans.length) {
            return this.renderStepper()
        } else {
            return (
                <div className="pt-2">
                    {loans && loans.filter(this.filterNonDuplicates).filter(isMortgageLoan).map(this.process)}
                </div>
            )
        }
    }

    onStreetAddressCompletion = (address, index, who) => {
        const num = com.getSubaddress(address, 'street_number', 'long_name')
        const city = com.getSubaddress(address, 'locality', 'long_name')
        const zip = com.getSubaddress(address, 'postal_code', 'long_name')
        const county = com.getSubaddress(address, 'administrative_area_level_2', 'long_name')
        const longstate = com.getSubaddress(address, 'administrative_area_level_1', 'long_name')
        const short = com.getSubaddress(address, 'route', 'short_name')
        const stradd = num + ' ' + short

        const changeOwnedProperty = who === com.borrower ? this.props.changeBorrowerOwnedProperty : this.props.changeCoborrowerOwnedProperty
        changeOwnedProperty(stradd, index, 'address')
        changeOwnedProperty(longstate, index, 'state')
        changeOwnedProperty(county, index, 'county')
        changeOwnedProperty(city, index, 'city')
        changeOwnedProperty(zip, index, 'zipcode')
    }

    addOtherProperties = (e, who) => {
        com.touch()

        const val = e === 'true'
        const { presentaddressownership, ownedproperties } = this.props.application[who]
        const addOwnedProperty = who === com.borrower ? this.props.addBorrowerOwnedProperty : this.props.addCoborrowerOwnedProperty
        const removeOwnedProperty = who === com.borrower ? this.props.removeBorrowerOwnedProperty : this.props.removeCoborrowerOwnedProperty
        const clearOwnedProperty = who === com.borrower ? this.props.clearBorrowerOwnedProperty : this.props.clearCoborrowerOwnedProperty
        const changeOwnedProperty = who === com.borrower ? this.props.changeBorrowerOwnedProperty : this.props.changeCoborrowerOwnedProperty

        if (val) {
            if (ownedproperties.length > 1) return
            addOwnedProperty()
            const additionalLoan = JSON.parse(new st.loan().toJson())
            additionalLoan.creditloantype = 'ConventionalRealEstateMortgage'
            additionalLoan.key = Math.random().toString()
            const index = presentaddressownership === st.O_Own ? 1 : 0
            changeOwnedProperty([additionalLoan], index, 'loans')
            this.setState({ showPropertyModal: true, currentPropertyIndex: index })
        } else {
            if (presentaddressownership === st.O_Own) {
                for (let i = 1; i < ownedproperties.length; i++) {
                    removeOwnedProperty(i)
                }
            } else {
                clearOwnedProperty()
            }
        }
    }

    processAdditionalRealEstate = (val, index, who) => {
        if (null === val) return <></>

        const {
            ownedproperties,
            presentaddressstreet,
            presentaddressstate,
            presentaddresscity,
            presentaddresszip,
        } = this.props.application[who]

        const {
            address = presentaddressstreet,
            city = presentaddresscity,
            state = presentaddressstate,
            zipcode = presentaddresszip,
            marketvalue,
            status,
            occupancy,
            insurancemaintenancetaxes,
        } = ownedproperties[index]

        const editProperty = (index) => {
            this.setState({ showPropertyModal: true, currentPropertyIndex: index })
        }

        const getStatusText = (value) => {
            const statusOptions = [
                { value: 'sold', label: 'Sold' },
                { value: 'pending', label: 'Pending Sale' },
                { value: 'retained', label: 'Retained' },
            ]
            const option = statusOptions.find(item => item.value === value)
            return option ? option.label : 'N/A'
        }

        const getOccupancyText = (value) => {
            const occupancyOptions = [
                { value: 'principal', label: 'Primary Residence' },
                { value: 'secondhome', label: 'Second Home' },
                { value: 'investment', label: 'Investment' },
                { value: 'other', label: 'Other' },
            ]
            const option = occupancyOptions.find(item => item.value === value)
            return option ? option.label : 'N/A'
        }

        const headerText = () => {
            return [address, city, state, zipcode].filter(Boolean).join(', ')
        }

        const { loans } = this.props.application[who].ownedproperties[index]

        const getCreditLoanTypeText = (value) => {
            const creditLoanTypeOptions = [
                { value: 'ConventionalRealEstateMortgage', label: 'Conventional real estate mortgage' },
                { value: 'FHARealEstateMortgage', label: 'FHA real estate mortgage' },
                { value: 'VeteransAdministrationRealEstateMortgage', label: 'Veterans administration real estate mortgage' },
                { value: 'USDA-RD', label: 'USDA-RD' },
                { value: 'Other', label: 'Other' },
            ]
            const option = creditLoanTypeOptions.find(item => item.value === value)
            return option ? option.label : 'N/A'
        }

        const paidOffAtClosing = (value) => {
            if (value === 'yes') {
                return 'Yes'
            } else if (value === 'no') {
                return 'No'
            }
            return ''
        }

        return (
            <div key={val.key} className="employment-list mt-2">
                <div className="employment-item">
                    {/* Header Section */}
                    <div className="employment-top">
                        <div className="employment-top-l">
                            <div className="employment-icon">
                                <img src="/images/property-icon.svg" alt='property' />
                            </div>
                            <span className="employment-name" style={{color: '#222222'}}>
                                {headerText()}
                            </span>
                        </div>
                        <div className="employment-top-r">
                            <div className="property-edit-button" onClick={() => editProperty(index)}>
                                Edit
                            </div>
                        </div>
                    </div>

                    {/* Content Section */}
                    <div style={{padding: "16px 16px 16px 50px"}}>
                        {/* Property Info Section */}
                        <div>
                            <div className="property-section-title">Property info</div>
                            <div>
                                <div className="property-info-row">
                                    <div className="property-info-label">Property value</div>
                                    <div className="property-info-value">{`$${com.commaize(marketvalue)}`}</div>
                                </div>
                                <div className="property-info-row">
                                    <div className="property-info-label">Status</div>
                                    <div className="property-info-value">{getStatusText(status)}</div>
                                </div>
                                <div className="property-info-row">
                                    <div className="property-info-label">Intended occupancy</div>
                                    <div className="property-info-value">{getOccupancyText(occupancy)}</div>
                                </div>
                                <div className="property-info-row">
                                    <div className="property-info-label">Monthly Insurance,Taxes, Association Dues, etc</div>
                                    <div className="property-info-value">{`$${com.commaize(insurancemaintenancetaxes)}`}</div>
                                </div>
                            </div>
                        </div>

                        {/* Divider */}
                        {loans.length > 0 && <div className="property-section-divider"></div>}

                        {/* Mortgage Loans Section */}
                        {loans.length > 0 && (
                            <div>
                                <div className="property-section-title">Mortgage loans on this property</div>
                                <div>
                                    {loans.map(loan => (
                                        <div key={loan.key} className="mortgage-loan-card">
                                            {/* Title */}
                                            <div className="mortgage-loan-title">
                                                {getCreditLoanTypeText(loan.creditloantype)}
                                            </div>

                                            {/* Info Grid */}
                                            <div className="mortgage-info-grid">
                                                {/* Left Column */}
                                                <div>
                                                    {/* Lender */}
                                                    <div className="mortgage-info-item">
                                                        <div className="mortgage-info-label">Lender</div>
                                                        <div className="mortgage-info-value">{loan.name}</div>
                                                    </div>

                                                    {/* Balance */}
                                                    <div className="mortgage-info-item">
                                                        <div className="mortgage-info-label">Unpaid balance</div>
                                                        <div className="mortgage-info-value">
                                                            {loan.remainingbalance ? `$ ${com.commaize(loan.remainingbalance)}` : ''}
                                                        </div>
                                                    </div>

                                                    {/* Mo left */}
                                                    <div className="mortgage-info-item">
                                                        <div className="mortgage-info-label">Paid off at/before closing</div>
                                                        <div className="mortgage-info-value">{paidOffAtClosing(loan.paidoffatclosing)}</div>
                                                    </div>
                                                </div>

                                                {/* Right Column */}
                                                <div>
                                                    {/* Account number */}
                                                    <div className="mortgage-info-item">
                                                        <div className="mortgage-info-label">Account number</div>
                                                        <div className="mortgage-info-value">{loan.accountnumber || ''}</div>
                                                    </div>

                                                    {/* Mo. payment */}
                                                    <div className="mortgage-info-item">
                                                        <div className="mortgage-info-label">Mo. payment</div>
                                                        <div className="mortgage-info-value">
                                                            {loan.monthlypayment ? `$${com.commaize(loan.monthlypayment)}` : ''}
                                                        </div>
                                                    </div>

                                                    {/* Ownership */}
                                                    <div className="mortgage-info-item">
                                                        <div className="mortgage-info-label">Credit limit (optional)</div>
                                                        <div className="mortgage-info-value">
                                                            {loan.creditlimit ? `$${com.commaize(loan.creditlimit)}` : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    isPreapproval = () => {
        const { hascontract, purpose } = this.props.application.property
        return false === hascontract && purpose === 'purchase'
    }

    getExpensesQuestions = (who) => {
        const { presentaddressownership, ownedproperties } = this.props.application[who]

        const currentProperty = presentaddressownership !== st.O_Own ? null : !isEmpty(ownedproperties) ? ownedproperties[0] : null
        const additionalProperties = isEmpty(ownedproperties) ?  [] : presentaddressownership === st.O_Own ? ownedproperties.slice(1) : ownedproperties

        const addOwnedProperty = who === com.borrower ? this.props.addBorrowerOwnedProperty : this.props.addCoborrowerOwnedProperty
        const clearOwnedProperty = who === com.borrower ? this.props.clearBorrowerOwnedProperty : this.props.clearCoborrowerOwnedProperty
        const changeOwnedProperty = who === com.borrower ? this.props.changeBorrowerOwnedProperty : this.props.changeCoborrowerOwnedProperty

        const hasAdditionalProperties = () => {
            if (isEmpty(ownedproperties)) {
                return false
            }
            if (presentaddressownership === st.O_Own) {
                return ownedproperties.length > 1
            } else {
                return ownedproperties.length > 0
            }
        }

        const {
            presentaddressstreet,
            presentaddressunit,
            presentaddressstate,
            presentaddresscounty,
            presentaddresscity,
            presentaddresszip,
        } = this.props.application[who]

        return (
            <div>
                <div className="mt-4">
                    <Form.Group className="inputSection mt-4">
                        <Form.Label className="d-flex justify-content-between text-wrap">
                            <span>
                                *What is your current housing situation?
                            </span>
                        </Form.Label>
                        <CheckGroup
                            required
                            data={[
                                { value: st.O_Own, label: 'I own it' },
                                { value: st.O_Rent, label: 'I rent it' },
                                { value: st.O_Rentfree, label: 'No housing expense' },
                            ]}
                            onChangeSelect={(e) => {
                                clearOwnedProperty()
                                this.props.changeBorrowerInfoByValue(e, who, 'presentaddressownership')
                                if (e === st.O_Own) {
                                    addOwnedProperty()
                                    changeOwnedProperty(presentaddressstreet, 0, 'address')
                                    changeOwnedProperty(presentaddressunit, 0, 'unit')
                                    changeOwnedProperty(presentaddresscity, 0, 'city')
                                    changeOwnedProperty(presentaddressstate, 0, 'state')
                                    changeOwnedProperty(presentaddresscounty, 0, 'county')
                                    changeOwnedProperty(presentaddresszip, 0, 'zipcode')
                                    const additionalLoan = JSON.parse(new st.loan().toJson())
                                    additionalLoan.creditloantype = 'ConventionalRealEstateMortgage'
                                    additionalLoan.key = Math.random().toString()
                                    additionalLoan.address = changeOwnedProperty([additionalLoan], 0, 'loans')
                                    this.setState({ showPropertyModal: true, currentPropertyIndex: 0 })
                                }
                            }}
                            value={presentaddressownership}
                            type="radio"
                            direction="horizontal"
                        />
                        <Form.Control.Feedback
                            type="invalid"
                            style={{ display: this.state.errNoOwnership ? 'block' : 'none' }}
                        >
                            Please select your current housing situation!
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>

                <div className="mt-e">
                    {currentProperty && this.processAdditionalRealEstate(currentProperty, 0, who)}
                </div>

                {presentaddressownership === st.O_Rent && (
                    <Row>
                        <Col md={4} xs={12} className="pl-0 pr-3 mt-4">
                            <Form.Group className="inputSection">
                                <Form.Label>
                                    *How much is your monthly rent payment?
                                </Form.Label>
                                <InputNumber
                                    prefix="$"
                                    className="w-100"
                                    defaultValue={this.props.application[who].expenses.currentrent}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) =>
                                        this.props.updateExpenses(
                                            { target: { value: e } },
                                            who,
                                            'currentrent'
                                        )
                                    }
                                    size="large"
                                    placeholder="----"
                                />
                                <Form.Control.Feedback
                                    type="invalid"
                                    style={{ display: this.state.errNoRent ? 'block' : 'none' }}
                                >
                                    Please enter your monthly rent payment!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                )}

                <Row>
                    <Col md={8} xs={12} className="mt-4 px-0 pr-2">
                        <Form.Group className="inputSection">
                            <Form.Label className="d-flex justify-content-between text-wrap">
                                <span>
                                    Do you currently own any additional properties?
                                </span>
                            </Form.Label>
                            <CheckGroup
                                required
                                data={[
                                    { value: 'true', label: 'Yes' },
                                    { value: 'false', label: 'No' },
                                ]}
                                onChangeSelect={(e) => this.addOtherProperties(e, who)}
                                value={hasAdditionalProperties() ? 'true' : 'false'}
                                type="radio"
                                direction="horizontal"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <div className="mt-e">
                    {!isEmpty(additionalProperties) && additionalProperties.map((property, index) => {
                        // +1 because the first property is current property
                        const propertyIndex = presentaddressownership === st.O_Own ? index + 1 : index
                        return this.processAdditionalRealEstate(property, propertyIndex, who)
                    })}
                </div>
                {hasAdditionalProperties() && (
                    <div
                        className="property-edit-button"
                        style={{ display: 'inline-flex', cursor: 'pointer' }}
                        onClick={() => {
                            addOwnedProperty()
                            const additionalLoan = JSON.parse(new st.loan().toJson())
                            additionalLoan.creditloantype = 'ConventionalRealEstateMortgage'
                            additionalLoan.key = Math.random().toString()
                            changeOwnedProperty([additionalLoan], ownedproperties.length, 'loans')
                            this.setState({ showPropertyModal: true, currentPropertyIndex: ownedproperties.length })
                        }}
                    >
                        Add
                    </div>
                )}
            </div>
        )
    }

    renderAdditionalExpenses = () => {
        return this.getExpensesQuestions(this.props.who)
    }

    renderExpenses = () => {
        const { who } = this.props

        const clearAlimony = (event) => {
            com.touch()
            this.props.clearAlimonies(event, who)
        }

        const addAlimony = (event) => {
            com.touch()
            return this.props.addAlimony(event, who)
        }

        const { alimony } = this.props.application[who].finances

        return (
            <>
                <Row>
                    <Col className="pl-0 pr-2 mt-4" xs={12}>
                        <Form.Label className="text-wrap">
                            Do you have any additional liabilities and expenses, including alimony, child support, separate maintenance, or job-related expenses?
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} xs={12} className="px-0 pr-2">
                        <Form.Group className="inputSection">
                            <CheckGroup
                                data={[
                                    { value: 'true', label: 'Yes' },
                                    { value: 'false', label: 'No' },
                                ]}
                                onChangeSelect={(e) => {
                                    if (e === 'true') {
                                        addAlimony()
                                    } else {
                                        clearAlimony()
                                    }
                                }}
                                value={(null != alimony && alimony.length > 0) ? "true" : "false"}
                                type='radio'
                                direction="horizontal"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {!isEmpty(alimony) && alimony.map(this.processAlimonies)}
            </>
        )
    }

    render = () => {
        const { who, borrowerid, loadingcr, message } = this.props
        const { ownedproperties, presentaddressownership } = this.props.application[who]
        const { warnings } = this.props.application.assetsandliabilities
        const { currentPropertyIndex, showPropertyModal } = this.state

        return (
            <div className="mb-4 text-left newInterviewPaneEmployment">
                <div className="page-header">
                    <span className="page-header-primary">Finance</span>
                    <span className="page-header-separator">/</span>
                    <span className="page-header-secondary">Liabilities</span>
                </div>
                <PropertyModal
                    show={showPropertyModal}
                    onHide={() => this.setState({ showPropertyModal: false })}
                    ownership={presentaddressownership}
                    ownedproperties={ownedproperties}
                    propertyIndex={currentPropertyIndex}
                    changeOwnedProperty={
                        who === com.borrower ? this.props.changeBorrowerOwnedProperty : this.props.changeCoborrowerOwnedProperty
                    }
                    onSubmit={(formData) => {
                        this.setState({ showPropertyModal: false })
                    }}
                />

                {!isEmpty(warnings) && borrowerid && warnings.map((warning, index) => (
                    <Alert key={`warning-${index}`} className="mx-3" variant="info">
                        {warning}
                    </Alert>
                ))}

                {loadingcr ? (
                    <div className="w-100 mt-4 text-center">
                        <div id="spinner" className="py-5 my-5">
                            <img
                                className="loader"
                                alt="spinner"
                                src={com.logo ? `data:image/png;base64, ${com.logo}` : '/logo.png'}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="mt-4">
                        {this.renderCore()}
                        {this.renderAdditionalExpenses()}
                        {this.renderExpenses()}
                    </div>
                )}

                {message}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(Liabilities)
